import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import menuItems from "./menuItems";
import LanguageSelector from "../footer/LanguageSelector";
import "./navBar.css";
import useCheckHostname from "../../../utility/helper";

const Nav = ({ activeEl, closeMenu, subMenuEl, flags }) => {
  const { t, i18n } = useTranslation("common");
  let currentLanguage = i18n.language;
  const zIndex = (index) => {
    return {
      zIndex: `'${index}'`,
    };
  };
  const isEducation = useCheckHostname('education');

  return (
    <nav className={activeEl !== "home" ? "add-border" : ""}>
      <LanguageSelector flags={flags} color="dark" sizeSmall />

      <ul>
        {menuItems.map((menuItem, index) => {
          return (
            !isEducation || (isEducation && index !== menuItems.length - 1)) ? (<li
              className={activeEl === menuItem.id ? "active" : ""}
              key={index}
              style={zIndex(index)}
            >
              <h2>
                {
                  menuItems.length - 1 === index ? (
                    <a href={menuItem.link} target="_blank" rel="noreferrer" className="with-left-divider"> {t("header.nav." + menuItem.id)}</a>
                  ) :
                    (<NavLink
                      to={
                        index === menuItems.length - 1 ?
                          menuItem.link :
                          menuItem.link ? `/${currentLanguage}/p` + menuItem.link : ""
                      }

                      rel="noopener noreferrer"
                    >
                      {t("header.nav." + menuItem.id)}
                      <span> {menuItem.link ? "" : ":"} </span>
                    </NavLink>
                    )
                }
              </h2>
              {menuItem.subMenuItems && (
                <div className="tooltip">
                  {menuItem.subMenuItems.map((subMenuItem, index) => {
                    const subMenuContent = (
                      <section
                        className={`subMenuItem ${subMenuItem.link === "/" + subMenuEl
                            ? "active"
                            : subMenuItem.link === subMenuEl
                              ? "active"
                              : ""
                          }`}
                      >
                        <h4>
                          {t(
                            "header.nav.tooltip." +
                            menuItem.id +
                            "." +
                            subMenuItem.id +
                            ".title"
                          )}
                        </h4>
                        <h4 className="tooltip-item-mobile-title">
                          {t(
                            "header.nav.tooltip." +
                            menuItem.id +
                            "." +
                            subMenuItem.id +
                            ".mobile-title"
                          )}
                        </h4>
                        <p>
                          {t(
                            "header.nav.tooltip." +
                            menuItem.id +
                            "." +
                            subMenuItem.id +
                            ".description"
                          )}
                        </p>
                      </section>
                    );
                    if (subMenuItem.extern) {
                      return (
                        <a
                          href={subMenuItem.link}
                          rel="noreferrer"
                          key={index}
                          onClick={() => closeMenu()}
                        >
                          {subMenuContent}
                        </a>
                      );
                    }
                    return (
                      <NavLink
                        to={
                          `/${currentLanguage}/p/${menuItem.id}${subMenuItem.link}`}
                        key={index}
                        onClick={() => closeMenu()}
                      >
                        {subMenuContent}
                      </NavLink>)
                  })}
                </div>
              )}
            </li>) : (<></>

          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
