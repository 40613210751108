import VideoContainer from "../schools/VideoContainer";

import { useEffect } from "react";

import "./videoModal.css";

const VideoModal = ({ videoUrl, setVideoUrl }) => {
  useEffect(() => {
    document.getElementById("videoModal").addEventListener("wheel", (evt) => {
      evt.preventDefault();
    });
  }, [videoUrl]);
  console.log(videoUrl)
  return (
    <div id="videoModal">
      <div id="videoContainer">
        <VideoContainer type={"recommendations-home"} youtubeUrl={videoUrl} />
        <button className="closeModal" onClick={() => setVideoUrl(null)}>
          X
        </button> 
      </div>
      <div className="backgroundContainer"></div>
    </div>
  );
};

export default VideoModal;
