import "./introSchools.css";
import "./introPublic.css";
import IntroPublicBackground from "../../../assets/schools/intro/schools-public-intro-background.svg";
import Hand from "../../../assets/schools/intro/hand.svg";
import TextBubbles from "../../../assets/schools/intro/text_bubbles.svg";
import Clock from "../../../assets/schools/intro/clock.svg";
import Button from "../../general/Button";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import horizoltalScroll from "../../../utility/horizontallScroll";
import { scrollInCenter } from "../../../utility/horizontallScroll";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
const IntroPublic = ({introRef,calculatorRef}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "false", duration: 750, offset: -750 });
    horizoltalScroll(document.getElementById("advantages_public"));
    scrollInCenter(
      document.getElementById("advantages_public"),
      document.getElementById("el_to_center")
    );
  }, []);

  
  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      const calculatorTopOffset = calculatorRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const calculatorHeight = calculatorRef.current.offsetHeight;

      const targetScrollPosition = calculatorTopOffset - windowHeight / 2 + calculatorHeight / 2;

      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };
  return (
    <div className="intro_schools" id="intro_public">
      <div className="intro_container">
        <div className="intro_content">
          <h1>{t("schools.intro.public.title")}</h1>
          <p>{t("schools.intro.public.p1")}</p>
          <p>{t("schools.intro.public.p2")}</p>
          <div className="intro_buttons_container">
            <div>
              <Button
                text={t("home.intro.content.buttons.btn1")}
                type={"primary"}
                action={scrollToCalculator}
              />
            </div>
            <p
              className="intro_packages-link"
              onClick={() => {
                navigate(`/${i18n.language}/p/subscriptions?user=school`);
              }}
            >
              {t("schools.intro.packagesLink")}
            </p>
          </div>
        </div>
      </div>
      <img
        src={IntroPublicBackground}
        alt={IntroPublicBackground}
        data-aos={"fade-left"}
      />
      <div className="advantages_public" id="advantages_public">
        <div className="advantages_box_public">
          <div className="advantages_box_img">
            <img src={Clock} alt={Clock} />
          </div>
          <h2>{t("schools.intro.public.advantage_1")}</h2>
          <p>{t("schools.intro.public.advantage_1_details")}</p>
        </div>
        <div className="advantages_box_public" id="el_to_center">
          <div className="advantages_box_img">
            <img src={TextBubbles} alt={TextBubbles} />
          </div>
          <h2>{t("schools.intro.public.advantage_2")}</h2>
          <p>{t("schools.intro.public.advantage_2_details")}</p>
        </div>
        <div className="advantages_box_public">
          <div className="advantages_box_img">
            <img src={Hand} alt={Hand} />
          </div>
          <h2>{t("schools.intro.public.advantage_3")}</h2>
          <p>{t("schools.intro.public.advantage_3_details")}</p>
        </div>
      </div>
    </div>
  );
};

export default IntroPublic;
