import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Button = ({
  text,
  type,
  link,
  linkType,
  action,
  classList,
  disabled,
}) => {
  const navigate = useNavigate();
  const intenalLink = (path) => {
    navigate(path);
  };
  const externalLink = (url) => {
    window.open(
      url,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  return (
    <Btn
      className={classList ? type + " " + classList : type}
      onClick={(ev) => {
        if (action) {
          ev.preventDefault();
          action();
        } else {
          ev.preventDefault();
          if (link) {
            if (linkType === "intern") {
              intenalLink(link);
            } else if (linkType === "location") {
              window.location.href = link;
            } else {
              externalLink(link);
            }
          }
        }
      }}
      disabled={disabled}
    >
      {text}
    </Btn>
  );
};

export default Button;

const Btn = styled.button`
  padding: 8.5px 15.5px;
  border-radius: 20px;
  outline: none;
  color: var(--tertiary-ultralight);
  background-color: transparent;
  border: 1px solid #fff;
  height: 40px;
  cursor: pointer !important;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  z-index: 15;
  width: fit-content !important;
  &.ghost:hover {
    background-color: var(--tertiary-ultralight);
    color: #000;
    mix-blend-mode: screen;
  }
  &:disabled {
    background-color: var(--secondary-light);
  }
  &.primary {
    background-color: var(--action-accent);
    border-color: transparent;
    &:hover {
      background-color: var(--pumpkin-orange);
    }
  }
  &.primary-invert {
    color: var(--action-accent);
    border-color: var(--action-accent);
    &:hover {
      background-color: var(--action-accent);
      color: var(--tertiary-ultralight);
      border-color: var(--tertiary-ultralight);
    }
  }
  &.secodaryHeavy {
    background-color: var(--secondary-heavy);
    border-color: transparent;
  }
  &.secodaryHeavy:hover {
    background-color: #000;
  }
  &.actionAccent {
    background-color: var(--tertiary-ultralight);
    border-color: var(--action-accent);
    color: var(--action-accent);
    &:hover {
      background-color: var(--action-accent);
      color: var(--tertiary-ultralight);
    }
  }
  &.secodaryHeavy-invert {
    background-color: transparent;
    border-color: var(--secondary-heavy);
    color: var(--secondary-heavy);
    &:hover {
      color: var(--macaroni-and-cheese);
      background-color: var(--secondary-heavy) !important;
    }
  }
  &.blue-btn {
    background-color: transparent;
    border-color: var(--main-default);
    color: var(--main-default);
    &:hover {
      color: var(--tertiary-ultralight);
      background-color: var(--main-default) !important;
    }
  }
`;
