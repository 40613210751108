import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";
const BottomSection = ({ changeLanguage, flags }) => {
  const { t, i18n } = useTranslation("common");
  let currentLanguage = i18n.language;

  const showCookieBot = (e) => {
    e.preventDefault();
    window.Cookiebot.show();
  };

  return (
    <section className="bottom">
      <LanguageSelector flags={flags} />
      <section>
        <p>{t("general.footer.bottom.section1.p1")}</p>
        <p>{t("general.footer.bottom.section1.p2")}</p>
      </section>
      <section>
        {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
        <a href="/" onClick={showCookieBot}>
          {t("general.footer.bottom.section2.p4")}
        </a>

        <NavLink to={`/${currentLanguage}/p/cookies`}>
          <p>{t("general.footer.bottom.section2.p0")}</p>
        </NavLink>
        <NavLink to={`/${currentLanguage}/p/terms-and-condition`}>
          <p>{t("general.footer.bottom.section2.p1")}</p>
        </NavLink>
        <NavLink to={`/${currentLanguage}/p/privacy-policy`}>
          <p>{t("general.footer.bottom.section2.p2")}</p>
        </NavLink>
        <a href="https://anpc.ro/" target="_blank" rel="noreferrer">
          <p>{t("general.footer.bottom.section2.p3")}</p>
        </a>
      </section>
    </section>
  );
};

export default BottomSection;
