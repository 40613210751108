/* eslint-disable jsx-a11y/anchor-has-content */
import './parents_education.css';
import ParentsEducationBackground from '../../assets/parents/better_education_hero.svg';
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import AOS from 'aos';
import useCheckHostname from '../../utility/helper';
const ParentsEducation = () => {
  const { t, i18n } = useTranslation('common');
  useEffect(() => {
    AOS.init({ mirror: 'false', once: 'true', duration: 1000 });
  }, []);

  const isEducation = useCheckHostname('education');

  return (
    <section className="parents_education">
      <img
        src={ParentsEducationBackground}
        alt={ParentsEducationBackground}
        data-aos="fade-right"
      />
      <div className="parents_education_content">
        <div className="parents_education_upper_text">
          <h1>{t('parents.education.title')}</h1>
          <h3>{t('parents.education.description')}</h3>
        </div>
        <h2>{t('parents.education.benefits_title')}</h2>
        <div className="parents_education_wrapper">
          <div>
            <p>•</p>
            <p>{t('parents.education.benefits.1')}</p>
          </div>
          <div>
            <p>•</p>
            <p>{t('parents.education.benefits.2')}</p>
          </div>
          {!isEducation && (
            <div>
              <p>•</p>
              <div>
                <Trans
                  i18nKey="parents.education.benefits.4"
                  t={t}
                  components={[
                    <p
                      className="link"
                      onClick={() => {
                        window.location.href = `/${i18n.language}/admission/offers`;
                      }}
                    ></p>,
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default ParentsEducation;
