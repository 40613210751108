import testimonialImg from "../../assets/csr/testimonial.svg";
import quote from "../../assets/csr/quote.svg";
import "./bcrDigitalization.css";
import AOS from "aos";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const BcrDigitalization = () => {
  const {  t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <div id="bcrDigitalization">
      <img src={testimonialImg} alt={testimonialImg} data-aos="fade-in" />
      <div className="content">
        <h3>
          {t("csr.bcr_digitalization.name")} 
          <br />
          <span>{t("csr.bcr_digitalization.status")} </span>
        </h3>

        <div className="testimonial">
          <img src={quote} alt={quote} />
          <h1>{t("csr.bcr_digitalization.title")} </h1>
          <p>{t("csr.bcr_digitalization.p1")} </p>
          <p>{t("csr.bcr_digitalization.p2")} </p>
          <img src={quote} alt={quote} />
        </div>
      </div>
    </div>
  );
};

export default BcrDigitalization;
