import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import parent from '../../assets/home/adsBenefits/female-parent-icon.svg';
import school from '../../assets/subscriptions/schools_icon.svg';
import bluewave_BG from '../../assets/subscriptions/bluewave_BG.svg';

import Footer from '../general/footer/Footer';
import { Header } from '../general/header';
import SolutionBox from '../schools/SolutionBox';
import SchoolCards from './SchoolCards';
import ParentCards from './ParentCards';

import ReactHtmlParser from 'react-html-parser';

import './subscriptions.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Api2 from '../../../src/Libs/api';

const decisionLnag = (lang, userType) => {
  const roOrganizationPathPdf =
    'https://adservio-public.s3.eu-west-1.amazonaws.com/RO-Adservio-Pricing.pdf';

  const roParentsPathPdf =
    'https://adservio-public.s3.eu-west-1.amazonaws.com/RO-Adservio-Elevi-Parinti.pdf';

  const enOrganizationPathPdf =
    'https://adservio-public.s3.eu-west-1.amazonaws.com/EN-Adservio-Pricing.pdf';

  const enParentsPathPdf =
    'https://adservio-public.s3.eu-west-1.amazonaws.com/EN-Adservio-Students-Parents.pdf';

  if (lang === 'ro' && userType === 'school') {
    return roOrganizationPathPdf;
  }
  if (lang === 'ro' && userType === 'parent') {
    return roParentsPathPdf;
  }
  if (lang === 'en' && userType === 'school') {
    return enOrganizationPathPdf;
  }
  if (lang === 'en' && userType === 'parent') {
    return enParentsPathPdf;
  }
  return userType === 'school' ? enOrganizationPathPdf : enParentsPathPdf;
};

const Subscriptions = () => {
  const [flags, setFlags] = useState();
  const [userType, setUserType] = useState(null);
  const [userAuth, setUserAuth] = useState(null);

  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();

  const getLangfromUrl = () => {
    let urlData = window.location.pathname.slice(1, 3);
    if (urlData === 'ro') {
      return urlData;
    }
    return 'en';
  };

  //get user type from url
  /* eslint-disable */
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    let urlData = queryParams.get('user');
    if (urlData) setUserType(urlData);
    else updateUserType('school');

    urlData = queryParams.get('auth');
    if (urlData === 'true') {
      setUserAuth(true);
    }
  });

  //change state of userType and rewrite url
  /* eslint-enable */

  const updateUserType = (newType) => {
    if (newType && newType !== userType) {
      setUserType(newType);
      window.history.replaceState(null, null, `?user=${newType}`);
    }
  };
  const { lng } = useParams();

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });
  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  return (
    <section className="subscriptions">
      {!userAuth && <Header flags={flags} />}
      <main>
        <div className="packages">
          {!userAuth && (
            <>
              <h1 className="packages__title">
                {userType && t(`subscriptions.title.${userType}`)}
              </h1>
              <div className="scrollContainer">
                <div className="packages__scrollable__div">
                  <div
                    className={
                      'packages__selector ' +
                      (userType === 'school' ? 'packages__selector--active' : '')
                    }
                    onClick={() => updateUserType('school')}
                  >
                    <img src={school} alt="img" />
                    <h2>{t(`subscriptions.users.school`)}</h2>
                  </div>
                  <div
                    className={
                      'packages__selector ' +
                      (userType === 'parent' ? 'packages__selector--active' : '')
                    }
                    onClick={() => updateUserType('parent')}
                  >
                    <img src={parent} alt="img" />
                    <h2>{t(`subscriptions.users.parent`)}</h2>
                  </div>
                </div>
              </div>
              <h2 className="packages__subtitle">
                {ReactHtmlParser(t(`subscriptions.subTitle.${userType}`))}
              </h2>
            </>
          )}
          <div className="packages__cards__container">
            <img className="packages__cards__bg" src={bluewave_BG} alt="bluewave" />
            {userType === 'school' && <SchoolCards />}
            {userType === 'parent' && <ParentCards />}

            <p className="packages__bottom__link">
              {userType === 'school' && (
                <span
                  onClick={() =>
                    navigate(
                      `/${i18n.language}/p/register?user=${
                        userType === 'school' ? 'headmaster' : userType
                      }`
                    )
                  }
                >
                  {t('subscriptions.freeTrial.1')}
                </span>
              )}
              {' ' + t('subscriptions.freeTrial.2') + ' '}
              <a href={decisionLnag(getLangfromUrl(), userType)}>
                {t('subscriptions.freeTrial.3')}
              </a>
            </p>
          </div>
        </div>
        {!userAuth && (
          <section className="solutions">
            <h1>{t('subscriptions.faq.title')}</h1>
            {userType && (
              <div className="solutions_container">
                {[0, 1, 2].map((index) => (
                  <SolutionBox
                    title={
                      userType === 'parent' && index === 1
                        ? t(`subscriptions.faq.${userType}.${index}.parentQuestion`)
                        : t(`subscriptions.faq.${userType}.${index}.question`)
                    }
                    text={t(`subscriptions.faq.${userType}.${index}.answer`)}
                    key={index}
                  />
                ))}
              </div>
            )}
          </section>
        )}
      </main>
      {!userAuth && <Footer />}
    </section>
  );
};

export default Subscriptions;
