import React from "react";

import TopSection from "./TopSection";
import BottomSection from "./BottomSection";

import "./footer.css";

const Footer = ({ flags }) => {
  return (
    <footer>
      <TopSection flags={flags} />
      <BottomSection flags={flags} />
    </footer>
  );
};

export default Footer;
