import React, { useState } from "react";

import Select from "react-select";

import PropTypes from "prop-types";

import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";

import labels from "react-phone-number-input/locale/en.json";

import "./CountrySelect.css";

const selectStyles = {
  indicatorsContainer: () => ({
    display: "none",
  }),
  control: (provided) => ({ ...provided, border: "none", boxShadow: "none" }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
    fontSize: 12,
    cursor: "text",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    fontSize: 12,
  }),
  menu: (provided) => ({
    ...provided,
    position: "unset",
    boxShadow: "none",
    margin: 0,
    borderRadius: 0,
    borderTop: "solid 1px #cccccc",
  }),
};

const Option = (props) => {
  const { innerProps, value, label, selectProps } = props;
  const isSelected = selectProps?.value === value;

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
      className={`country-select__option-wrapper ${isSelected &&
        "country-select__option-wrapper--selected"}`}
    >
      <img
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
        alt="Country"
        className="country-select__option-icon"
      />
      <span className="country-select__option-text">
        {label} +{getCountryCallingCode(value)}
      </span>
    </div>
  );
};

const Dropdown = ({ children, isOpen, target, disabled }) => (
  <div
    className={`phone-number__country-select ${disabled &&
      "phone-number__country-select--disabled"}`}
  >
    {target}
    {isOpen && children ? (
      <div className="country-select__menu">{children}</div>
    ) : null}
  </div>
);

const CountrySelect = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { onChange, value, disabled } = props;

  const onSelectChange = (selectedOption) => {
    setIsOpen(!isOpen);
    onChange(selectedOption.value);
  };

  const countries = getCountries().map((country) => {
    return {
      label: labels[country],
      value: country,
    };
  });

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
      }}
      disabled={disabled}
      target={
        <button
          disabled={disabled}
          type="button"
          className={`country-select__button ${disabled &&
            "country-select__button--disabled"}`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {value && (
            <img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} alt="Country" className="country-select__option-icon" />
          )}
          <span className="icon-down-arrow country-select__down-arrow" />
        </button>
      }
    >
      <Select
        theme={{
          spacing: {
            controlHeight: 40,
          },
        }}
        maxMenuHeight={200}
        autoFocus
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={onSelectChange}
        menuIsOpen
        options={countries}
        placeholder="Search"
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
        components={{ Option }}
      />
    </Dropdown>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CountrySelect.defaultProps = {
  value: "",
  disabled: false,
  onChange: () => {},
};

Dropdown.propTypes = {
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  target: PropTypes.node,
};

Dropdown.defaultProps = {
  isOpen: false,
  disabled: false,
  children: undefined,
  target: undefined,
};

Option.propTypes = {
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  value: PropTypes.string,
};

Option.defaultProps = {
  innerProps: undefined,
  selectProps: {
    value: undefined,
  },
  label: "",
  value: "",
};

export default CountrySelect;
