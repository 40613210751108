import React from "react";
import PropTypes from "prop-types";

import "./Loader.css";

const Loader = ({ color, size, position }) => (
  <div className={`comp-loader ${color} ${size} ${position}`} />
);

Loader.propTypes = {
  /** marimea loader-ului */
  size: PropTypes.oneOf(["xm", "sm", "md", "lg"]),
  /** culoarea loader-ului */
  color: PropTypes.oneOf(["light", "dark"]),
  position: PropTypes.oneOf(["position_abs", ""]),
};

Loader.defaultProps = {
  size: "md",
  color: "light",
  position: "position_abs"
};

export default Loader;
