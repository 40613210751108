import schoolIcon from "../../assets/registerFlow/schoolIcon.svg";
import "./registerSucceeded.css";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import { useEffect } from "react";

const RegisterSucceeded = () => {
  const { t } = useTranslation("common");

  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <div id="registerSucceeded">
      <img src={schoolIcon} alt={schoolIcon} data-aos="fade-up" />
      <h1>{t("register.succeeded.title3")}</h1>
      <h3>{t("register.succeeded.subTitle2")}</h3>
    </div>
  );
};

export default RegisterSucceeded;
