import femaleParent from "../../assets/home/adsBenefits/female-parent-icon.svg";
import headmasterBG from "../../assets/home/adsBenefits/headmaster-bg.svg";
import headmaster from "../../assets/home/adsBenefits/headmaster-icon.svg";
import maleParent from "../../assets/home/adsBenefits/male-parent-icon.svg";
import parentBG from "../../assets/home/adsBenefits/parents-bg.svg";
import studentBG from "../../assets/home/adsBenefits/student-bg.svg";
import student from "../../assets/home/adsBenefits/student-icon.svg";
import teacherBG from "../../assets/home/adsBenefits/teacher-bg.svg";
import teacher from "../../assets/home/adsBenefits/teacher-icon.svg";

import Button from "../general/Button";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { scrollInCenter } from "../../utility/horizontallScroll";

import "./adservioBenefits.css";

const clickHandler = (id) => {
  if (window.innerWidth <= 620) {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      onAnimationEnd: scrollInCenter(
        document.getElementById("roleSelector"),
        document.getElementById(id + "-card")
      ),
    });
  } else {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }
};

const AdservioBenefits = () => {
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true" });
  }, []);
  const { i18n, t } = useTranslation("common");
  let bgAnimation = "fade-up";
  let animationLeftSide = "fade-right";
  let animationRightSide = "fade-left";
  let bgAnimationTime = "750";
  let iconAnimationTime = "500";
  let offSet = "150";
  return (
    <>
      <h1 className="adsBenefitsTitle">{t("home.adservioBenefits.title")}</h1>
      <section className="roleSelector" id="roleSelector">
        <div
          className="card"
          onClick={() => clickHandler("headmaster")}
          id="headmaster-card"
        >
          <div className="imgContainer">
            <img src={headmaster} alt={headmaster} />
          </div>
          <span> {t("home.adservioBenefits.links.headmasters")}</span>
        </div>
        <div
          className="card"
          onClick={() => clickHandler("teacher")}
          id="teacher-card"
        >
          <div className="imgContainer">
            <img src={teacher} alt={teacher} />
          </div>
          <span> {t("home.adservioBenefits.links.teachers")}</span>
        </div>
        <div
          className="card"
          onClick={() => clickHandler("parents")}
          id="parents-card"
        >
          <div className="imgContainer">
            <img src={femaleParent} alt={femaleParent} />
          </div>
          <span> {t("home.adservioBenefits.links.parents")}</span>
        </div>
        <div
          className="card"
          onClick={() => clickHandler("students")}
          id="students-card"
        >
          <div className="imgContainer">
            <img src={student} alt={student} />
          </div>
          <span> {t("home.adservioBenefits.links.students")}</span>
        </div>
      </section>
      <section id="adservioBenefitsContainer">
        <h1> </h1>

        <div className="benefit_container" id="headmaster">
          <section className="benefit_shapes">
            <img
              src={headmasterBG}
              alt="headmaster"
              data-aos={bgAnimation}
              data-aos-duration={bgAnimationTime}
            />
            <img
              src={headmaster}
              alt="headmaster"
              data-aos={animationLeftSide}
              data-aos-duration={iconAnimationTime}
              data-aos-offset={offSet}
            />
          </section>
          <section className="benefit_content">
            <h3>{t("home.adservioBenefits.content.section1.title-new")}</h3>
            <ul>
              <li>{t("home.adservioBenefits.content.section1.li1")}</li>
              <li>{t("home.adservioBenefits.content.section1.li2")}</li>
              <li>{t("home.adservioBenefits.content.section1.li3")}</li>
            </ul>
            <Button
              text={t("home.adservioBenefits.btn")}
              type={"primary"}
              linkType={"intern"}
              link={`/${i18n.language}/p/schools/public?for=headmaster`}
            ></Button>
          </section>
        </div>
        <div className="benefit_container" id="teacher">
          <section className="benefit_shapes">
            <img
              src={teacherBG}
              alt={teacherBG}
              data-aos={bgAnimation}
              data-aos-duration={bgAnimationTime}
            />
            <img
              src={teacher}
              alt={teacher}
              data-aos={animationRightSide}
              data-aos-duration={iconAnimationTime}
              data-aos-offset={offSet}
            />
          </section>
          <section className="benefit_content">
            <h3>{t("home.adservioBenefits.content.section2.title-new")}</h3>
            <ul>
              <li>{t("home.adservioBenefits.content.section2.li1")}</li>
              <li>{t("home.adservioBenefits.content.section2.li2")}</li>
              <li>{t("home.adservioBenefits.content.section2.li3")}</li>
            </ul>
            <Button
              text={t("home.adservioBenefits.btn")}
              type={"primary"}
              linkType={"intern"}
              link={`/${i18n.language}/p/schools/public?for=teacher`}
            ></Button>
          </section>
        </div>
        <div className="benefit_container" id="parents">
          <section className="benefit_shapes">
            <img
              src={parentBG}
              alt={parentBG}
              data-aos={bgAnimation}
              data-aos-duration={bgAnimationTime}
            />
            <img
              src={femaleParent}
              alt="parent"
              data-aos={animationLeftSide}
              data-aos-duration={iconAnimationTime}
              data-aos-offset={offSet}
            />
            <img
              src={maleParent}
              alt="parent"
              data-aos={animationLeftSide}
              data-aos-duration={iconAnimationTime}
              data-aos-offset={offSet}
            />
          </section>
          <section className="benefit_content">
            <h3>{t("home.adservioBenefits.content.section3.title-new")}</h3>
            <ul>
              <li>{t("home.adservioBenefits.content.section3.li1")}</li>
              <li>{t("home.adservioBenefits.content.section3.li2")}</li>
              <li>{t("home.adservioBenefits.content.section3.li3")}</li>
            </ul>
            <Button
              text={t("home.adservioBenefits.btn")}
              type={"primary"}
              linkType={"intern"}
              link={`/${i18n.language}/p/parents`}
            ></Button>
          </section>
        </div>
        <div className="benefit_container" id="students">
          <section className="benefit_shapes">
            <img
              src={studentBG}
              alt="studentBG"
              data-aos={bgAnimation}
              data-aos-duration={bgAnimationTime}
            />
            <img
              src={student}
              alt="student"
              data-aos={animationRightSide}
              data-aos-duration={iconAnimationTime}
              data-aos-offset={offSet}
            />
          </section>
          <section className="benefit_content">
            <h3>{t("home.adservioBenefits.content.section4.title-new")}</h3>
            <ul>
              <li>{t("home.adservioBenefits.content.section4.li1")}</li>
              <li>{t("home.adservioBenefits.content.section4.li2")}</li>
              <li>{t("home.adservioBenefits.content.section4.li3")}</li>
            </ul>
            <Button
              text={t("home.adservioBenefits.btn")}
              type={"primary"}
              linkType={"intern"}
              link={`/${i18n.language}/p/students`}
            ></Button>
          </section>
        </div>
      </section>
    </>
  );
};

export default AdservioBenefits;
