import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../general/header';
import Footer from '../general/footer/Footer';
import Calculator from '../general/Calculator';
import IntroPublic from './IntroSchools/IntroPublic';
import IntroPrivate from './IntroSchools/IntroPrivate';
import IntroVirtual from './IntroSchools/IntroVirtual';
import IntroChain from './IntroSchools/IntroChain';
import Solutions from './Solutions';
import Digitalization from './Digitalization';
import Support from './Support';
import Benefits from './Benefits';
import ShareModal from '../general/ShareModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MechanicalCounters from '../general/MechanicalCounters';
import VideoModal from '../general/VideoModal';
import Api2 from '../../../src/Libs/api';
import BillingSection from '../general/BillingSection/BillingSection';

const WhiteSpace = styled.div`
  height: 100px;
  @media only screen and (max-width: 1200px) {
    height: 72px;
  }
`;

const Schools = () => {
  let { type, lng } = useParams();
  const { i18n, t } = useTranslation('common');
  const [flags, setFlags] = useState();

  const introRef = useRef(null);
  const calculatorRef = useRef(null);

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  const [videoUrl, setVideoUrl] = useState(null);
  return (
    <>
      <Helmet>
        <title>{t(`general.keysForMeta.schools-${type}.title`)}</title>
        <meta name="description" content={t(`general.keysForMeta.schools-${type}.description`)} />
        <meta name="keywords" content={t(`general.keysForMeta.schools-${type}.keywords`)} />
        <meta property="og:title" content={t(`general.keysForMeta.schools-${type}.title`)} />
        <meta
          property="og:description"
          content={t(`general.keysForMeta.schools-${type}.description`)}
        />
      </Helmet>
      {videoUrl && <VideoModal videoUrl={videoUrl} setVideoUrl={setVideoUrl} />}
      <ShareModal />
      <Header flags={flags} activeEl={'schools'} subMenuEl={type} />
      <main>
        {type === 'public' && <IntroPublic calculatorRef={calculatorRef} introRef={introRef} />}
        {type === 'private' && <IntroPrivate calculatorRef={calculatorRef} introRef={introRef} />}
        {type === 'chain' && <IntroChain calculatorRef={calculatorRef} introRef={introRef} />}
        {type === 'virtual' && <IntroVirtual calculatorRef={calculatorRef} introRef={introRef} />}
        {(type === 'private' || type === 'chain') && <BillingSection />}
        <Benefits type={type} calculatorRef={calculatorRef} introRef={introRef} />
        <Solutions type={type} />
        <Digitalization
          text={t('schools.digitalization.title')}
          setVideoUrl={setVideoUrl}
          type={type}
        />
        <div id="schools_digitalization_margin"></div>
        <Calculator calculatorRef={calculatorRef} />
        <WhiteSpace></WhiteSpace>
        <MechanicalCounters />
        <Support />
      </main>
      <Footer flags={flags} />
    </>
  );
};

export default Schools;
