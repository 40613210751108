import Button from "../general/Button";
import { useTranslation, Trans } from "react-i18next";

import "./intro.css";
import IntroLogos from "./introLogos";
import EAF_badge from "../../assets/general/EAF_badge.png";

const Intro = ({ introRef,calculatorRef }) => {
  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      const calculatorTopOffset = calculatorRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const calculatorHeight = calculatorRef.current.offsetHeight;

      const targetScrollPosition = calculatorTopOffset - windowHeight / 2 + calculatorHeight / 2;

      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };

  const { t } = useTranslation("common");

  return (
    <>
      <section className="intro">
        <div className="container_intro">
          <h1>
            <Trans i18nKey="home.intro.content.title" t={t} components={[<br />]} />
          </h1>
          <div className="container_intro_p">
            <p>{t("home.intro.content.description")}</p>
          </div>

          <div className="container_intro_buttons" ref={introRef}>
            <Button
              text={t("home.intro.content.buttons.btn1")}
              type={"primary"}
              action={scrollToCalculator}
            />
          </div>
          <IntroLogos id={"intro_logos_inside"} />
        </div>
        <img src={EAF_badge} alt={"EAF_badge"} className="intro_badge" />
      </section>
      <IntroLogos id={"intro_logos_outside"} />
    </>
  );
};

export default Intro;
