import educator from "../../assets/schools/icons/educator-icon.svg";
import headmaster from "../../assets/schools/icons/headmaster-icon.svg";
import headteacher from "../../assets/schools/icons/headteacher-icon.svg";
import schoolmaster from "../../assets/schools/icons/schoolmaster-icon.svg";
import secretary from "../../assets/schools/icons/secretary-icon.svg";
import staffIcon from "../../assets/schools/icons/staff-icon.svg";
import teacher from "../../assets/schools/icons/teacher-icon.svg";

import { useState, useEffect } from "react";
import { getOffset, getWidth } from "../../utility/positionOnViewPort";
import { scrollInCenter } from "../../utility/horizontallScroll";
import Button from "../general/Button";
import "./benefits.css";
import "./BenefitBlueIconsContainers/Headmaster";
import Headmaster from "./BenefitBlueIconsContainers/Headmaster";
import Secretary from "./BenefitBlueIconsContainers/Secretary";
import Educator from "./BenefitBlueIconsContainers/Educator";
import SchoolMaster from "./BenefitBlueIconsContainers/SchoolMaster";
import Teacher from "./BenefitBlueIconsContainers/Teacher";
import Headteacher from "./BenefitBlueIconsContainers/Headteacher";
import Auxiliar from "./BenefitBlueIconsContainers/Auxiliar";

import PrivateSchools from "./BenefitBlueIconsContainers/PrivateSchools";
import ChainSchools from "./BenefitBlueIconsContainers/ChainSchools";
import VirtualSchools from "./BenefitBlueIconsContainers/VirtualSchools";
import StudentsBenefits from "./BenefitBlueIconsContainers/StudentsBenefits";
import { useTranslation } from "react-i18next";
import VideoContainer from "./VideoContainer";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}

const images = importAll(
  require.context(
    "../../assets/schools/blueIcons/",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const openModal = async () => {
  const shareData = {
    title: "adservio",
    text: "adservio text",
    url: "https://adservio.ro",
  };

  if (isMobile) {
    navigator
      .share(shareData)
      .then(() => console.log("Share was successful."))
      .catch((error) => {
        console.log("Sharing failed", error);
      });
  } else {
    console.log(`Your system doesn't support sharing files.`);
    document.getElementById("shareModal").style.display = "block";
  }
};
const staff = [
  {
    name: "headmaster",
    icon: headmaster,
  },
  {
    name: "secretary",
    icon: secretary,
  },
  {
    name: "educator",
    icon: educator,
  },
  {
    name: "schoolmaster",
    icon: schoolmaster,
  },
  {
    name: "teacher",
    icon: teacher,
  },
  {
    name: "headteacher",
    icon: headteacher,
  },
  {
    name: "auxiliar",
    icon: staffIcon,
  },
];

const Benefits = ({ type , calculatorRef, introRef }) => {
  const { i18n, t } = useTranslation("common");
  const navigate = useNavigate();
  const [activeStaff, setActiveStaff] = useState("headmaster");
  const hideVideoContainer =
    i18n.language !== "ro" &&
    (activeStaff === "headteacher" || activeStaff === "auxiliar");
  const hidePackagesLink =
    activeStaff === "headteacher" || activeStaff === "auxiliar";
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    let person = queryParams.get("for");
    if (!person) {
      person = "headmaster";
    }
    setActiveStaff(person);

    if (type === "public") {
      if (window.innerWidth > 949) {
        setTimeout(() => {
          moveBall(
            getOffset(document.getElementById(person + "-iconContainer")).left +
              getWidth(document.getElementById(person + "-iconContainer")) / 2,
            0
          );
        }, 200);
      } else {
        setInCenter(person);
      }
    }
  }, [type]);

  const moveBall = (position, time) => {
    const ball = document.getElementById("rolling_ball");
    const initialPosition = getOffset(ball).left ? getOffset(ball).left : 0;

    ball.animate(
      [{ left: `${initialPosition}px` }, { left: `${position}px` }],
      {
        duration: time,
        easing: "ease-out",
        fill: "forwards",
      }
    );
  };
  const setInCenter = (person) => {
    const scrollContainer = document.getElementById("staff-container");
    const currentPersonContainer = document.getElementById(
      person + "-iconContainer"
    );
    scrollInCenter(scrollContainer, currentPersonContainer);
  };
  const handleIconClick = (person) => {
    window.history.replaceState(null, null, `?for=${person}`);
    setActiveStaff(person);

    if (window.innerWidth > 949) {
      moveBall(
        getOffset(document.getElementById(person + "-iconContainer")).left +
          getWidth(document.getElementById(person + "-iconContainer")) / 2,
        750
      );
    } else {
      setInCenter(person);
    }
  };

  const scrollToCalculator = () => {
    if (calculatorRef.current) {
      const calculatorTopOffset = calculatorRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const calculatorHeight = calculatorRef.current.offsetHeight;

      const targetScrollPosition = calculatorTopOffset - windowHeight / 2 + calculatorHeight / 2;

      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <section id="benefits">
      <div className="benefits_container">
        {type === "public" && (
          <>
            <div className="h1_container">
              <h1>{t("schools.benefits.title")}</h1>
            </div>
            <div className="benefits_icons_container" id="staff-container">
              {staff.map((person, index) => {
                return (
                  <div
                    id={person.name + "-iconContainer"}
                    className="benefits_icon_w_title"
                    onClick={() => handleIconClick(person.name)}
                    key={index}
                  >
                    <img
                      src={person.icon}
                      className={
                        person.name === activeStaff ? "active-staff-img" : ""
                      }
                      alt={person.icon}
                    />
                    <p className={person.name === activeStaff ? "active" : ""}>
                      {t("schools.benefits.staff." + person.name)}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="rolling_ball_container">
              <div className="rolling_ball" id="rolling_ball"></div>
            </div>
          </>
        )}
        <section className="benefits_box">
          <div className="benefits_content">
            <div className="benefits_abstract">
              {type === "public" && (
                <>
                  <h1>
                    {t(
                      "schools.benefits.staff." +
                        staff.find((person) => person.name === activeStaff).name
                    )}
                  </h1>
                  <div className="benefits_abstract_text">
                    <p>
                      {t(
                        "schools.benefits.public.description." +
                          staff.find((person) => person.name === activeStaff)
                            .name +
                          ".p1"
                      )}
                    </p>
                    <p>
                      {t(
                        "schools.benefits.public.description." +
                          staff.find((person) => person.name === activeStaff)
                            .name +
                          ".p2"
                      )}
                    </p>
                  </div>
                </>
              )}
              {type === "private" && (
                <>
                  <h1>{t("schools.benefits.private.title")}</h1>
                  <div className="benefits_abstract_text">
                    <p>{t("schools.benefits.private.p1")}</p>
                    <p>{t("schools.benefits.private.p2")}</p>
                  </div>
                </>
              )}
              {type === "virtual" && (
                <>
                  <h1>{t("schools.benefits.virtual.title")}</h1>
                  <div className="benefits_abstract_text">
                    <p>{t("schools.benefits.virtual.p1")}</p>
                    <p>{t("schools.benefits.virtual.p2")}</p>
                  </div>
                </>
              )}
              {type === "chain" && (
                <>
                  <h1>{t("schools.benefits.chain.title")}</h1>
                  <div className="benefits_abstract_text">
                    <p>{t("schools.benefits.virtual.p1")}</p>
                    <p>{t("schools.benefits.virtual.p2")}</p>
                  </div>
                </>
              )}
            </div>
            {type === "public" && (
              <>
                {activeStaff === "headmaster" && <Headmaster images={images} />}
                {activeStaff === "secretary" && <Secretary images={images} />}
                {activeStaff === "educator" && <Educator images={images} />}
                {activeStaff === "schoolmaster" && (
                  <SchoolMaster images={images} />
                )}
                {activeStaff === "teacher" && <Teacher images={images} />}
                {activeStaff === "headteacher" && (
                  <Headteacher images={images} />
                )}
                {activeStaff === "auxiliar" && <Auxiliar images={images} />}
              </>
            )}
            {type === "private" && <PrivateSchools images={images} />}
            {type === "chain" && <ChainSchools images={images} />}
            {type === "virtual" && <VirtualSchools images={images} />}
            {type === "student" && <StudentsBenefits images={images} />}

            {!hideVideoContainer && (
              <div className="functionalities">
                <>
                  <h1>{t("schools.benefits.functionalities.title")}</h1>

                  <div className="video_container">
                    <VideoContainer type={type} activeStaff={activeStaff} />
                  </div>
                </>

                {activeStaff === "headmaster" && type === "public" && (
                  <div className="func_buttons" ref={introRef}>
                    <>
                      <Button
                        text={t("home.intro.content.buttons.btn1")}
                        type={"primary"}
                        action={scrollToCalculator}
                      />
                      <Button
                        text={t("home.intro.content.buttons.btn2")}
                        type={"ghost"}
                        link={`/${i18n.language}/p/register`}
                        linkType={"intern"}
                      />
                    </>
                  </div>
                )}
              </div>
            )}
            {!hidePackagesLink && (
              <p
                className="benefits_packages-link"
                onClick={() =>
                  activeStaff === "headmaster" || activeStaff === "secretary"
                    ? navigate(`/${i18n.language}/p/subscriptions?user=school`)
                    : navigate(`/${i18n.language}/p/subscriptions?user=teacher`)
                }
              >
                {activeStaff === "headmaster" || activeStaff === "secretary"
                  ? t("schools.benefits.public.linkPackages1")
                  : t("schools.benefits.public.linkPackages2")}
              </p>
            )}
            {type !== "private" && type !== "chain" && type !== "student" && (
              <div className="benefits_star_box">
                <h4>{t("schools.benefits.star-box.title")}</h4>
                <div className="benefits_star_box_flex">
                  <div className="benefits_star_box_column">
                    <h2>{t("schools.benefits.star-box.t1")}</h2>
                    <p>{t("schools.benefits.star-box.p1")}</p>
                  </div>
                  <div className="benefits_star_box_column">
                    <h2>{t("schools.benefits.star-box.t2")}</h2>
                    <p>{t("schools.benefits.star-box.p2")}</p>
                    <p>{t("schools.benefits.star-box.p3")}</p>
                  </div>
                </div>
                <Button
                  text={t("schools.benefits.star-box.button")}
                  type={"ghost"}
                  action={openModal}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </section>
  );
};

export default Benefits;
