import React from "react";

import "./EngTermsConditions.css";

const EngTermsConditions = () => {
  const currentHost = window.location.host;

  return (
    <div className="comp-eng-terms-conditons">
      <h3>Terms and conditions</h3>
      <h4>Welcome to Adservio</h4>
      <p>
        Thank you for using our products and services ("Services"). The services
        are provided by Adservio Social Innovation and Adservio Center
        companies, based in Iasi, 56, Morii Street, hereinafter referred to as
        "Adservio".
      </p>
      <p>
        By using our Services, you agree to these Terms and Conditions. Please
        read them carefully.
      </p>
      <p>
        Our services are dedicated to the educational environment, which also
        involves the processing of minors' data, and may therefore sometimes
        require Additional Terms or conditions for products that will be
        promptly notified before accessing these products. In the case of
        certain Services that will be specifically mentioned by Adservio, there
        may be additional Terms and Conditions, and if you use those Services,
        these Additional Terms and Conditions may be part of the contract
        between the school unit to which the child belongs and Adservio or
        published on the Adservio website -{" "}
        <a href={`https://${currentHost}/`}>{currentHost}</a>
      </p>
      <h4>Use of our Services</h4>
      <p>
        You must comply with all policies made available within the Services.{" "}
      </p>
      <p>
        The use of the Services does not give you any intellectual property
        rights over them or the content that you access. You as Adservio users,
        access / purchase information services, informational school management
        services, equipment and internet endowment services or support services.
        You may not use the content of our Services unless you have become a
        user of our services by contracting your Adservio license through your
        school unit.
      </p>
      <p>
        Our services display a certain non-Adservio content. Responsibility for
        this content rests solely with the entity that makes it available,
        namely school units as well as users, with regard to the messaging
        module. With regard to your use of the Services, we may send you
        service, administrative, and other type of information. You may opt out
        of some or all of these communications by checking / unchecking them in
        your Adservio Account Settings.
      </p>
      <p>
        When you start using Adservio, you may choose to agree or disallow
        Adservio to conduct surveys, sign-up campaigns and promotional campaigns
        among customers utilising the user account, email or phone number as a
        means of communication, renouncing a subscription may be requested at
        any time at the following e-mail address:{" "}
        <a href={`mailTo:relatiiclienti@${currentHost}`}>
          relatiiclienti@adservio.ro
        </a>
        .
      </p>
      <h4>Your Adservio account</h4>
      <p>
        In order to use Adservio services, an Adservio Account is required. By
        contracting the Adservio system by your school unit, you become one of
        the following categories of users: teacher, staff secretariat, senior
        staff, parent or student.
      </p>
      <p>
        Adservio Privacy Policy explains how we treat your personal data and
        protect your privacy when you use our Services.
      </p>
      <h4>Your content within our Services</h4>
      <p>
        Some Services allow you to submit content. Adservio administers the
        interactive school management platform and the modules integrated into
        this platform: the electronic schoolregister, messaging, secretariat,
        the directors' module, the teachers' module and does not interfere with
        system data.
      </p>
      <p>
        Certain Services may provide you with ways to access and remove the
        content provided to that Service. Some Services also have Terms and
        Conditions or Settings that restrict the scope of use of the content
        sent within those Services. Make sure you have the rights to access the
        appropriate modules for the entire content you submit to our Services.
      </p>
      <p>
        You can learn more about how Adservio uses and stores the content in the
        privacy policy or the Additional Terms and conditions for certain
        Services that will be made available to you at{" "}
        <a href={`https://${currentHost}/`}>{currentHost}</a>
        .If you submit feedback or suggestions about the Services, we may use
        that feedback or those suggestions without violating the protection of
        personal data laws.
      </p>
      <h4>About software programs within the Services</h4>
      <p>
        You may not copy, modify, distribute, sell or rent any part of the
        Services and software included; you may not attempt to retrieve or
        attempt to extract the source code of that software unless you have
        written permission from us.
      </p>
      <h4>Change and termination of Service provision</h4>
      <p>
        We constantly modify and improve Services. We may add or remove
        functionalities or features, and these changes will be notified in
        advance so that the provision of services to you will not suffer.
      </p>
      <h4>Warranties and disclaimers</h4>
      <p>
        We provide the Services at a reasonable level of aptitude and attention
        commercially speaking, respecting the security, integrity and
        confidentiality of the data, and we hope you will enjoy using them. But
        there are certain things we cannot promise about the Services. The
        school unit is responsible for the up to date and completeness of the
        data on absences, grades in subjects taught in the system. For the sake
        of clarity, Adservio does not have the right to interfere in any way
        with the system data provided to the school unit unless Adservio
        explicitly undertakes to perform this action by contract, in compliance
        with the legal provisions. In any other situation, Adservio is not
        responsible for the lack of system data that should be provided by the
        school unit or delays in completing the data by the school unit.
      </p>
      <h4>Liability for our Services</h4>
      <p>
        Adservio will under no circumstances be liable for any loss or damage
        falling under the contingency clause category.
      </p>
      <h4>About these Terms and Conditions</h4>
      <p>
        We may change the present Terms and Conditions and Additional Terms and
        Conditions applicable to a Service, for example, to reflect changes in
        our legislation or our Services. Check the Terms and Conditions
        periodically.
        <br /> We will post notices of changes to these Terms and Conditions on
        this page. We will post notifications regarding the modification of the
        Additional Terms and Conditions within the Service concerned. Changes
        will not apply retroactively and will take effect within at least
        fourteen days after posting. However, changes in the new functions of a
        Service and those for legal reasons will come into force immediately. If
        you do not agree with the changes to the Terms and Conditions of a
        Service, we suggest that you discontinue use of the respective Service.
      </p>
      <ul>
        <li>
          {" "}
          In the event of a discrepancy between these Terms and Conditions and
          the Additional Terms and Conditions, the Additional Terms and
          Conditions shall prevail.{" "}
        </li>
        <li>
          {" "}
          These Terms and Conditions govern the relationship between Adservio
          and you. They do not create any rights for third party beneficiaries.{" "}
        </li>
        <li>
          {" "}
          If you do not comply with these Terms and Conditions, and we do not
          take immediate action, it does not mean that we waive any rights we
          have (for example, the right to take action in the future).{" "}
        </li>
        <li>
          If certain terms and conditions are found to be unenforceable, this
          will not affect the rest of the Terms and Conditions.{" "}
        </li>{" "}
      </ul>
      <p>
        {" "}
        For information on how to contact Adservio, please visit our contact
        page.{" "}
      </p>
    </div>
  );
};

export default EngTermsConditions;
