import { useTranslation } from "react-i18next";

const StudentsBenefits = ({ images }) => {
  const { t } = useTranslation("common");

  return (
    <div className="benefits_blue_icons_container" id="students">
      <h1>{t("students.benefits.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["grades-icon.svg"].default} alt="" />
          <p>{t("students.benefits.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["survey-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["signature-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["percent-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r1c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["calendarx-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["groups-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["video-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["homework-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r2c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["library-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r3c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["userpsychologic-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r3c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["university-bround.svg"].default} alt="" />
          <p>{t("students.benefits.r3c3")}</p>
        </div>
      </div>
    </div>
  );
};

export default StudentsBenefits;
