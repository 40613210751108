// import RRA from "../../assets/logos/RRA.svg";
// import ZF from "../../assets/logos/ZF.png";
// import Hotnews from "../../assets/logos/Hotnews.png";
// import Adevarul from "../../assets/logos/Adevarul.svg";
// import Mediafax from "../../assets/logos/Mediafax.svg";
// import Guerilla from "../../assets/logos/Guerilla.svg";
// import digi from "../../assets/logos/Digi24.svg";

// import protv from "../../assets/logos/ProTV.svg";
// import forbes from "../../assets/logos/Forbes.svg";
// import aLive from "../../assets/logos/aLive.png";
// import br from "../../assets/logos/BR.png";
// import Biz from "../../assets/logos/Biz.png";
// import startUp from "../../assets/logos/StartUp.png";

import { mentionsRo, mentionsInternational } from './mentionsConstants';

import { useEffect, useState } from 'react';
import { horizoltalScrollWithContainer, maximScroll } from '../../utility/horizontallScroll';
import { useTranslation } from 'react-i18next';

import './mentions.css';

const Mentions = () => {
  const { t, i18n } = useTranslation('common');
  const [mentionsSliderLeft, setMentionsSliderLeft] = useState([]);
  const [mentionsSliderRight, setMentionsSliderRight] = useState([]);

  let animation = window.innerWidth <= 1200;

  useEffect(() => {
    maximScroll(document.getElementById('MentionsHorizontalScrollToLeft'), 1);
    horizoltalScrollWithContainer(
      document.getElementById('MentionsContainer'),
      document.getElementById('MentionsHorizontalScrollToRight'),
      1
    );
    horizoltalScrollWithContainer(
      document.getElementById('MentionsContainer'),
      document.getElementById('MentionsHorizontalScrollToLeft'),
      -1
    );
  }, []);

  useEffect(() => {
    const onDivideMentions = () => {
      const mentions = i18n.language === 'ro' ? mentionsRo : mentionsInternational;
      const mentionsLeft = [];
      const mentionsRight = [];

      mentions.forEach((el, index) => {
        if (index < (mentions.length - 1) / 2) mentionsLeft.push(el);
        else mentionsRight.push(el);
      });

      setMentionsSliderLeft(mentionsLeft);
      setMentionsSliderRight(mentionsRight);
    };

    onDivideMentions();
  }, [i18n.language]);

  return (
    <>
      <section id="MentionsContainer">
        <h1>{t('home.mentions.title')}</h1>
        <div>
          <div
            className={
              animation ? 'mentionsSlider left-to-right LeftRight' : 'mentionsSlider left-to-right'
            }
            id="MentionsHorizontalScrollToRight"
          >
            {mentionsSliderLeft.length &&
              mentionsSliderLeft.map((el) => {
                return <img key={el.id} src={el.img} alt={el.name} className={el.name} />;
              })}
          </div>
          <div
            className={
              animation ? 'mentionsSlider right-to-left RightLeft' : 'mentionsSlider right-to-left'
            }
            id="MentionsHorizontalScrollToLeft"
          >
            {mentionsSliderRight.length &&
              mentionsSliderRight.map((el) => {
                return <img key={el.id} src={el.img} alt={el.name} className={el.name} />;
              })}
          </div>
        </div>
      </section>
      <style></style>
    </>
  );
};

export default Mentions;
