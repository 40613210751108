import introBg from "../../assets/about/Group 1582.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const AboutIntro = () => {
  const { t } = useTranslation("common");
  return (
    <Intro>
      <img src={introBg} alt="" />
      <div className="textContainer">
        <h1>{t("about.intro.title")}</h1>
        <p>
          {t("about.intro.p.1")}
          <br />
          <br />
          {t("about.intro.p.2")} <br />
          <br />
          {t("about.intro.p.3")}
        </p>
      </div>
      <div className="cardsContainer">
        <section className="card">
          <span>{t("about.intro.cards.card1.span")}</span>
          <h2>{t("about.intro.cards.card1.title")}</h2>
        </section>
        <section className="card">
          <span>{t("about.intro.cards.card2.span")}</span>
          <h2>{t("about.intro.cards.card2.title")}</h2>
        </section>
        <section className="card">
          <span>{t("about.intro.cards.card3.span")}</span>
          <h2>{t("about.intro.cards.card3.title")}</h2>
        </section>
      </div>
    </Intro>
  );
};

export default AboutIntro;

const Intro = styled.section`
  position: static;

  margin-top: 102.5px;
  max-width: 1540px;
  margin-left: auto;
  margin-right: auto;

  & img {
    position: absolute;
    height: 570px;
    width: auto;
    margin-top: -50px;
    right: -50px;
    z-index: -1;
  }
  & .textContainer {
    max-width: 992px;
    & h1 {
      font-family: "Manrope";
      font-size: 50px;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
      color: var(--dark-slate-blue);
    }
    & p {
      font-family: "Manrope";
      font-size: 20px;
      font-weight: 500;
      line-height: 1.6;
      text-align: left;
      color: var(--tertiary-heavy);
      margin-top: 29.4px;
      & br {
        display: none;
      }
    }
  }
  & .cardsContainer {
    margin-top: 115.8px;
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
    & .card {
      max-width: 369px;
      &:not(:nth-of-type(1)) {
        margin-left: 140px;
      }
      &:nth-of-type(1) span {
        background-color: var(--main-heavy);
      }
      &:nth-of-type(2) span {
        background-color: var(--macaroni-and-cheese);
      }
      &:nth-of-type(3) span {
        background-color: var(--confirmation-accent);
      }
      & span {
        height: 40px;
        width: 100px !important;
        min-width: 100px;
        text-align: center;
        font-family: "Manrope";
        font-size: 20px;
        font-weight: bold;
        line-height: 1.27;
        color: var(--tertiary-ultralight);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & h2 {
        margin-top: 9px;
        font-family: "Manrope";
        font-size: 34px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
        color: var(--tertiary-heavy);
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    & .textContainer {
      margin-left: 50px;
      max-width: 850px;
      & h1 {
        font-size: 35px;
      }
      & p {
        font-size: 18px;
      }
    }
    & img {
      margin-top: -120px;
      height: 500px;
    }

    & .cardsContainer {
      & .card {
        margin-left: 50px !important;
        max-width: 300px;
        & h2 {
          font-size: 25px;
        }
      }
    }
  }
  @media only screen and (max-width: 1250px) {
    margin-top: 75px;

    & img {
      position: absolute;
      height: 400px;
      width: auto;
      margin-top: -50px;
      right: -50px;
    }

    &.textContainer {
      margin-right: 50px;
      & h1 {
        font-size: 30px;
      }
      & p {
        font-size: 17px;
      }
    }

    & .cardsContainer {
      & .card {
        margin-left: 30px !important;
        max-width: 250px;
        & h2 {
          font-size: 22px;
        }
      }
    }
  }
  @media only screen and (max-width: 750px) {
    & img {
      height: 400px;
      width: auto;
      margin-top: 0px;
      right: -50px;
      margin-top: 170px;
    }

    & .textContainer {
      max-width: 992px;
      margin: 25px 17px 0 23px;
      & h1 {
        font-size: 26px;
        line-height: 1.23;
      }
      & p {
        font-size: 16px;
        line-height: 1.5;
        margin: 14.7px 50px 0 0;
        & br {
          display: block;
        }
      }
    }
    & .cardsContainer {
      margin-top: 167px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-items: flex-start;
      width: calc(100% - 38px);
      margin-left: 23px;
      padding: 0;
      & .card {
        max-width: 100%;
        display: flex;
        margin-left: 0 !important;
        align-items:center;
        &:not(:nth-of-type(3)) {
          margin-bottom: 40px;
        }
        & span {
          font-size: 20px;
          margin-right: 16px;
        }
        & h2 {
          text-align: left;
          font-size: 20px;
          line-height: 1.3;
          margin: 0;
        }
      }
    }
  }
`;
