import { useState } from "react";
import ArrowUp from "../../assets/schools/solutions/arrow-up.svg";

const SolutionBox = ({ title, text, moreContent, open, p2, p3, p4, p5 }) => {
  const [boxState, setboxState] = useState(open);
  const clickHandler = (el) => {
    setboxState(!boxState);
  };
  return (
    <div className="solution_box">
      <div className="question_container" onClick={() => clickHandler(this)}>
        <h2>{title}</h2>
        <img
          src={ArrowUp}
          alt={ArrowUp}
          className={boxState ? "openBox" : "closeBox"}
        />
      </div>
      {boxState && (
        <>
          <div className="slide_down_content">
            <p>{text}</p>
            {moreContent && (
              <>
                <div className="solution_box_row">
                  <div className="paragraph_start">
                    <div className="colored_rectangle"></div>
                    <p>{p2}</p>
                  </div>
                  <div className="paragraph_start">
                    <div className="colored_rectangle"></div>
                    <p>{p3}</p>
                  </div>
                </div>
                <div className="solution_box_row">
                  <div className="paragraph_start">
                    <div className="colored_rectangle"></div>
                    <p>{p4}</p>
                  </div>
                  <div className="paragraph_start">
                    <div className="colored_rectangle"></div>
                    <p>{p5}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SolutionBox;
