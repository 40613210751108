import {useTranslation} from "react-i18next";
import "./digitalization.css";
import DigitalizationVideoContainer from "./DigitalizationVideoContainer";
import horizoltalScroll from "../../utility/horizontallScroll";
import dragToScroll from "../../utility/dragToScroll";
import {useEffect} from "react";

import img1 from "../../assets/youtubeThumbnails/jeanmonnet2-thumbnail.png";
import img2 from "../../assets/youtubeThumbnails/buzau-thumbnail.png";
import img3 from "../../assets/youtubeThumbnails/directoare-lascar-rosetti-thumbnail.png";
import img4 from "../../assets/youtubeThumbnails/elevă-lascar-rosetti-thumbnail.png";
import img5 from "../../assets/youtubeThumbnails/jeanmonnet1-thumbnail.png";
import img6 from "../../assets/youtubeThumbnails/colegiul_silvic.png";
import img7 from "../../assets/youtubeThumbnails/Liceul_Reformat.png";
import img8 from "../../assets/youtubeThumbnails/colegiul_silvic_bucovina.png";
import img9 from "../../assets/youtubeThumbnails/colegiul_national_dragos_voda.png";

const Digitalization = ({text, setVideoUrl, type}) => {
  const {t} = useTranslation("common");
  useEffect(() => {
    horizoltalScroll(document.getElementById("videoScroll"));
    dragToScroll(document.getElementById("videoScroll"));
  }, []);
  const videoData = {
    1: {
      url: "https://youtu.be/y9V0ZbTCFeY",
      description: t("schools.digitalization.video5.description"),
      name: t("schools.digitalization.video5.mention.name"),
      place: t("schools.digitalization.video5.mention.place"),
      img: img1,
    },
    2: {
      url: "https://youtu.be/Ul13cd198aU",
      description: t("schools.digitalization.video1.description"),
      name: t("schools.digitalization.video1.mention.name"),
      place: t("schools.digitalization.video1.mention.place"),
      img: img2,
    },
    3: {
      url: "https://youtu.be/2owzbjzxmFg",
      description: t("schools.digitalization.video3.description"),
      name: t("schools.digitalization.video3.mention.name"),
      place: t("schools.digitalization.video3.mention.place"),
      img: img3,
    },
    4: {
      url: "https://www.youtube.com/watch?v=42ajZ_3iG3Q",
      description: t("schools.digitalization.video2.description"),
      name: t("schools.digitalization.video2.mention.name"),
      place: t("schools.digitalization.video2.mention.place"),
      img: img4,
    },
    5: {
      url: "https://youtu.be/eFRKOoDn5w4",
      description: t("schools.digitalization.video4.description"),
      name: t("schools.digitalization.video4.mention.name"),
      place: t("schools.digitalization.video4.mention.place"),
      img: img5,
    },
    6: {
      url: "https://www.youtube.com/watch?v=cs0zcWxtXXg",
      description: t("schools.digitalization.video6.description"),
      name: t("schools.digitalization.video6.mention.name"),
      place: t("schools.digitalization.video6.mention.place"),
      img: img6,
    },
    7: {
      url: "https://youtu.be/Nfd3_dWcMU8",
      description: t("schools.digitalization.video7.description"),
      name: t("schools.digitalization.video7.mention.name"),
      place: t("schools.digitalization.video7.mention.place"),
      img: img7,
    },
    8: {
      url: "https://www.youtube.com/watch?v=xEJLSbFrilg",
      description: t("schools.digitalization.video8.description"),
      name: t("schools.digitalization.video8.mention.name"),
      place: t("schools.digitalization.video8.mention.place"),
      img: img8,
    },
    9: {
      url: "https://www.youtube.com/watch?v=EXEIkabLclk&ab_channel=Adservio",
      description: t("schools.digitalization.video9.description"),
      name: t("schools.digitalization.video9.mention.name"),
      place: t("schools.digitalization.video9.mention.place"),
      img: img9,
    },
  };
  const videos = {
    public: [9, 6, 7, 1, 2, 3, 5],
    virtual: [6, 7, 1, 2, 3, 4, 5],
    private: [6, 7, 1, 2],
    chain: [6, 7, 1, 2],
    student: [4, 8],
  };
  return (
    <section className="digitalization">
      <div className="digitalization_container">
        <h1>{text}</h1>
        <div className="videos_container" id="videoScroll">
          {videos[type].map((el, index) => {
            return (
              <DigitalizationVideoContainer
                url={videoData[el].url}
                description={videoData[el].description}
                name={videoData[el].name}
                place={videoData[el].place}
                img={videoData[el].img}
                setVideoUrl={setVideoUrl}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Digitalization;
