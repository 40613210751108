import React, { useEffect } from 'react';
import square_dots_BG from '../../assets/subscriptions/square_dots_BG.svg';
import checkmark from '../../assets/subscriptions/checkmark_icon.svg';
// import arrow_down from '../../assets/subscriptions/arrowdown_expand.svg';
import Api2 from '../../../src/Libs/api';

// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import useCheckHostname from '../../utility/helper';
const SchoolCards = () => {
  // const [activePriceIndex, setActivePriceIndex] = useState(0);
  // const pricePackage1 = useCheckHostname('education') ? 3.99 : 1.99;
  // const pricesPackage2 = [199, 299, 499];
  // const currency = '€';
  // const navigate = useNavigate();

  const [organizationSubscriptions, setOrganizationSubscriptions] = useState();

  const getTranslation = (subscription, field, language = 'ro', fallback) => {
    return subscription[field].find((l) => l.langId === language)?.value || fallback;
  };

  const getLangfromUrl = () => {
    let urlData = window.location.pathname.slice(1, 3);
    if (urlData === 'ro') {
      return urlData;
    }
    return 'en';
  };

  useEffect(() => {
    let organizationSubs = [];

    const fetchOrganizationSubscriptions = async () => {
      Api2.get(
        `/subscriptions/packages/available?countryId=1&subscriberType=organization&withDetails=true`
      ).then((data) => {
        const sortedData = data.data.sort((a, b) => a.order - b.order);

        setOrganizationSubscriptions(sortedData);
        organizationSubs = sortedData;
      });
    };

    organizationSubs?.length === 0 && fetchOrganizationSubscriptions();
  }, []);

  return (
    <div className="packages__cards">
      {organizationSubscriptions?.map((subscription, index) => {
        const lang = getLangfromUrl();
        const featuresDescription = getTranslation(subscription, 'featuresDescription', lang);
        const subscriptionName = getTranslation(subscription, 'packageName', lang);
        const subscriptionDescription = getTranslation(subscription, 'packageDescription', lang);

        return (
          <div
            className={`packages__card`}
            style={{ '--theme-color': `var(--${subscription.colorTheme}-500)` }}
            key={index}
          >
            {index === 0 && (
              <img className="packages__card__squaredots" src={square_dots_BG} alt="square-dots" />
            )}
            <h3>{subscriptionName}</h3>
            <p className="txt-center">{subscriptionDescription}</p>
            <br />
            <h5>{featuresDescription}</h5>

            <div className="packages__card__benefits">
              {Array.from({ length: 5 }, (_, i) => i + 1).map((i, index) => {
                const benefit = getTranslation(subscription, `feature${i}`, lang);

                return benefit ? (
                  <div className="packages__card__benefit" key={index}>
                    <img src={checkmark} alt="checkmark" />
                    <p>{benefit}</p>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        );
      })}
      {/* <div
        className={`packages__card ${
          card1Open ? '' : 'packages__card--closed-350'
        } packages__card--light--purple schools__card--1`}
      >
        <img className="packages__card__squaredots" src={square_dots_BG} alt="square-dots" />
        <h3>{t('packages.school.0.name')}</h3>
        <p className="txt-center">{t('packages.school.0.type')}</p>
        <h4>
          <span>
            {currency}
            {pricePackage1}
          </span>
          <div className="vat-included--1">
            <p>{t('packages.vatIncluded')}</p>
          </div>
          <br /> {t('packages.school.0.billingType2')}
        </h4> 
        <br />
        <h5>{t('packages.school.0.benefitsTitle')}</h5>

        <div className="packages__card__benefits">
          {Array.from(Array(parseInt(t('packages.school.0.noOfBenefits')))).map((_, index) => {
            return (
              <div className="packages__card__benefit" key={index}>
                <img src={checkmark} alt="checkmark" />
                <p>{t(`packages.school.0.benefits.${index}`)}</p>
              </div>
            );
          })}
        </div>

         <div className="packages__card__bottom">
          <div className="packages__card__bottom__gradient"></div>
          <div className="packages__card__separator packages__card__bottom__separator"></div>
          <img
            className="packages__card__arrow"
            src={arrow_down}
            alt="arrow"
            onClick={() => setCard1Open(true)}
          />
          <button
            className="packages__card__button"
            onClick={() =>
              navigate(`/${i18n.language}/p/subscriptions/chosen?user=school&package=0`)
            }
          >
            {t('packages.school.0.btn')}
          </button>
        </div> 
      </div> */}
    </div>
  );
};

export default SchoolCards;
