import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {Header} from "./header";
import RoCookiePolicy from "./CookiePolicy/RoCookiePolicy";
import EngCookiePolicy from "./CookiePolicy/EngCookiePolicy";
import Footer from "./footer/Footer";
import Api2 from "../../../src/Libs/api";

const Cookies = () => {
  const { i18n } = useTranslation("common");
  const [flags, setFlags] = useState();
  let { lng } = useParams();

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };

  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  return (
    <>
      <Header flags={flags} />

      {i18n.language === "ro" ? <RoCookiePolicy /> : <EngCookiePolicy />}

      <Footer flags={flags} />
    </>
  );
};

export default Cookies;
