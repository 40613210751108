import general from "./general.json";
import about from "./about.json";
import csr from "./csr.json";
import header from "./header.json";
import home from "./home.json";
import institutions from "./institutions.json";
import register from "./register.json";
import parents from "./parents.json";
import schools from "./schools.json";
import students from "./students.json";
import contact from "./contact.json";
import packages from "./packages.json";
import subscriptions from "./subscriptions.json";

const language = {
  about,
  csr,
  header,
  general,
  home,
  institutions,
  parents,
  register,
  schools,
  students,
  contact,
  packages,
  subscriptions,
};

export default language;
