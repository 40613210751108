import introImg from "../../assets/students/studentsBg.svg";
import "./introStudents.css";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import { useEffect } from "react";
const IntroStudents = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <section className="intro_students">
      <img src={introImg} alt={introImg} data-aos="fade-left" />
      <div className="intro_students_content">
        <h1>{t("students.intro.title")}</h1>
        <div className="intro_students_text_container">
          <div className="intro_students_wrapper">
            <p>{t("students.intro.p1")}</p>
          </div>
          <div className="intro_students_wrapper">
            <p>{t("students.intro.p2")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroStudents;
