import img1 from "../../assets/about/img_PO.png";
import img2 from "../../assets/about/Mask Group 8.png";

const FirstImageContainer = () => {
  return (
    <>
      <svg
        id="firstImagesSection"
        xmlns="http://www.w3.org/2000/svg"
        width="757.395"
        height="615.67"
        viewBox="0 0 757.395 615.67"
      >
        <g
          id="Group_1655"
          data-name="Group 1655"
          transform="translate(-1161.605 -1151.842)"
        >
          <rect
            id="Rectangle_292"
            data-name="Rectangle 292"
            width="286.961"
            height="286.961"
            transform="translate(1161.605 1767.512) rotate(-90)"
            fill="#34296e"
          />
          <rect
            id="Rectangle_293"
            data-name="Rectangle 293"
            width="179.271"
            height="179.271"
            transform="translate(1739.729 1659.105) rotate(-90) scale(2)"
            fill="#f7c52f"
          />
          <rect
            id="Rectangle_295"
            data-name="Rectangle 295"
            width="179.271"
            height="179.271"
            transform="translate(1739.729 1479.83) rotate(-90)"
            fill="#00a4d0"
          />
          <circle
            id="Ellipse_197"
            data-name="Ellipse 197"
            cx="21.983"
            cy="21.983"
            r="21.983"
            transform="translate(1772.087 1446.77) rotate(-90)"
            fill="#f7f7ff"
          />
          <circle
            id="Ellipse_199"
            data-name="Ellipse 199"
            cx="21.983"
            cy="21.983"
            r="21.983"
            transform="translate(1842.679 1377.336) rotate(-90)"
            fill="#f7f7ff"
          />
          <rect
            id="Rectangle_296"
            data-name="Rectangle 296"
            width="179.271"
            height="179.271"
            transform="translate(1560.49 1659.105) rotate(-90)"
            fill="#e54f6d"
          />
          <circle
            id="Ellipse_201"
            data-name="Ellipse 201"
            cx="66.414"
            cy="66.414"
            r="66.414"
            transform="translate(1582.599 1635.834) rotate(-90)"
            fill="#fff"
          />
          <path
            id="Path_1293"
            data-name="Path 1293"
            d="M179.268,179.241H0V0A179.845,179.845,0,0,1,179.268,179.241Z"
            transform="translate(1560.489 1479.83) rotate(-90)"
            fill="#f7c52f"
          />
          <path
            id="Path_1294"
            data-name="Path 1294"
            d="M0,111.922V0H111.9A112.281,112.281,0,0,1,0,111.922Z"
            transform="translate(1448.568 1480.738) rotate(-90)"
            fill="#f7c52f"
          />
          <circle
            id="Ellipse_202"
            data-name="Ellipse 202"
            cx="108.284"
            cy="108.284"
            r="108.284"
            transform="translate(1343.852 1151.842)"
            fill="#e54f6d"
          />
        </g>
        <image
          id="img1"
          href={img1}
          color="red"
          height="382.5"
          width="500"
          transform="translate(-120 240)"
        />
        <image
          id="img2"
          href={img2}
          height="202.2"
          width="179.2"
          transform="translate(400 126.35)"
        />
      </svg>
    </>
  );
};

export default FirstImageContainer;
