import React, { useEffect } from "react";

import "./EngCookiePolicy.css";

const EngTermsConditions = () => {
  const currentHost = window.location.host;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://consent.cookiebot.com/c683ff1e-ea9a-458e-ae91-e2d33348dfdb/cd.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="comp-eng-cookie-policy">
      <h1>Cookie Policy</h1>
      <p>
        This policy regulates the use of cookies and establishes the legal basis
        for Adservio Social Innovation for the use of "cookies" and similar
        technologies on the website or on systems related to the website:&nbsp;
        <u>
          <strong>
            <a href={`https://${currentHost}/`}>{currentHost}</a>
          </strong>
        </u>
        {". "}
        This cookie policy is valid from 01.05.2022.
      </p>
      <p>
        "Essential" cookies are automatically placed on your computer, laptop or
        any device that can access or operate on the company's website.
        "Non-essential" cookies and other similar technologies are placed only
        on the user's computer or device if he / she has given his / her
        explicit consent. Additional information on the difference between
        essential and non-essential cookies can be found in the section below
        entitled "About cookies".
      </p>
      <p>
        Information on how to consent to non-essential cookies and other
        technologies and how to withdraw this consent can be found in the
        section below entitled "How to accept or reject cookies"
        <strong>.</strong>
      </p>

      <br />
      <p>
        <strong>Contents</strong>
      </p>

      <ul className="cuprins">
        <li>
          <a href="#aboout_cookies">About Cookies</a>
        </li>
        <li>
          <a href="#types_cookies">The types of cookies used</a>
        </li>
        <li>
          <a href="#essential_cookies">Essential cookies</a>
        </li>
        <li>
          <a href="#non_essential_cookies">Non-essential cookies</a>
        </li>
        <li>
          <a href="#web_beacon">Web beacons</a>
        </li>
        <li>
          <a href="#facebook_pixel">Facebook Pixel</a>
        </li>
        <li>
          <a href="#how_reject">How to accept or reject cookies</a>
        </li>
      </ul>

      <h2 id="aboout_cookies">About cookies</h2>

      <h3>What are cookies?</h3>

      <p>
        Cookies are small text files sent by a website server to a web browser
        (web browser: IE, Firefox, Opera), RAM or hard drive and then stored
        there. They can be used for a variety of purposes, such as customizing
        the website according to the needs of a particular user, helping a user
        navigate the website, improving the quality of the website for the user,
        or storing user preferences and information. connection.
      </p>

      <h3>Essential and non-essential cookies</h3>
      <p>Cookies can be classified as "essential" or "non-essential".</p>
      <p>Essential cookies: have one of the following two characteristics:</p>
      <ul>
        <li>
          are used exclusively for making or facilitating communication in a
          network; or
        </li>
        <li>
          are strictly necessary for the provision of an online service (e.g.
          our site or a service on our site) requested by you.
        </li>
      </ul>
      <p>
        Non-essential cookies: These do not fall within the definition of
        essential cookies because they are not strictly necessary for the
        operation of a site or the services offered on it, such as cookies used
        to analyze the behavior on a website ("analytical" cookies ) or cookies
        used to display ads.
      </p>
      <h3>Session and persistent cookies</h3>
      <p>
        Cookies can be of two types: "session" and "persistent", depending on
        the storage period after they are placed in your browser.
      </p>
      <p>
        <u>Session cookies: </u>
        remain on the device as long as the web browser is open. They expire
        when the web browser is closed.
      </p>
      <p>
        <u>Persistent cookies:</u>
        expire at a fixed point in time or if they are manually deleted from the
        web browser.
      </p>

      <h3>Our or third-party cookies</h3>
      <p>
        Cookies can be classified into two groups: those belonging to the site
        visited or those belonging to third parties.
      </p>
      <p>
        <u>Cookies that belong to our site (primary):</u>
        these are cookies placed on your device by the domain of our site.
      </p>
      <p>
        <u>Third-party cookies: </u>
        These are cookies placed on your device by domains of third-party sites.
        Additional information can be found at{" "}
        <u>
          <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>
        </u>
      </p>

      <h2 id="types_cookies">Types of cookies used</h2>
      <p>We use the following cookies on our website: </p>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/c683ff1e-ea9a-458e-ae91-e2d33348dfdb/cd.js"
        type="text/javascript"
        async
      ></script>

      <h2 id="essential_cookies">Essential cookies</h2>
      <p>
        These are cookies that are strictly necessary for the proper functioning
        of our website or for the provision of services that you request using
        our website. We use the following essential cookies on our website:
      </p>
      <p>
        - the primary session cookies used to identify and authenticate a user
        when they connect to the site, so that it is not necessary to enter the
        login information repeatedly. These cookies are presented in the table
        above.
      </p>
      <p>
        - Persistent third-party cookies used to detect user consent to the use
        of cookies on our site. These cookies are listed in the table above.
      </p>
      <p>
        Legal basis for processing: the processing of user information contained
        in or obtained through essential cookies is carried out in legitimate
        interests (Article 6 (1) (f) of the General Data Protection Regulation
        (GDPR) for personal data).
      </p>
      <p>
        Legitimate interests: ensuring the proper functioning of the site and
        the requested online services.
      </p>

      <h3>How to decline essential cookies</h3>
      <p>
        Most types of web browsers allow you to block all cookies, including
        essential cookies. Please note that if all cookies are blocked, certain
        components and functions of the site may be affected.
      </p>
      <p>
        Existing cookies in the web browser can be deleted by selecting the
        option to delete cookies from the option to delete browsing data.
      </p>
      <p>
        More detailed information on how to accept and reject cookies, including
        instructions for certain browsers, can be found in the section below
        entitled "How to accept or reject cookies".
      </p>
      <br />
      <h2 id="non_essential_cookies">Non-essential cookies</h2>
      <p>We use the following types of non-essential cookies on our website:</p>
      <p>• Functional cookies</p>
      <p>• Analytical (or performance) cookies</p>
      <p>• Targeting (or advertising) cookies</p>

      <h3>Functional cookies</h3>
      <p>
        These are cookies designed to improve the functionality of the site.
        These are not strictly essential for the proper functioning of the
        website or for the services requested on it, or are cookies that serve
        non-essential purposes, in addition to the essential purpose. We use the
        following functional cookies on the site:
      </p>
      <p>
        - Session or persistent third-party cookies used to recognize a user
        when they use the live chat service. These cookies are listed in the
        table above
      </p>
      <br />
      <h3>Accepting or rejecting functional cookies</h3>
      <p>
        Navigate to the main section entitled "How to accept or reject cookies".
        Processing of personal information about the user, contained or obtained
        through functional cookies
      </p>
      <br />
      <p>
        <strong>Legal basis for processing: </strong>
        the processing of information about users contained in or obtained
        through essential cookies is carried out in legitimate interests
        (Article 6 (1) (f) of the General Regulation on the Protection of
        Personal Data (GDPR)). We will only process personal data if you have
        agreed to place cookies on your computer or device.
      </p>
      <br />
      <p>
        <strong>Legitimate interests:</strong>
        improving the quality and functionality of the website and providing or
        improving the services requested on the site.
      </p>
      <br />
      <p>
        <strong>Analytical (or performance) cookies</strong>
      </p>
      <p>
        Analytical (or performance) cookies track and collect data about what a
        user does on the visited website. These cookies are not essential for
        the proper functioning of the site. We use the following analytical
        cookies on our website:
      </p>
      <p>
        We use Google Analytics cookies on the site. Google Analytics cookies
        help to understand how you interact with the website, including how a
        user entered the website, the pages accessed, how long and how many
        objects were accessed, the location (based on the IP address).
      </p>
      <p>
        The Google Analytics cookies used on this site are listed in the table
        above. These cookies are persistent cookies. These cookies expire after
        2 years.
      </p>
      <p>
        The information collected using analytics cookies is collected
        anonymously.
      </p>
      <p>
        <u>More information</u>
      </p>
      <p>
        Google Analytics cookies are classified as primary cookies because they
        are set by the domain of the site, although Google collects and
        processes information using Google Analytics. More information on how
        Google handles information collected through Google Analytics is in the
        Google Analytics Privacy Policy, available here: &nbsp;
        <a href="https://support.google.com/analytics/answer/6004245">
          support.google.com/analytics/answer/6004245
        </a>
      </p>
      <p>
        Information on how Google uses data from the cookies it uses can be
        found at&nbsp;
        <u>
          <a href="http://www.google.com/policies/privacy/partners/">
            www.google.com/policies/privacy/partners/
          </a>
        </u>
      </p>
      <br />
      <p>
        <u>Accepting or rejecting analytical cookies</u>
      </p>
      <p>
        Navigate to the main section entitled "How to accept or reject cookies".
      </p>
      <p>
        Google Analytics may not be monitored on all websites in general by
        following the instructions at&nbsp;
        <u>
          <a href="http://tools.google.com/dlpage/gaoptout">
            tools.google.com/dlpage/gaoptout
          </a>
        </u>
      </p>
      <br />
      <p>
        <u>
          Processing user information contained in or obtained through
          analytical cookies
        </u>
      </p>
      <br />
      <p>
        <strong>Legal basis for processing:</strong>
        processing of information about users contained in or obtained through
        analytical cookies in legitimate interests (Article 6 (1) (f) of the
        General Regulation on the Protection of Personal Data (GDPR).
      </p>
      <br />
      <p>
        <strong>Legitimate interests:</strong>
        analyzing how people use the website to improve the site and business.
        Further information on how to use the information obtained from the use
        of analytics cookies, including profiling, can be found in the section
        entitled "Use of automated decision-making and profiling systems" in the
        Privacy Policy, and is available here:&nbsp;
        <a
          href={`https://${currentHost}/en/p/privacy-policy`}
        >{`https://${currentHost}/en/p/privacy-policy`}</a>
        .
      </p>
      <br />
      <p>
        <strong>Third party cookies</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        Third parties use cookies to analyze users' use of our site for the
        purpose of displaying advertisements (including third-party
        advertisements) to users [and] [enter other uses of third-party cookies,
        e.g. Ad conversion tracking, such as Google AdWords]. Third-party
        cookies used on our site include:
      </p>
      <br />
      <p>
        <strong>Additional Information</strong>
      </p>
      <p>
        Information about the cookies used by Google in connection with the
        above is in the "Advertising" section and is available at:
        www.google.com/policies/technologies/types/
      </p>
      <p>
        Information about how Google uses data from cookies for its own purposes
        can be found at www.google.com/policies/privacy/partners/
      </p>
      <br />
      <h2 id="how_reject">Accepting or rejecting third party cookies</h2>
      <p>
        Navigate to the main section entitled "How to accept or reject cookies".
      </p>
      <p>
        <u>
          The processing of your data contained in or obtained through third
          party cookies
        </u>
      </p>
      <p>
        <strong>Legal basis for processing:</strong>
        consent (Article 6 (1) (a) of the General Data Protection Regulation).
      </p>
      <p>
        <strong>Consent: </strong>
        You give your consent to the purposes for which the information is
        processed by clicking / accepting the purposes for which these
        advertising cookies are used on our website.
      </p>
      <p>
        <strong></strong>
      </p>

      <p>
        <strong></strong>
      </p>
      <h2 id="web_beacon">Web Beacons</h2>
      <p>
        We and any marketing companies we use also incorporate web beacons into
        their marketing emails. Web Beacons are small GIF image files that allow
        you to track the receipt of marketing emails, how often your website ads
        or pages, location, IP address, and web browser information are viewed.
        Web Beacons are activated whenever a marketing email is opened or a page
        is accessed on the site that contains a Web Beacon. Web Beacons transmit
        data when viewed, but are unable to access other information on your
        computer. Web Beacons are not stored on your hard drive unless they are
        downloaded with the GIF image.
      </p>
      <p>
        Some browsers (but not all) allow you to restrict the use of Web beacons
        either by preventing the sending of information to their source (for
        example, when the browser is set to block cookies and trackers), or by
        not accessing the images that contain them. for example, if the "Do not
        display images (in e-mail)" setting on the e-mail server is selected.
      </p>
      <p>
        <u>Accepting or rejecting web beacons</u>
      </p>
      <p>
        Navigate to the main section entitled "How to accept or reject cookies".
      </p>
      <p>
        <strong>Legal basis for processing: </strong>
        the processing of user data contained in or obtained through web beacons
        is carried out on the basis of legitimate interests (Article 6 (1) (f)
        of the General Data Protection Regulation (GDPR) for personal data).
      </p>
      <p>
        <strong>Legitimate business interest:</strong>
        analyzing the effectiveness of email marketing campaigns
      </p>
      <h2 id="facebook_pixel">Facebook Pixel</h2>
      <p>
        Our website uses Facebook Pixel. Facebook Pixel is a code that allows
        you to track and monitor the success of ads displayed on Facebook and
        improve the effectiveness of those ads by recording information such as
        the device used to access the site and actions performed using cookies.
        You can also use Facebook Pixel to create targeted ads on Facebook and
        our site.
      </p>
      <p>
        Facebook combines data collected from the use of the Facebook Pixel on
        the site with data it collects from other sources, in order to improve
        and segment the ads displayed on the site or through its services, to
        improve its systems and to provide advertising performance measurement
        service. More information on how Facebook handles the information it
        collects about users and others can be found in their privacy policy at:
        www.facebook.com/about/privacy
      </p>
      <p>
        <u></u>
      </p>
      <p>
        <u>Accepting or rejecting the Facebook Pixel</u>
      </p>
      <p>
        Navigate to the main section entitled "How to accept or reject cookies".
      </p>
      <br />
      <p>
        <strong>Legal basis for processing: </strong>
        the processing of user information contained in or obtained through the
        Facebook Pixel is carried out on the basis of legitimate interests
        (Article 6 (1) (f) of the General Regulation on the Protection of
        Personal Data (GDPR)).
      </p>
      <br />
      <p>
        <strong>Legitimate interest: </strong>
        analyzing the effectiveness of Facebook ads. Additional information on
        how to use the information collected through Facebook Pixel [, including
        automated decision-making and profiling, can be found in the section
        entitled Using automated decision-making and profiling systems in the
        privacy policy, available here:
        <u>
          <a href="http://www.adservio.ro/en/p/privacy-policy">
            www.adservio.ro/en/p/privacy-policy
          </a>
        </u>
      </p>
      <br />
      <p>
        <strong>How cookies are accepted or rejected</strong>
      </p>
      <p>
        There are a number of different ways in which some or all types of
        cookies and similar technologies may be accepted or rejected. Some of
        the main ways to do this are described below:
      </p>
      <p>
        You are welcome to block the use of some or all of the cookies that are
        used on the website. However, please be aware that this may affect the
        website and its functionality in whole or in part.
      </p>
      <p>
        You should also be aware that deleting all cookies from your web browser
        will also delete all cookies that store your preferences, for example
        whether or not you have accepted the use of cookies on a website or any
        cookies. which blocks other cookies.
      </p>
      <p>
        Detailed information on cookies and browser settings can be found by
        accessing the following link:&nbsp;
        <u>
          <a href="www.allaboutcookies.org/">www.allaboutcookies.org/</a>
        </u>
      </p>
      <br />
      <p>
        <strong>Accepting or rejecting cookies</strong>
      </p>
      <p>
        <u>Browser settings</u>
      </p>
      <p>
        You can accept or reject some or all cookies (for example, by blocking
        all third-party cookies) by adjusting your browser settings. How to do
        this can be found by accessing the following links for the most popular
        web browsers:
      </p>
      <br />
      <p>
        - Google Chrome:&nbsp;
        <u>
          <a href="https://support.google.com/chrome/answer/95647">
            https://support.google.com/chrome/answer/95647
          </a>
        </u>
      </p>
      <p>
        -MozillaFirefox:&nbsp;
        <u>
          <a href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox?redirectlocale=ro-US&amp;redirectslug=Clear+Recent+History">
            https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox?redirectlocale=ro-US&amp;redirectslug=Clear+Recent+History
          </a>
        </u>
      </p>
      <p>
        - Microsoft Internet Explorer:&nbsp;
        <u>
          <a href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer">
            https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer
          </a>
        </u>
      </p>
      <p>
        - Apple Safari:&nbsp;
        <u>
          <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">
            https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac
          </a>
        </u>
      </p>
      <br clear="all" />
      <p>
        Some browsers, such as Chrome and Firefox, allow you to change the
        settings for browsing in "incognito" mode, limiting the amount of data
        entered on your device and automatically deleting all persistent cookies
        entered on your device when the browsing session ends. There are also
        many third-party applications that can be added to your browser to block
        or manage cookies.
      </p>
      <br />
      <p>
        <strong>Existing cookies</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        To delete cookies that were previously placed in your browser, you must
        select the option to clear your browsing history, making sure that the
        option to delete or delete cookies is activated when this is done.
      </p>
      <br />
      <p>
        <u>Google Adsettings</u>
      </p>
      <p>Custom ads are managed by adssettings.google.com/ and by:</p>
      <p>
        uncheck the "Customize ads" option (that is, by ensuring that the switch
        at the top of the page is set to left / gray and not right / blue).
      </p>
      <p>
        Alternatively, you can install a free browser plugin here:
        support.google.com/ads/answer/7395996
      </p>
      <br />
      <p>
        <u>Browser extension for Google Analytics exclusion</u>
      </p>
      <p>
        You can opt out of Google Analytics by installing the browser extension
        available here: tools.google.com/dlpage/gaoptout
      </p>
      <br />
      <p>
        <strong>Web Beacons</strong>
      </p>
      <p>
        You may allow the use of Web Beacons in our marketing emails by checking
        the relevant box when subscribing to the newsletter.
      </p>
      <p>
        You may opt out of the use of Web Beacons in our marketing emails by
        accessing the unsubscribe url present in each email.
      </p>
      <br />
      <p>
        <strong>Facebook Pixel</strong>
      </p>
      <p>You can block Facebook Pixel using our cookie control tool.</p>
      <p>
        <u>Disconnect from Facebook</u>
      </p>
      <p>
        There is a possibility to install a browser extension called "Disconnect
        Facebook pixel and FB tracking". This will prevent Facebook from
        tracking your behavior on third-party websites.
      </p>
      <p>They can be installed by accessing:</p>
      <ul>
        <li>
          For Chrome:&nbsp;
          <u>
            <a href="https://www.facebook.com/business/help/282415328961648">
              https://www.facebook.com/business/help/282415328961648
            </a>
          </u>
        </li>
        <li>
          For Firefox:&nbsp;
          <u>
            <a href="https://addons.mozilla.org/en-GB/firefox/addon/facebook-disconnect/">
              addons.mozilla.org/en-GB/firefox/addon/facebook-disconnect/
            </a>
          </u>
        </li>
      </ul>
      <p>European Interactive Digital Advertising Tool</p>
      <p>
        You can opt out of Facebook and other companies in the Digital
        Advertising Alliance to display interest-based ads by visiting
        www.youronlinechoices.com, selecting your country, selecting "Your ad
        choices," then finding Facebook (and any other companies you want to
        block) and selecting the "Off" option.
      </p>
    </div>
  );
};

export default EngTermsConditions;
