import { NavLink } from 'react-router-dom';
import logo from '../../../assets/general/footer/logo-Adservio-footer.svg';
import fbIcon from '../../../assets/general/footer/fb_icon.svg';
import igIcon from '../../../assets/general/footer/insta_icon.svg';
import ytIcon from '../../../assets/general/footer/yt_icon.svg';
import xIcon from '../../../assets/general/footer/x_icon.svg';
import linkedInIcon from '../../../assets/general/footer/in_icon.svg';
import tiktokIcon from '../../../assets/general/footer/tt_icon.svg';

import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import useCheckHostname from '../../../utility/helper';
const TopSection = ({ flags }) => {
  const { t, i18n } = useTranslation('common');
  const isEducation = useCheckHostname('education');
  let currentLanguage = i18n.language;

  return (
    <section className="top">
      <section>
        <NavLink to="/">
          <img src={logo} alt={logo} />
        </NavLink>
        <LanguageSelector flags={flags} />
      </section>
      <section>
        <h3>{t('general.footer.top.section2.title')}</h3>
        <div className="top_footer_section">
          <ul>
            <li>
              <NavLink to={`/${currentLanguage}/p/schools/public`}>
                {t('general.footer.top.section2.list1.item1')}
              </NavLink>
            </li>

            <li>
              <NavLink to={`/${currentLanguage}/p/schools/private`}>
                {t('general.footer.top.section2.list1.item2')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${currentLanguage}/p/institutions/cityhall`}>
                {t('general.footer.top.section2.list1.item3')}
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink to={`/${currentLanguage}/p/students`}>
                {t('general.footer.top.section2.list2.item1')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${currentLanguage}/p/parents`}>
                {t('general.footer.top.section2.list2.item2')}
              </NavLink>
            </li>

            <li>
              <NavLink to={`/${currentLanguage}/p/csr`}>
                {t('general.footer.top.section2.list2.item3')}
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink to={`/${currentLanguage}/p/about`}>
                {t('general.footer.top.section2.list3.item1')}
              </NavLink>
            </li>
            <li>
              <a href="https://idei.adservio.ro/echipa/" target="_blank" rel="noreferrer">
                {t('general.footer.top.section2.list3.item2')}
              </a>
              <button
                onClick={() =>
                  window.open(
                    'https://idei.adservio.ro/cariere/',
                    '_blank' // <- This is what makes it open in a new window.
                  )
                }
              >
                {t('general.footer.top.section2.btn')}
              </button>
            </li>
            <li>
              <NavLink to={`/${currentLanguage}/p/contact`}>
                {t('general.footer.top.section2.list3.item3')}
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h3>{t('general.footer.top.section3.title')}</h3>
        <div className="top_footer_section">
          <ul>
            <li>
              <a
                href={
                  currentLanguage === 'ro'
                    ? 'https://manual.adservio.ro/web/'
                    : 'https://manual.adservio.ro/web/en'
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('general.footer.top.section3.list.item1')}
              </a>
            </li>
            <li>
              <a
                href="https://idei.adservio.ro/propuneri/?filter=top"
                target="_blank"
                rel="noreferrer"
              >
                {t('general.footer.top.section3.list.item2')}
              </a>
            </li>
            {!isEducation && (
              <li>
                <a href={`/${currentLanguage}/admission/offers`} rel="noreferrer">
                  {t('general.footer.top.section3.list.item3')}
                </a>
              </li>
            )}
          </ul>
        </div>
      </section>
      <section>
        <h3>{t('general.footer.top.section4.title')}</h3>
        <div className="top_footer_section">
          <ul>
            <li>
              <a href="https://idei.adservio.ro/" target="_blank" rel="noreferrer">
                {t('general.footer.top.section4.list.item1')}
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/groups/comunitateaadservio"
                target="_blank"
                rel="noreferrer"
              >
                {t('general.footer.top.section4.list.item2')}
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h3>{t('general.footer.top.section5.title')}</h3>
        <div className="top_footer_section">
          <ul>
            <li>
              <a href={`/${i18n.language}/auth`}>{t('general.footer.top.section5.p1')}</a>
            </li>
            <li>
              <a
                href="https://idei.adservio.ro/categorie/actualizari/"
                target="_blank"
                rel="noreferrer"
              >
                {t('general.footer.top.section5.p2.1')}
                <span>{t('general.footer.top.section5.p2.2')}</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h3>{t('general.footer.top.section6.title')}</h3>
        <div className="top_footer_section">
          <section className="social_media">
            <a href="https://www.facebook.com/adservio.ro" target="_blank" rel="noreferrer">
              <img src={fbIcon} alt="facebook logo" />
            </a>
            <a href="https://www.instagram.com/adservio_romania/" target="_blank" rel="noreferrer">
              <img src={igIcon} alt="instagram logo" />
            </a>
            <a href="https://www.youtube.com/adservio" target="_blank" rel="noreferrer">
              <img src={ytIcon} alt="youtube logo" />
            </a>
            <a href="https://twitter.com/AdservioRomania" target="_blank" rel="noreferrer">
              <img src={xIcon} alt="x logo" />
            </a>
            <a href="https://www.tiktok.com/@adservio_ro" target="_blank" rel="noreferrer">
              <img src={tiktokIcon} alt="tiktok icon" />
            </a>
            <a href="https://www.linkedin.com/company/adservio" target="_blank" rel="noreferrer">
              <img src={linkedInIcon} alt="linkedIn icon" />
            </a>
          </section>
        </div>

        <p>
          <a
            href="https://www.glassdoor.co.uk/Overview/Working-at-Adservio-Romania-EI_IE4639067.11,27.htm"
            target="_blank"
            rel="noreferrer"
          >
            {t('general.footer.top.section6.p')}
          </a>
        </p>
      </section>
    </section>
  );
};

export default TopSection;
