import { useTranslation } from "react-i18next";

const Educator = ({ images }) => {
  const { t } = useTranslation("common");
  return (
    <div className="benefits_blue_icons_container" id="educator">
      <h1>{t("schools.benefits.public.blue-icons.educator.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["feather-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["clock-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["puzzle-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["award-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["myclasses-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["sleep-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["history-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r3c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["library-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r3c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["cloud-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.educator.r3c3")}</p>
        </div>
      </div>
    </div>
  );
};

export default Educator;
