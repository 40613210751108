import CityhallIntro from "./CityhallIntro";
import CityhallBenefits from "./CityhallBenefits";
import CityhallDigitalization from "./CityhallDigitalization";
import MechanicalCounters from "../general/MechanicalCounters";
import Parteners from "../home/Parteners";
import { useTranslation } from "react-i18next";
const Cityhall = () => {
  const {  t } = useTranslation("common");
  return (
    <>
      <CityhallIntro />
      <CityhallBenefits />
      <MechanicalCounters />
      <CityhallDigitalization />
      <Parteners type={"cityhall"} title={t("institutions.cityhall.parteners.title")}/>
    </>
  );
};

export default Cityhall;
