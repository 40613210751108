import "./parents_communication.css";
import ParentsCommunicationBackground from "../../assets/parents/communication_hero.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
const ParentsCommunication = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <section className="parents_communication">
      <div className="parents_communication_background">
        <img
          src={ParentsCommunicationBackground}
          alt={ParentsCommunicationBackground}
          data-aos="fade-left"
        />
        <div className="parents_communication_content">
          <h1>{t("parents.communication.title")}</h1>
          <h3>{t("parents.communication.description")}</h3>
          <h2>{t("parents.communication.benefits_title")}</h2>
          <div className="parents_communication_wrapper">
            <div>
              <p>•</p>
              <p>{t("parents.communication.benefits.1")}</p>
            </div>
            <div>
              <p>•</p>
              <p>{t("parents.communication.benefits.2")}</p>
            </div>
            <div>
              <p>•</p>
              <p>{t("parents.communication.benefits.3")}</p>
            </div>
            <div>
              <p>•</p>
              <p>{t("parents.communication.benefits.4")}</p>
            </div>
            <div>
              <p>•</p>
              <p>{t("parents.communication.benefits.5")}</p>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};
export default ParentsCommunication;
