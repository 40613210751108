import { fullOnViewPort, getOffset, getWidth } from "./positionOnViewPort";

export default function horizoltalScroll(scrollContainer) {
  scrollContainer.addEventListener("wheel", (evt) => {
    if (evt.deltaY > 0) {
      let initialScrollValue = parseInt(scrollContainer.scrollLeft);
      let finalValue = initialScrollValue + parseInt(evt.deltaY);
      scrollContainer.scrollLeft += 2;

      if (initialScrollValue !== finalValue) {
        if (initialScrollValue !== parseInt(scrollContainer.scrollLeft)) {
          evt.preventDefault();
        }
      } else {
        if (initialScrollValue !== parseInt(scrollContainer.scrollLeft)) {
          evt.preventDefault();
        }
      }

      const scroll = setInterval(() => {
        if (initialScrollValue !== finalValue) {
          initialScrollValue += Math.min(5, finalValue - initialScrollValue);
          scrollContainer.scrollLeft = initialScrollValue;
        } else {
          clearInterval(scroll);
        }
      }, 0.005);
    } else {
      if (scrollContainer.scrollLeft !== 0) {
        evt.preventDefault();
        let initialScrollValue = parseInt(scrollContainer.scrollLeft);
        let finalValue = initialScrollValue + parseInt(evt.deltaY);
        scrollContainer.scrollLeft -= 1;
        if (initialScrollValue !== parseInt(scrollContainer.scrollLeft)) {
          evt.preventDefault();
        }

        const scroll = setInterval(() => {
          if (initialScrollValue !== finalValue) {
            initialScrollValue -= Math.min(5, initialScrollValue - finalValue);
            scrollContainer.scrollLeft = initialScrollValue;
          } else {
            clearInterval(scroll);
          }
        }, 0.005);
      }
    }
  });
}

export function horizoltalScrollWithContainer(
  scrollContainer,
  itemContainer,
  direction
) {
  window.addEventListener("wheel", (ev) => {
    if (fullOnViewPort(scrollContainer, 300)) {
      itemContainer.scrollLeft += (direction * ev.deltaY) / 5;
      itemContainer.scrollLeft += (direction * ev.deltaY) / 5;
      itemContainer.scrollLeft += (direction * ev.deltaY) / 5;
      itemContainer.scrollLeft += (direction * ev.deltaY) / 5;
    } else {
      maximScroll(document.getElementById("MentionsHorizontalScrollToLeft"), 1);
      maximScroll(
        document.getElementById("MentionsHorizontalScrollToRight"),
        -1
      );
    }
  });
}

export function scrollInCenter(container, el) {
  let currentPos = getOffset(el).left;
  let finalPos = window.innerWidth / 2;
  finalPos -= getWidth(el) / 2;
  let lastPos = currentPos - 1;

  if (currentPos > finalPos) {
    const moveLeft = setInterval(() => {
      if (currentPos > finalPos && lastPos !== currentPos) {
        lastPos = currentPos;
        container.scrollLeft += 2;
        currentPos = getOffset(el).left;
      } else {
        clearInterval(moveLeft);
      }
    }, 0.05);
  } else {
    const moveRight = setInterval(() => {
      if (currentPos < finalPos && lastPos !== currentPos) {
        lastPos = currentPos;
        container.scrollLeft -= 2;
        currentPos = getOffset(el).left;
      } else {
        clearInterval(moveRight);
      }
    }, 0.05);
  }
}
export function maximScroll(scrollContainer, direction) {
  let currentScroll = scrollContainer.scrollLeft;
  let lastScrollValue = scrollContainer.scrollLeft - 10;
  setInterval(() => {
    if (currentScroll !== lastScrollValue) {
      lastScrollValue = currentScroll;
      scrollContainer.scrollLeft += 100 * direction;
      currentScroll = scrollContainer.scrollLeft;
    } else {
      clearInterval(this);
    }
  }, 0.1);
}

const leftMaxScroll = (container, direction) => {
  let currentValue = container.scrollLeft;
  let lastValue = currentValue - 1;
  const scrollToLeft = setInterval(() => {
    if (currentValue !== lastValue) {
      lastValue = currentValue;
      container.scrollLeft += direction * -1;
      currentValue = container.scrollLeft;
    } else {
      autoScroll(container, direction);
      clearInterval(scrollToLeft);
    }
  }, 30);
};

export function autoScroll(container, direction) {
  let currentValue = container.scrollLeft;
  let lastValue = currentValue - 1;

  const scrollToRight = setInterval(() => {
    if (currentValue !== lastValue) {
      lastValue = currentValue;
      container.scrollLeft += direction * 1;
      currentValue = container.scrollLeft;
    } else {
      leftMaxScroll(container, direction);
      clearInterval(scrollToRight);
    }
  }, 30);
  lastValue = currentValue + 5;
}
