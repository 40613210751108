import React from 'react'

import IS from "../../../assets/about/projectParteners/IS.png"
import Regio from "../../../assets/about/projectParteners/Regio.png"
import Rom_gov from "../../../assets/about/projectParteners/Rom_Gov.png"
import UE from "../../../assets/about/projectParteners/UE.png"


import "./Projects.css"

import { useTranslation } from 'react-i18next'

import file from "../../../assets/about/files/Informatii proiect Adservio.pdf"



export const Projects = () => {
  const { t } = useTranslation("common");
  return (
    <div className="projectsContainer">
      <h1 className="title">{t('about.projects.title')}</h1>
      <div className="content">
          <p className="description">
          {t('about.projects.description')}
          </p>
          <a className="link" href={file}  download>
          {t('about.projects.download')}
          </a>
          <p className="info">{t('about.projects.info1')}<a className='link' href="https://www.fonduri-ue.ro." target='_blank' rel="noreferrer">www.fonduri-ue.ro.</a>{t('about.projects.info2')}<a className='link' href='https://www.inforegio.ro' target='_blank' rel="noreferrer">www.inforegio.ro</a>{t('about.projects.info3')} <a className='link' href="https://facebook.com/inforegio.ro" target='_blank' rel="noreferrer">facebook.com/inforegio.ro</a></p>
      </div>
      <div className="parteners">
      {[UE , Rom_gov, Regio ,IS].map((img, index) => <img key={index} src={img} alt=""/> )}
      </div>
    </div>
    
  );
}




