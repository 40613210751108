/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { Component } from "react";

import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import LaddaButton, { EXPAND_RIGHT, CONTRACT } from "react-ladda";

import "./Button.css";
/* TODO - Remove it or change c
  The import does not seem to change anything
 */
import "../../../../node_modules/ladda/dist/ladda-themeless.min.css";

class Button extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { onClick, goBack, type, haslink, link, history } = this.props;

    if ((type !== "submit" && !haslink) || link) {
      e.preventDefault();
    }
    if (onClick) {
      onClick(e);
    } else if (goBack) {
      history.goBack();
    }
  }

  // https://www.npmjs.com/package/react-ladda
  render() {
    const {
      disabled,
      color,
      size,
      loading,
      goBack,
      progress,
      ladda,
      className,
      type,
      children,
      contract,
      link,
    } = this.props;

    return (
      <LaddaButton
        loading={loading}
        onClick={this.onClick}
        data-style={contract ? CONTRACT : EXPAND_RIGHT}
        progress={progress}
        className={`btn-component
                        ${ladda ? "ladda-button" : ""}
                        ${goBack ? "goBack" : ""}
                        ${color}
                        btn-${size}
                        ${disabled ? "disabled" : ""}
                        ${className || ""}`}
        type={type || ""}
      >
        {link && (
          <Link to={link}>
            <span className="btn_hover" />
            <span className={`btn_text ${ladda ? "ladda-label" : ""} `}>
              {goBack ? <span className="_icon icon-download" /> : children}
            </span>
          </Link>
        )}

        {!link && (
          <>
            <span className="btn_hover" />

            <span className={`btn_text ${ladda ? "ladda-label" : ""} `}>
              {goBack ? <span className="_icon icon-download" /> : children}
            </span>
          </>
        )}
      </LaddaButton>
    );
  }
}

Button.propTypes = {
  /* true - start Ladda
        false - stop Ladda */
  loading: PropTypes.bool,
  /* seteaza ca butonul sa fie de tip Ladda */
  ladda: PropTypes.bool,
  /* specifica o clasa externa */
  className: PropTypes.string,
  /* specifica tipul butonului */
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  /* contine o componenta/nod care este pusa in interiorul componentei */
  children: PropTypes.node,
  /* 0.0 - progess 0
        1.0 - progress full */
  progress: PropTypes.number,
  /* face back - history.goBack
        adauga clasa de goBack - pentru stilizare
        nu trebuie utilizata proprietatea - onClick */
  goBack: PropTypes.bool,
  /* apelarea fct de onCLick a butonului. Pe butonul de back nu trebuie onClick, pt ca se face goBack */
  onClick: PropTypes.func,
  /* oadauga clasa disabled pe buton */
  disabled: PropTypes.bool,
  /* case where link is needed */
  link: PropTypes.string,
  /* orange - bg: orange, color text: white
        blue - bg: blue, color text: white
        ghost-orange - bg: white, color text : orange
        ghost-blue - bg: white, color text : blue
        white - bg: transparent, color text : blue
        black - bg : #333, color text : white */
  color: PropTypes.oneOf([
    "orange",
    "blue",
    "ghost-orange",
    "ghost-blue",
    "ghost-white",
    "ghost-black",
    "white",
    "blue-link",
    "black",
    "green",
    "red",
    "ghost-red",
  ]),
  /*  lg - height btn: 40px
         sm - height btn: 30px */
  size: PropTypes.oneOf(["lg", "sm"]),
  /* the loaderstyle */
  contract: PropTypes.bool,
  /* use with <a> tags ass children */
  haslink: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
};

Button.defaultProps = {
  loading: false,
  goBack: false,
  disabled: false,
  ladda: false,
  type: "button",
  size: "lg",
  color: "orange",
  contract: false,
  link: "",
  haslink: false,
  className: "",
  onClick: () => {},
  children: null,
  progress: null,
};

const ButtonWithHistory = () => {
  return ({ children, ...props }) => {
    const history = useNavigate();

    return (
      <Button history={history} {...props}>
        {children}
      </Button>
    );
  };
};

export default ButtonWithHistory();
