import "./parents_benefits.css";
import ParentsBenefitsBackground from "../../assets/parents/grades_hero.svg";
import Button from "../general/Button";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";
const ParentsBenefits = () => {
  const { i18n, t } = useTranslation("common");

  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <section className="parents_benefits">
      <div className="parents_benefits_content">
        <div className="parents_benefits_upper_text">
          <h1>{t("parents.benefits.title")}</h1>
          <h3>{t("parents.benefits.description")}</h3>
        </div>
        <h2>{t("parents.benefits.benefits_title")}</h2>
        <div className="parents_benefits_wrapper">
          <div>
            <p>•</p>
            <p>{t("parents.benefits.benefits.1")}</p>
          </div>
          <div>
            <p>•</p>
            <p>{t("parents.benefits.benefits.2")}</p>
          </div>
          <div>
            <p>•</p>
            <p>{t("parents.benefits.benefits.4")}</p>
          </div>
          <div>
            <p>•</p>
            <p>{t("parents.benefits.benefits.6")}</p>
          </div>
          <div>
            <p>•</p>
            <p>{t("parents.benefits.benefits.7")}</p>
          </div>
        </div>
        <Button
          text={t("parents.benefits.button")}
          type={"primary"}
          linkType={"intern"}
          link={`/${i18n.language}/p/students`}
        />
      </div>
      <img
        src={ParentsBenefitsBackground}
        alt={ParentsBenefitsBackground}
        data-aos="fade-right"
      />
    </section>
  );
};
export default ParentsBenefits;
