import { useEffect, useState } from "react";
import {Header} from "../general/header";
import BecomePartener from "./BecomePartener";
import Footer from "../general/footer/Footer";
import ValuesBanner from "./ValuesBanner";
import AboutIntro from "./AboutIntro";
import Team from "./Team";
import TimeLine from "./TimeLine";
import Parteners from "../home/Parteners";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { addAnim } from "../../utility/animation";
import Api2 from "../../Libs/api";
import { AdservioMicrosoft } from "./AdservioAndMicrosoft";
import { Projects } from "./Projects"
import EafCard from "./EafCard";
export const About = () => {
  let { lng } = useParams();
  const { i18n, t } = useTranslation("common");
  const [flags, setFlags] = useState();

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });
  useEffect(() => {
    if (window.innerWidth >= 1200) addAnim();

    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);
  return (
    <>
      <Helmet>
        <title>{t("general.keysForMeta.about.title")}</title>
        <meta
          name="description"
          content={t("general.keysForMeta.about.description")}
        />
        <meta
          name="keywords"
          content={t("general.keysForMeta.about.keywords")}
        />

        <meta
          property="og:title"
          content={t("general.keysForMeta.about.title")}
        />
        <meta
          property="og:description"
          content={t("general.keysForMeta.about.description")}
        />
      </Helmet>
      <Header flags={flags} activeEl={"about"} subMenuEl={""} />
      <main style={{ overflowX: "hidden", position: "relative" }}>
        <AboutIntro />
        <ValuesBanner />
        <Team />
        <TimeLine />
        <AdservioMicrosoft />
        <EafCard />
        <Parteners
          type={"about"}
          title={t("institutions.ministry.parteners.title")}
        />
        {i18n.language !== "ro" && <BecomePartener />}
        {i18n.language === "ro" && <Projects/>}
       
      </main>
      <Footer flags={flags} />
    </>
  );
};

export default About;
