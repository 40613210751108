import bannerImg from "../../assets/csr/bannerImg.svg";
import "./digitalEducation.css";
import AOS from "aos";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const DigitalEducation = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <div id="digitalEducation">
      <div className="banner">
        <h2>{t("csr.digital_education.title")}</h2>
        <img src={bannerImg} alt={bannerImg} data-aos="flip-up" />
      </div>
      <div className="textContent">
        <div className="col">
          <p>{t("csr.digital_education.p2")}</p>
        </div>
        <div className="col">
          <p>{t("csr.digital_education.p4")}</p>
        </div>
      </div>
    </div>
  );
};

export default DigitalEducation;
