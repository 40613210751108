import { useState } from "react";

const DigitalizationVideoContainer = ({
  url,
  description,
  name,
  place,
  img,
  setVideoUrl,
}) => {
  return (
    <div className="video_w_description">
      <VideoAnimation img={img} handleClick={() => setVideoUrl(url)} url={url} />

      <p>{description}</p>
      <h3>
        <span>{name}</span>
        <span>&nbsp;-&nbsp;</span>
        <br />
        <span>{place}</span>
      </h3>
    </div>
  );
};

export default DigitalizationVideoContainer;


export const VideoAnimation = ({ img, url, handleClick }) => {
  const [hovered, setHovered] = useState();
  return (
    <div className="video">
      <img src={img} alt={img} className={hovered ? "active" : ""} />
      <div className="videoAnimation" onClick={() => handleClick(url)}>
        <div
          className="overflowContainer"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div className="playBtn"></div>
          <div className="wave1"></div>
          <div className="wave2"></div>
          <div className="wave3"></div>
        </div>
      </div>
    </div>
  )
}