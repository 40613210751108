import adservio from "../../assets/csr/adservioLogo.svg";
import brd from "../../assets/csr/bcr.svg";
import "./adservioAndBrd.css";
import { useTranslation } from "react-i18next";
const AdservioAndBrd = () => {
  const { t } = useTranslation("common");
  return (
    <div id="adservio-brd">
      <div className="titleContainer">
        <h1>{t("csr.Ads_and_Brd.title")}</h1>
      </div>
      <div className="content">
        <div className="logos">
          <img src={adservio} alt={adservio} />
          <span>+</span>
          <img src={brd} alt={brd} />
        </div>
        <div className="textContainer">
          <p>{t("csr.Ads_and_Brd.p3")}</p>
          <p>{t("csr.Ads_and_Brd.p4")}</p>
        </div>
      </div>
    </div>
  );
};

export default AdservioAndBrd;
