import introImg from "../..//assets/csr/introImg.svg";
import "./intro.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
const IntroCsr = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <div id="csrIntro">
      <img src={introImg} alt={introImg} data-aos="flip-left" />
      <div style={{ zIndex: "10" }}>
        <h1>
          {t("csr.intro.title.t1")}
          <br />
          {t("csr.intro.title.t2")}
        </h1>
      </div>
    </div>
  );
};

export default IntroCsr;
