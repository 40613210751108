import { Header } from '../general/header';
import Footer from '../general/footer/Footer';
import ParentsIntro from './ParentsIntro.js';
import ParentsBenefits from './ParentsBenefits.js';
import ParentsCommunication from './ParentsCommunication.js';
import ParentsEducation from './ParentsEducation.js';
import ParentsFunctionalities from './ParentsFunctionalities.js';
import ParentsRecommend from './ParentsRecommend.js';
import AdservioMobile from '../students/AdservioMobile';
import Feedback from '../parents/Feedback';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Support from '../schools/Support';
import Parteners from '../home/Parteners';
// import Digitalization from "../schools/Digitalization";
import styled from 'styled-components';
import ShareModal from '../general/ShareModal';
import VideoModal from '../general/VideoModal';
import Api2 from '../../../src/Libs/api';

import './parents_extra_styling.css';
import AdmissionSection from '../general/AdmissionSection/AdmissionSection.js';
const Parents = () => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [flags, setFlags] = useState();

  let { lng } = useParams();
  const { i18n, t } = useTranslation('common');

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  return (
    <section id="parentsID">
      <Helmet>
        <title>{t('general.keysForMeta.parents.title')}</title>
        <meta name="description" content={t('general.keysForMeta.parents.description')} />
        <meta name="keywords" content={t('general.keysForMeta.parents.keywords')} />
        <meta property="og:title" content={t('general.keysForMeta.parents.title')} />
        <meta property="og:description" content={t('general.keysForMeta.parents.description')} />
      </Helmet>
      <ShareModal /> {videoUrl && <VideoModal videoUrl={videoUrl} setVideoUrl={setVideoUrl} />}{' '}
      <Header flags={flags} activeEl={'parents'} />{' '}
      <main>
        <ParentsIntro />
        <ParentsEducation />
        <ParentsCommunication />
        <ParentsBenefits />
        <AdmissionSection type="parent" />
        <ParentsFunctionalities />
        {/* <Digitalization
          text={t("parents.digitalization.title")}
          setVideoUrl={setVideoUrl}
        /> */}
        <WhiteSpace></WhiteSpace>
        <AdservioMobile />
        <Parteners type={'parents'} title={t('parents.parteners.title')} /> <ParentsRecommend />
        <Support />
        <Feedback />
      </main>{' '}
      <Footer flags={flags} />
    </section>
  );
};

export default Parents;
const WhiteSpace = styled.div`
  height: 100px;
  width: 100vw;
  margin-bottom: 50px;
`;
