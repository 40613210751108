import { useTranslation } from "react-i18next";

const SchoolMaster = ({ images }) => {
  const { t } = useTranslation("common");
  return (
    <div className="benefits_blue_icons_container" id="schoolmaster">
      <h1>{t("schools.benefits.public.blue-icons.schoolmaster.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["zap-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.top_r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["myclasses-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.top_r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["calificativ-icon.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.top_r1c3")}</p>
        </div>
      </div>

      <h1>{t("schools.benefits.public.blue-icons.schoolmaster.motivation")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["comment-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["bell-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["homework-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["history-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r1c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["usercheck-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["video-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["barchart2-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["users-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r2c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["share-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r3c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["trendingup-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r3c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["piechart-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r3c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["library-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.schoolmaster.r3c4")}</p>
        </div>
      </div>
    </div>
  );
};

export default SchoolMaster;


