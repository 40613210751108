import "./feedback.css";
import appStore from "../../assets/feedback/appstore.svg";
import glassdoor from "../../assets/feedback/glassdoor.svg";
import google from "../../assets/feedback/google.svg";
import playStore from "../../assets/feedback/play.svg";
import youtube from "../../assets/feedback/youtube.svg";
import { useTranslation } from "react-i18next";

const Feedback = () => {
  const { t } = useTranslation("common");
  return (
    <section className="feedback">
      <h1>{t("parents.feedback.title")}</h1>
      <div className="feedback_wrapper">
        <div className="feedback_wrapper_item">
          <div className="feedback_wrapper_item_logo">
            <a
              href="https://g.page/r/CUQUMK-NJdEMEAg/review"
              target="_blank"
              rel="noreferrer"
            >
              <img src={google} alt={google} />
            </a>
          </div>
          <h2>{t("parents.feedback.google")}</h2>
        </div>
        <div className="feedback_wrapper_item">
          <div className="feedback_wrapper_item_logo">
            <a
              href="https://play.google.com/store/apps/details?id=ro.adservio.app&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <img src={playStore} alt={playStore} />
            </a>
          </div>
          <h2>{t("parents.feedback.playstore")}</h2>
        </div>
        <div className="feedback_wrapper_item">
          <div className="feedback_wrapper_item_logo">
            <a
              href="https://www.youtube.com/adservio"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt={youtube} />
            </a>
          </div>
          <h2>{t("parents.feedback.youtube")}</h2>
        </div>
        <div className="feedback_wrapper_item">
          <div className="feedback_wrapper_item_logo">
            <a
              href="https://apps.apple.com/ro/app/adservio/id1401366069"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appStore} alt={appStore} />
            </a>
          </div>
          <h2>{t("parents.feedback.appstore")}</h2>
        </div>
        <div className="feedback_wrapper_item">
          <div className="feedback_wrapper_item_logo">
            <a
              href="https://www.glassdoor.co.uk/mz-survey/employer/collectReview_input.htm?
i=4639067&j=true&y=&c=PAGE_INFOSITE_TOP"
              target="_blank"
              rel="noreferrer"
            >
              <img src={glassdoor} alt={glassdoor} />
            </a>
          </div>
          <h2>{t("parents.feedback.glassdoor")}</h2>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
