import pictureFrame from "../../assets/home/recommendations/picture-frame-bg.svg";
import playVideoHover from "../../assets/home/recommendations/playvideo-hover.svg";
import textBubble from "../../assets/home/recommendations/text-bubble.svg";
import textBubbleMobile from "../../assets/home/recommendations/text-bubble-mobile.svg";

import { isMobile } from "react-device-detect";

const RecommendationsBubble = ({
  text,
  author,
  role,
  video,
  profilePhoto,
  setVideoUrl,
  action,
}) => {
  return (
    <>
      <div className={`BUBBLE ${isMobile ? "active" : ""}`} onClick={action}>
        <div className="text_card">
          <div className="img_hover" onClick={() => setVideoUrl(video)}>
            <img src={profilePhoto} alt={profilePhoto} />
            <img src={pictureFrame} alt={pictureFrame} />
            {video && <img src={playVideoHover} alt={playVideoHover} />}
          </div>
          <div className="text_bubble">
            <img src={textBubble} alt={textBubble} />
            <img src={textBubbleMobile} alt={textBubbleMobile} />
            <p>{text}</p>
            <p>
              <span>{author}</span> - {role}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendationsBubble;
