import MinistryIntro from "./MinistryIntro";
import MinistryDigitalization from "./MinistryDigitalization";
import MechanicalCounters from "../general/MechanicalCounters";
import MinistryElectronic from "./MinistryElectronic";
import Parteners from "../home/Parteners";
import { useTranslation } from "react-i18next";
const Ministry = () => {
  const {  t } = useTranslation("common");
  return (
    <>
      <MinistryIntro />
      <MinistryDigitalization />
      <MechanicalCounters />
      <MinistryElectronic />
      <Parteners type={"cityhall"}  title={t("institutions.ministry.parteners.title")}/>
    </>
  );
};

export default Ministry;
