import "./parents_intro.css";
import Button from "../general/Button";
import ParentsIntroBackground from "../../assets/parents/parents_hero.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
const ParentsIntro = () => {
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);

  const { i18n, t } = useTranslation("common");
  const navigate = useNavigate();

  const openModal = async () => {
    const shareData = {
      title: "adservio",
      text: "adservio text",
      url: "https://adservio.ro",
    };

    if (isMobile) {
      navigator
        .share(shareData)
        .then(() => console.log("Share was successful."))
        .catch((error) => {
          console.log("Sharing failed", error);
        });
    } else {
      console.log(`Your system doesn't support sharing files.`);
      document.getElementById("shareModal").style.display = "block";
    }
  };
  return (
    <section className="parents_intro">
      <img
        data-aos="fade-left"
        src={ParentsIntroBackground}
        alt={ParentsIntroBackground}
      />
      <h1>{t("parents.intro.title")}</h1>
      <div className="parents_intro_content">
        <div className="parents_intro_content_text">
          <div>
            <p>{t("parents.intro.content.mark")}</p>
            <p>{t("parents.intro.content.p1")}</p>
          </div>
          <div>
            <p>{t("parents.intro.content.mark")}</p>
            <p>{t("parents.intro.content.p2")}</p>
          </div>
          <div>
            <p>{t("parents.intro.content.mark")}</p>
            <p>{t("parents.intro.content.p3")}</p>
          </div>
          <div>
            <p>{t("parents.intro.content.mark")}</p>
            <p>{t("parents.intro.content.p4")}</p>
          </div>
          <div>
            <p>{t("parents.intro.content.mark")}</p>
            <p>{t("parents.intro.content.p5")}</p>
          </div>
        </div>
        <div className="parents_intro_buttons">
          <Button
            text={t("parents.intro.button1")}
            type={"primary"}
            action={openModal}
          />
          <Button
            text={t("parents.intro.button2")}
            type={"ghost"}
            linkType={"location"}
            link={`/${i18n.language}/auth`}
          />
        </div>
        <p
          className="parents_intro_packages-link"
          onClick={() =>
            navigate(`/${i18n.language}/p/subscriptions?user=parent`)
          }
        >
          {t("parents.intro.packages")}
        </p>
      </div>
    </section>
  );
};
export default ParentsIntro;
