import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {Header} from "../general/header";
import Footer from "../general/footer/Footer";
import Ministry from "./Ministry";
import Cityhall from "./Cityhall";
import Api2 from "../../../src/Libs/api";

const Institution = () => {
  let { type, lng } = useParams();
  const { i18n, t } = useTranslation("common");
  const [flags, setFlags] = useState();

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t(`general.keysForMeta.institution-${type}.title`)}</title>
        <meta
          name="description"
          content={t(`general.keysForMeta.institution-${type}.description`)}
        />
        <meta
          name="keywords"
          content={t(`general.keysForMeta.institution-${type}.keywords`)}
        />
        <meta
          property="og:title"
          content={t(`general.keysForMeta.institution-${type}.title`)}
        />
        <meta
          property="og:description"
          content={t(`general.keysForMeta.institution-${type}.description`)}
        />
      </Helmet>
      <Header flags={flags} activeEl={"institutions"} subMenuEl={type} />
      <main>
        {type === "ministry" && <Ministry />}
        {type === "cityhall" && <Cityhall />}
      </main>
      <Footer flags={flags} />
    </>
  );
};

export default Institution;
