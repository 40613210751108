import SecondImageContainer from './SecondImageContainer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const TimeLine = () => {
  const { t } = useTranslation('common');
  return (
    <Container className="timeline">
      <section className="timelineImages" id="imgShapes2">
        <SecondImageContainer />
      </section>
      <section className="timelineContent">
        <h1>{t('about.timeLine.title')}</h1>
        <div>
          <div className="year">2008</div>
          <p>{t('about.timeLine.2008')}</p>
        </div>
        <div>
          <div className="year">2009</div>
          <p>{t('about.timeLine.2009')}</p>
        </div>
        <div>
          <div className="year">2013</div>

          <p>{t('about.timeLine.2013')}</p>
        </div>
        <div>
          <div className="year">2017</div>
          <p>{t('about.timeLine.2017')}</p>
        </div>
        <div>
          <div className="year">2018</div>
          <p>{t('about.timeLine.2018')}</p>
        </div>
        <div>
          <div className="year">2020</div>
          <p>{t('about.timeLine.2020')}</p>
        </div>
        <div>
          <div className="year">2021</div>
          <p>{t('about.timeLine.2021')}</p>
        </div>
        <div>
          <div className="year">2022</div>
          <p>{t('about.timeLine.2022')}</p>
        </div>
        <div>
          <div className="year">2023</div>
          <p>{t('about.timeLine.2023')}</p>
        </div>
      </section>
    </Container>
  );
};

export default TimeLine;

const Container = styled.section`
  background-color: var(--dark-slate-blue);
  width: 1281px;
  height: fit-content;
  margin-left: 487.5px;
  border-radius: 12px;
  display: flex;
  position: relative;

  & .timelineImages {
    position: absolute;
    width: 809.1px !important;
    top: -200px;
    left: -487.5px;
    & svg {
      width: 100%;
    }
  }
  & .timelineContent {
    height: fit-content;
    margin-left: 440px;
    padding-bottom: 30px;
    & > div {
      margin-bottom: 23px;
      display: flex;
      height: fit-content;
      box-sizing: border-box;
      & .year {
        opacity: 0.14;
        font-family: 'Manrope';
        font-size: 34px;
        font-weight: bold;
        text-align: left;
        color: var(--tertiary-ultralight);
        min-width: 85px !important;
        max-width: 85px !important;
        box-sizing: border-box;
      }
      & p {
        font-family: 'Manrope';
        font-size: 16px;
        margin-left: 20px;
        margin-right: 124.5px;
        line-height: 1.2;
        text-align: left;
        color: var(--tertiary-ultralight);
        height: fit-content;
      }
    }
  }
  & h1 {
    margin-top: 47px;
    margin-bottom: 40.5px;
    font-family: 'Manrope';
    font-size: 50px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: var(--tertiary-ultralight);
  }
  @media only screen and (max-width: 1800px) {
    width: 1200px;
    margin-left: 350px;

    & .timelineImages {
      width: 600px !important;
      top: -250px;
      left: -350px;
    }
    & .timelineContent {
      margin-left: 367px;
    }
  }
  @media only screen and (max-width: 1600px) {
    width: 1000px;

    & .timelineContent > div > p {
      margin-right: 65px;
    }
    & .timelineContent {
      margin-left: 300px;
    }
  }
  @media only screen and (max-width: 1350px) {
    margin: 0 auto;

    & .timelineImages {
      width: 375px !important;
      top: -325px;
      left: 0;
      transform: translate(-62%, 0);
    }
    & .timelineContent {
      margin-left: 200px;
    }
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: column !important;
    height: auto;
    margin-left: 0;

    & .timelineImages {
      position: absolute;
      width: 100% !important;
      max-width: 375px;
      top: 0;
      left: 0;
      transform: translate(0, -45.5%);
      height: max-content;
      & svg {
        width: 100%;
      }
    }

    & .timelineContent {
      margin: 10px 50px 0 50px;
    }
    & h1 {
      text-align: right;
      margin-bottom: 200px;
    }

    & .timelineContent > div > p {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 810px) {
    margin-bottom: 35px;
    & h1 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 1.23;
    }
    & .timelineContent {
      margin-top: 200px;
      margin-left: 0;
      margin-right: 0;
    }
    & .timelineContent > div {
      display: flex;
      flex-direction: column !important;
      margin-left: 22px;
      margin-right: 36px;
    }
    & .timelineContent > div > div {
      margin-bottom: 9.5px !important;
      height: fit-content !important;
      min-height: 10px !important;
    }
    & .timelineContent > div > p {
      margin-left: 0;
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;
