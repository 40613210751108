import Button from "../general/Button";
import styled from "styled-components";
import FirstImageContainer from "./FirstImageContainer";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation("common");
  return (
    <Container className="team">
      <div className="imgShapes" id="imgShapes1">
        <FirstImageContainer />
      </div>
      <div className="textContainer">
        <h1>{t("about.team.title")}</h1>
        <p>{t("about.team.p")}</p>
        <div>
          <Button
            text={t("about.team.btn1")}
            type={"primary-invert"}
            link={"https://idei.adservio.ro/echipa/"}
            linkType="extern"
          />
          <Button
            text={t("about.team.btn2")}
            type={"primary"}
            link={"https://idei.adservio.ro/cariere/"}
            linkType="extern"
          />
        </div>
      </div>
    </Container>
  );
};

export default Team;

const Container = styled.section`
  height: fit-content;
  margin: 148px auto 326.5px auto;
  width: 100%;
  max-width: 1540px;
  display: flex;

  & .textContainer {
    max-width: 750px;
    & h1 {
      font-family: "Manrope";
      font-size: 50px;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
      color: var(--dark-slate-blue);
      margin-bottom: 29.6px;
    }
    & p {
      font-family: "Manrope";
      font-size: 20px;
      line-height: 1.6;
      text-align: left;
      color: var(--tertiary-heavy);
      margin-bottom: 49.2px;
    }
    & div button {
      padding-left: 29.5px;
      padding-right: 29.5px;
    }
    & div button:nth-of-type(1) {
      margin-right: 32px;
    }
  }
  & .imgShapes {
    position: absolute;
    width: 758.4px;
    right: 0;
    margin-top: -102.9px;
    & svg {
      width: 100% !important;
      height: auto;
    }
  }

  @media only screen and (max-width: 1600px) {
    & .textContainer {
      margin-left: 50px;
      max-width: 650px;
      & .imgShapes {
        width: 650px;
      }
    }
  }
  @media only screen and (max-width: 1450px) {
    & .textContainer {
      & h1 {
        font-size: 35px;
      }
      & p {
        font-size: 18px;
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    margin: 148px auto 158px auto;
    & .textContainer {
      max-width: 550px;
    }
    & .imgShapes {
      width: 500px;
    }
  }

  @media only screen and (max-width: 1120px) {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .textContainer {
      max-width: 86%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 0px;
      & h1 {
        margin-top: 32px;
        text-align: center;
      }
      & p {
        text-align: center;
      }
      & div button:nth-of-type(1) {
        margin-right: 16px;
      }
    }
    & .imgShapes {
      position: relative;
      width: 100%;
      max-width: 500px;
      margin: 0 0 0 0 !important;
    }
  }
  @media only screen and (max-width: 700px) {
    & .textContainer {
      max-width: 90%;
      & h1 {
        font-size: 26px;
        margin-bottom: 16px;
      }
      & p {
        font-size: 16px;
        margin-bottom: 32px;
      }
    }
  }
`;
