/* eslint-disable no-useless-escape */
import desktopBubbles from "../../assets/home/newsletter/desktop-bubbles.svg";
import mobileBubble from "../../assets/home/newsletter/mobile-bubble.svg";
import Api2 from "../../Libs/api";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./newsletter.css";

const Newsletter = () => {
  const { t, i18n } = useTranslation("common");
  const [userEmail, setUserEmail] = useState("");
  const [successSubscriber, setSuccessSubscriber] = useState(false);
  const [errors, setErrors] = useState([]);

  const hasError = (formErrors, fieldName, errorCode) => {
    if (!formErrors.length) return false;
    const error = formErrors.filter(
      (fieldErr) =>
        fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode
    );
    return error.length ? error[0] : false;
  };

  const handleError = (err) => {
    if (err && err.response && typeof err.response.data !== "string") {
      const apiErrors = err.response.data;
      setErrors(apiErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    Api2.post(`/generic/newsletter`, {
      email: userEmail,
      campaign: i18n.language === "ro" ? "ro" : "int",
    })
      .then((resp) => {
        setSuccessSubscriber(true);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const getInputValue = (event) => {
    setUserEmail(event.target.value);
  };

  return (
    <section className="newsletter">
      <div className="newsletter_container">
        {!successSubscriber && (
          <>
            <h2>{t("home.newsLetter.title")}</h2>

            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                type="text"
                placeholder={t("home.newsLetter.placeholder")}
                inputMode="email"
                name="email"
                className="newsletter_container_email"
                onChange={getInputValue}
              />
              {hasError(errors, "email", "101") && (
                <p className="form__input-error">
                  {t("home.newsLetter.error.email_field")}
                </p>
              )}
              {hasError(errors, "email", "1") && (
                <p className="form__input-error">
                  {t("home.newsLetter.error.empty_field")}
                </p>
              )}
              {hasError(errors, "email", "3") && (
                <p className="form__input-error">
                  {t("home.newsLetter.error.valid_email_address")}
                </p>
              )}
              <br />
              <input type="hidden" name="campaign_token" />
              <input type="hidden" name="start_day" value="0" />

              <input
                type="submit"
                value={t("home.newsLetter.btn")}
                className="newsletter_container_submit"
              />
            </form>
          </>
        )}
        {successSubscriber && (
          <p className="form__input-success">
            {t("home.newsLetter.success-subscriber")}
          </p>
        )}
      </div>
      <img src={desktopBubbles} alt={desktopBubbles} />
      <img src={mobileBubble} alt={mobileBubble} />
    </section>
  );
};

export default Newsletter;
