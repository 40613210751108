import React from "react";

import Select from "react-select";

import customStyles from "./constants";
import "./select.css";

const SelectComp = (props) => {
  const {
    value,
    onChangeOptions,
    options,
    className,
    isSearchable,
    name,
    children,
    onInputValueChange,
    noOptionsMessage,
    noClientFilter,
    placeholder,
    ...otherProps
  } = props;

  const onChange = (filter) => {
    onChangeOptions(filter, filter.id, filter.filterName);
  };

  const optionsArr =
    children && children.length
      ? children
          .filter((item) => !!item.props)
          .map((item, index) => ({
            value: item.props.children,
            label: item.props.children,
            id: index,
            filterName: name,
            item: item.props,
          }))
      : options;

  return (
    <Select
      menuPlacement="auto"
      className={`comp-select ${className || ""}`}
      isSearchable={isSearchable}
      name={`${name}`}
      onChange={onChange}
      onInputChange={(data, action) => {
        if (onInputValueChange && typeof onInputValueChange === "function") {
          onInputValueChange(data, action);
        }
      }}
      options={optionsArr}
      styles={customStyles}
      value={!value?.label || !value?.value ? null : value}
      noOptionsMessage={noOptionsMessage}
      filterOption={noClientFilter ? () => true : undefined}
      placeholder={placeholder}
      {...otherProps}
    />
  );
};

export default SelectComp;
