import schoolIcon from '../../assets/registerFlow/schoolIcon.svg';
import './registerSucceeded.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ShareModal from '../general/ShareModal';
import Button from '../general/Button';
import AOS from 'aos';
import Api2 from '../../Libs/api';
import { Header } from '../general/header';
import Footer from '../general/footer/Footer';
import Loader from '../overlays/Modal/Loader';
import { useCallback, useEffect, useState } from 'react';

const RegisterSucceeded = () => {
  const { t, i18n } = useTranslation('common');
  let { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [flags, setFlags] = useState();
  const [schoolWasCreated, setSchoolCreated] = useState(false);
  const [subscriptionError, setSubscriptionsError] = useState(false);

  let { lng } = useParams();
  const changeLanguage = useCallback(
    (to) => {
      i18n.changeLanguage(to).then(() => {
        i18n.language = to;
      });
    },
    [i18n]
  );

  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  }, [lng, i18n, changeLanguage]);

  useEffect(() => {
    AOS.init({ mirror: 'false', once: 'true', duration: 1000 });

    if (token && i18n.language) {
      Api2.post('./scoli/enrollment_confirm', { token, _lang: i18n.language })
        .then(() => {
          setSuccess(true);
          setLoading(false);
          setSchoolCreated(false);
        })
        .catch(() => {
          setSuccess(false);
          setLoading(false);
          setSchoolCreated(false);
        });
    }
  }, [token, lng, i18n]);

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  useEffect(() => {
    if (!schoolWasCreated) {
      const interval = setInterval(() => {
        Api2.get(`scoli/draft/${token}`)
          .then((response) => {
            if (response.status === 'created') {
              setSchoolCreated(true);
            }
          })
          .catch((err) => {
            setSubscriptionsError(true);
          });
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [token, i18n.language, schoolWasCreated]);

  return (
    <>
      <Helmet>
        <title>{t(`general.keysForMeta.register-.title`)}</title>
        <meta name="description" content={t(`general.keysForMeta.register-.description`)} />
        <meta name="keywords" content={t(`general.keysForMeta.register-.keywords`)} />
        <meta property="og:title" content={t(`general.keysForMeta.register-.title`)} />
        <meta property="og:description" content={t(`general.keysForMeta.register-.description`)} />
      </Helmet>

      <ShareModal />

      <Header flags={flags} />

      <main>
        <div id="registerSucceeded">
          {loading ? (
            <Loader />
          ) : (
            <>
              {subscriptionError ? (
                <p className="subscription-error">{t('general.generic_error')}</p>
              ) : (
                <>
                  <img src={schoolIcon} alt={schoolIcon} data-aos="fade-up" />
                  {success ? (
                    <>
                      <h1>
                        {schoolWasCreated
                          ? t('register.succeeded.title2_created')
                          : t('register.succeeded.title1')}
                      </h1>
                      <h3 className="white-space-pre-line">
                        {schoolWasCreated
                          ? t('register.succeeded.subTitle2_created')
                          : t('register.succeeded.subTitle1_new')}
                      </h3>
                      {schoolWasCreated ? (
                        <Button
                          text={t('register.succeeded.btn1')}
                          type={'primary'}
                          linkType={'extern'}
                          link={`/${i18n.language}/auth`}
                        />
                      ) : (
                        <Loader position="" />
                      )}
                    </>
                  ) : (
                    <>
                      <h1 className="hasAdservio">{t('register.succeeded.linkBroken.title')}</h1>
                      <h3 className="hasAdservio">
                        {t('register.succeeded.linkBroken.subTitle_new')}
                      </h3>
                      <h3 className="hasAdservio">
                        {t('register.succeeded.linkBroken.subTitle2_new')}
                      </h3>
                      <Button
                        text={t('register.succeeded.linkBroken.buttonNew')}
                        type={'primary'}
                        linkType={'intern'}
                        link={`/${i18n.language}/p/register`}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>

      <Footer flags={flags} />
    </>
  );
};

export default RegisterSucceeded;
