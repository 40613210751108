import Button from "../general/Button";
import shapes from "../../assets/home/modernLearning/shapes.svg";
import { useTranslation } from "react-i18next";

import "./modernLearning.css";

const ModernLearning = () => {
  const { i18n, t } = useTranslation("common");
  return (
    <section id="container_benefits">
      <h1>{t("home.modernLearning.title")}</h1>
      <section className="beefits_content">
        <img src={shapes} alt={shapes} />
        <section className="textContainer">
          <div className="benefits">
            <div className="benefit">
              <span>1.</span>
              <section>
                <h4>{t("home.modernLearning.content.subTitle1")}</h4>
                <p>{t("home.modernLearning.content.p1")}</p>
              </section>
            </div>
            <div className="benefit">
              <span>2.</span>
              <section>
                <h4>{t("home.modernLearning.content.subTitle2")}</h4>
                <p>{t("home.modernLearning.content.p2")}</p>
              </section>
            </div>
            <div className="benefit">
              <span>3.</span>
              <section>
                <h4>{t("home.modernLearning.content.subTitle3")}</h4>
                <p>{t("home.modernLearning.content.p3")}</p>
              </section>
            </div>
          </div>
          <Button
            type={"ghost"}
            text={t("home.modernLearning.content.btn")}
            linkType={"intern"}
            link={`/${i18n.language}/p/schools/public`}
          />
        </section>
      </section>
    </section>
  );
};

export default ModernLearning;
