import styled from 'styled-components';

import eafBadge from '../../assets/general/EAF_Badge_Blue.png';

import { useTranslation } from 'react-i18next';

const EafCard = () => {
  const { t } = useTranslation('common');
  return (
    <Container className="eaf_card">
      <section className="eaf_container">
        <img src={eafBadge} alt="" />
        <div className="text_container">
          <h2>91%</h2>
          <p>{t('about.eaf_card.text')}</p>
        </div>
      </section>
    </Container>
  );
};

export default EafCard;

const Container = styled.section`
  margin-top: 165px;
  height: fit-content;
  display: flex;
  justify-content: center;

  & .eaf_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 1450px;

    & .text_container {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      color: #084365;
      gap: 80px;
      margin-top: 40px;
      padding: 80px;
      background-color: rgba(8, 67, 102, 0.15);
      border-radius: 0px 16px 16px 0px;

      & h2 {
        font-family: 'Manrope-ExtraBold';
        font-size: 120px;
        font-weight: bold !important;
        line-height: 1.2;
      }

      & p {
        font-family: 'Manrope';
        font-size: 24px;
        line-height: 1.2;
      }
    }

    & img {
      width: 385px;
    }
  }

  @media only screen and (max-width: 1550px) {
    & .eaf_container {
      padding: 0px 40px;
      & .text_container {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        padding: 30px;
        gap: 10px;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    margin-top: 70px;

    & .eaf_container {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0px 20px;

      & .text_container {
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin-top: -77px;
        padding: 87px 28px 40px 28px;
        border-radius: 16px;
        max-width: 500px;

        & h2 {
          font-family: 'Manrope-Bold';
          font-size: 80px;
          line-height: 1.2;
        }

        & p {
          text-align: center;
          font-size: 16px;
        }
      }

      & img {
        width: 220px;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    margin-bottom: 65px;
  }

  @media only screen and (max-width: 750px) {
    margin-top: 0px;
  }
`;
