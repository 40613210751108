import "./csr_digitalization.css";
import Star_1 from "../../assets/parents/star_1.svg";
import Star_2 from "../../assets/parents/star_2.svg";
import AOS from "aos";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const CsrDigitalization = () => {
  const { t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <section className="csr_digitalization">
      <div className="csr_digitalization_container">
        <div>
          <h1>{t("csr.digitalization.title.t2")}</h1>
        </div>
        <div>
          <p>{t("csr.digitalization.p1")}</p>
          <p>{t("csr.digitalization.p3")}</p>
        </div>
        <img src={Star_1} alt={Star_1} data-aos="fade-up-right" />
        <img src={Star_2} alt={Star_2} data-aos="fade-down-left" />
      </div>
    </section>
  );
};

export default CsrDigitalization;
