import schoolIcon from "../../assets/registerFlow/schoolIcon.svg";
import Button from "../general/Button";
import "./registerSucceeded.css";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";

const SchoolIsAdservio = () => {
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <div id="registerSucceeded">
      <img src={schoolIcon} alt={schoolIcon} data-aos="fade-up" />

      <h1 className="hasAdservio">
        {t("register.succeeded.hasAdservio.title")}
      </h1>
      <h3 className="hasAdservio">
        {t("register.succeeded.hasAdservio.subTitle")}
      </h3>
      <div className="btns">
        <Button
          text={t("register.succeeded.hasAdservio.btn1")}
          classList="hasAdservio"
          type={"primary"}
          link={`/${i18n.language}/auth`}
        />
        <Button
          text={t("register.succeeded.hasAdservio.btn2")}
          classList="hasAdservio"
          type={"primary-invert"}
        />
      </div>
      <div
        className="hasAdservio"
        onClick={() => navigate(`/${i18n.language}/p/contact`)}
      >
        <Trans
          i18nKey="register.succeeded.hasAdservio.contact-new"
          t={t}
          components={[<span></span>]}
        />
      </div>
    </div>
  );
};

export default SchoolIsAdservio;
