import { useTranslation } from "react-i18next";

const Headmaster = ({ images }) => {
  const { t } = useTranslation("common");
  return (
    <div className="benefits_blue_icons_container" id="headmaster">
      <h1>{t("schools.benefits.public.blue-icons.headmaster.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["zap-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["barchart2-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["grades-icon.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["homework-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r1c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["portfolio-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["mail-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["calendar-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["trendingup-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r2c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["filetext-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r3c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["groups-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r3c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["piechart-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headmaster.r3c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["coins-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.auxiliar.r2c2")}</p>
        </div>
      </div>
    </div>
  );
};

export default Headmaster;
