import { useTranslation } from "react-i18next";

const VirtualSchools = ({ images }) => {
  const { t } = useTranslation("common");
  return (
    <div className="benefits_blue_icons_container" id="virtual">
      <h1>{t("schools.benefits.private.blue-icons.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["cloud-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["grades-icon.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["globe-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["homework-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r1c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["library-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["barchart2-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["comment-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["survey-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r2c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["video-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r3c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["coins-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r3c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["trendingup-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r3c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["student-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.private.blue-icons.r3c4")}</p>
        </div>
      </div>
    </div>
  );
};

export default VirtualSchools;
