import photo1 from "../../assets/Testimoniale Homepage/Magda-Dache.png";
import photo2 from "../../assets/Testimoniale Homepage/Maria-Sevcenco.png";
import photo3 from "../../assets/Testimoniale Homepage/Liliana-Carp.png";
import photo4 from "../../assets/Testimoniale Homepage/Stefana-Croitoru.png";
import photo5 from "../../assets/Testimoniale Homepage/Diana-Raducanu.png";
import photo6 from "../../assets/Testimoniale Homepage/Constantin-Toma.png";
import photo7 from "../../assets/Testimoniale Homepage/Alina-Cuciurean.png";
import photo8 from "../../assets/Testimoniale Homepage/Zoltan-Torok-Gyurko.png";

import { useEffect, useState } from "react";
import RecommendationsBubble from "./RecommendationsBubble";
import horizoltalScroll from "../../utility/horizontallScroll";
import { scrollInCenter } from "../../utility/horizontallScroll";
import dragToScroll from "../../utility/dragToScroll";
import { useTranslation } from "react-i18next";
import { fullOnViewPortWidth } from "../../utility/positionOnViewPort";
import { isMobileOnly } from "react-device-detect";

import "./recommendations.css";

const Recommendations = ({ videoUrl, setVideoUrl }) => {
  const { t } = useTranslation("common");
  useEffect(() => {
    horizoltalScroll(document.getElementById("scrollContainer"));
    dragToScroll(document.getElementById("scrollContainer"));
    clickHandler(1);
    if (isMobileOnly) {
      document
        .getElementById("scrollContainer")
        .addEventListener("scroll", (e) => {
          for (let i = 0; i < 6; ++i) {
            if (
              fullOnViewPortWidth(
                document.getElementById("scrollContainer").children[i]
              )
            ) {
              setbulletIndex(i + 1);
            }
          }
        });
    }
  }, []);
  const [bulletIndex, setbulletIndex] = useState(1);
  const clickHandler = (index) => {
    if (isMobileOnly) {
      scrollInCenter(
        document.getElementById("scrollContainer"),
        document.getElementById("scrollContainer").children[index - 1]
      );
      setbulletIndex(index);
    }
  };
  return (
    <section className="recommendations">
      <h1>{t("home.recommendations.title")}</h1>

      <section id="scrollContainer">
        <RecommendationsBubble
          text={t("home.recommendations.bubble.7.p")}
          author={t("home.recommendations.bubble.7.name")}
          role={t("home.recommendations.bubble.7.role")}
          profilePhoto={photo7}
          video={"https://youtu.be/cs0zcWxtXXg"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(7)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.8.p")}
          author={t("home.recommendations.bubble.8.name")}
          role={t("home.recommendations.bubble.8.role")}
          profilePhoto={photo8}
          video={"https://youtu.be/Nfd3_dWcMU8"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(8)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.1.p")}
          author={t("home.recommendations.bubble.1.name")}
          role={t("home.recommendations.bubble.1.role")}
          profilePhoto={photo1}
          video={"https://www.youtube.com/watch?v=Ul13cd198aU"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(1)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.2.p")}
          author={t("home.recommendations.bubble.2.name")}
          role={t("home.recommendations.bubble.2.role")}
          profilePhoto={photo2}
          video={"https://www.youtube.com/watch?v=42ajZ_3iG3Q"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(2)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.3.p")}
          author={t("home.recommendations.bubble.3.name")}
          role={t("home.recommendations.bubble.3.role")}
          profilePhoto={photo3}
          video={"https://www.youtube.com/watch?v=2owzbjzxmFg"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(3)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.4.p")}
          author={t("home.recommendations.bubble.4.name")}
          role={t("home.recommendations.bubble.4.role")}
          profilePhoto={photo4}
          video={"https://www.youtube.com/watch?v=eFRKOoDn5w4"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(4)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.5.p")}
          author={t("home.recommendations.bubble.5.name")}
          role={t("home.recommendations.bubble.5.role")}
          profilePhoto={photo5}
          video={"https://www.youtube.com/watch?v=y9V0ZbTCFeY"}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(5)}
        />
        <RecommendationsBubble
          text={t("home.recommendations.bubble.6.p")}
          author={t("home.recommendations.bubble.6.name")}
          role={t("home.recommendations.bubble.6.role")}
          profilePhoto={photo6}
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          action={() => clickHandler(6)}
        />
      </section>
      {isMobileOnly && (
        <section className="carousel" id="carouselBullets">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((el, index) => {
            return (
              <div
                key={index}
                className={bulletIndex === el ? "active" : ""}
              ></div>
            );
          })}
        </section>
      )}
    </section>
  );
};

export default Recommendations;
