import "./cityhall_intro.css";
import Button from "../general/Button";
import CityhallIntroBackground from "../../assets/institutions/cityhall_intro_background.svg";
import { useEffect } from "react";
import AOS from "aos";
import { useTranslation } from "react-i18next";
const CityhallIntro = () => {
  const { i18n, t } = useTranslation("common");
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <section className="cityhall_intro">
      <img
        src={CityhallIntroBackground}
        alt={CityhallIntroBackground}
        data-aos="fade-left"
      />
      <div className="cityhall_intro_content">
        <h1>{t("institutions.cityhall.intro.title")}</h1>
        <div className="cityhall_intro_paragraphs">
          <div className="cityhall_intro_parahraph_1">
            <p>{t("institutions.cityhall.intro.p1")}</p>
            <p>{t("institutions.cityhall.intro.p2")}</p>
          </div>
          <p>{t("institutions.cityhall.intro.p3")}</p>
        </div>
        <h2>{t("institutions.cityhall.intro.h2")}</h2>
        <h3>{t("institutions.cityhall.intro.h3")}</h3>
        <Button
          text={t("institutions.cityhall.intro.button")}
          type={"primary"}
          linkType={"intern"}
          link={`/${i18n.language}/p/contact`}
        />
      </div>
    </section>
  );
};

export default CityhallIntro;
