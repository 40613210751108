import React from 'react';

//style => intro.css

import roFlag from '../../assets/home/intro/logos/romania_flag.svg';
import euFlag from '../../assets/home/intro/logos/EU_flag.svg';
import roGovLogoWhite from '../../assets/home/intro/logos/romgov_logo_white.png';
import roGovLogo from '../../assets/home/intro/logos/romgov_logo.png';
import screensWhite from '../../assets/home/intro/logos/screens_icon_white.svg';
import screens from '../../assets/home/intro/logos/screens_icon_grey.svg';
import msWhite from '../../assets/home/intro/logos/ms_icon_white.svg';
import kpmgWhite from '../../assets/home/intro/logos/kpmg_logo_white.svg';
import msColor from '../../assets/home/intro/logos/ms_icon_color.svg';
import kpmgColor from '../../assets/home/intro/logos/kpmg_logo_color.svg';
import EAF_logo from '../../assets/logos/Parteneri & Certificări/EAF_logo.png';
import { useTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

const IntroLogos = ({ id }) => {
  const { t, i18n } = useTranslation('common');

  return (
    <div className={`intro_logos ${i18n.language === 'ro' ? 'scroll' : ''}`} id={id}>
      {id === 'intro_logos_outside' && (
        <div className="intro_logos_logoContainer">
          <img src={EAF_logo} alt={'EAF_logo'} className="EAF_logo" />
        </div>
      )}
      <div className="intro_logos_logoContainer">
        <div className="imgContainer">
          {i18n.language === 'ro' ? (
            <img src={roFlag} alt={roFlag} />
          ) : (
            <img src={euFlag} alt={euFlag} />
          )}
        </div>
        <p className="text">{ReactHtmlParser(t('home.intro.logos.madeIn'))}</p>
      </div>

      {i18n.language === 'ro' && (
        <React.Fragment>
          <div className="intro_logos_logoContainer">
            <div className="imgContainer">
              {id === 'intro_logos_outside' ? (
                <img src={roGovLogo} alt={roGovLogo} />
              ) : (
                <img src={roGovLogoWhite} alt={roGovLogoWhite} />
              )}
            </div>
            <p className="text">{ReactHtmlParser(t('home.intro.logos.recognized'))}</p>
          </div>
          <div className="intro_logos_logoContainer">
            <div className="imgContainer">
              {id === 'intro_logos_outside' ? (
                <img src={screens} alt={screens} />
              ) : (
                <img src={screensWhite} alt={screensWhite} />
              )}
            </div>
            <p className="text">{ReactHtmlParser(t('home.intro.logos.promotor'))}</p>
          </div>
        </React.Fragment>
      )}
      <div className="intro_logos_logoContainer">
        <div className="imgContainer">
          {id === 'intro_logos_outside' ? (
            <img src={msColor} alt={msColor} />
          ) : (
            <img src={msWhite} alt={msWhite} />
          )}
        </div>
        <p className="text">{ReactHtmlParser(t('home.intro.logos.microsoft'))}</p>
      </div>
      <div className="intro_logos_logoContainer">
        <div className="imgContainer">
          {id === 'intro_logos_outside' ? (
            <img src={kpmgColor} alt={kpmgColor} />
          ) : (
            <img src={kpmgWhite} alt={kpmgWhite} />
          )}
        </div>
        <p className="text">{ReactHtmlParser(t('home.intro.logos.kpmg'))}</p>
      </div>
    </div>
  );
};

export default IntroLogos;
