import React from 'react';
import billing_illustration from '../../../assets/general/billing_stats.png';
import coinsImg from '../../../assets/schools/blueIcons/coins-bround.svg';
import './BillingSection.css';
import { useTranslation } from 'react-i18next';

const BillingSection = () => {
  const { t } = useTranslation('common');
  const benefits_length = 7;
  const benefits = Array.from({ length: benefits_length }, (_, i) => i + 1).map((i) => ({
    title: t(`general.billing_section.benefits.titles.title${i}`),
    description: t(`general.billing_section.benefits.descriptions.description${i}`),
  }));

  return (
    <section className="billing_section">
      <h1 className="billing_title">{t('general.billing_section.title')}</h1>
      <div className="billing_container">
        <div className="billing_illustration_container">
          <img className="billing_illustration_coins" src={coinsImg} alt="coins" />
          <img className="billing_illustration" src={billing_illustration} alt="billing" />
        </div>
        <div className="billing_container_benefits">
          {benefits.map((benefit, index) => (
            <div className="billing_container_benefit" key={index}>
              <h2 className="billing_container_benefit_title">{benefit.title}</h2>
              <p className="billing_container_benefit_description">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BillingSection;
