export function enterOnViewPort(el, offSet) {
  if (el) {
    const rect = el.getBoundingClientRect();
    return rect.top - offSet <= window.innerHeight;
  }
}

export function fullOnViewPort(el, offSet) {
  if (el) {
    const rect = el.getBoundingClientRect();
    return rect.bottom - offSet <= window.innerHeight;
  }
}
export function fullOnViewPortWidth(el) {
  if (el) {
    const rect = el.getBoundingClientRect();

    return rect.left > -50 && rect.left <= window.innerWidth - 100;
  }
}
export function getOffset(el) {
  if (el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }
}
export function getWidth(el) {
  const rect = el.getBoundingClientRect();
  return rect.right - rect.left;
}
export default enterOnViewPort;
