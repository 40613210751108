import frame from "../../assets/home/reasons/icon-arches.svg";
import plane from "../../assets/home/reasons/plane.svg";
import trainingIcon from "../../assets/home/reasons/training-icon.svg";
import coins from "../../assets/home/reasons/coins.svg";
import securityIcon from "../../assets/home/reasons/security-icon.svg";
import flashIcon from "../../assets/home/reasons/flash-icon.svg";
import phoneIcon from "../../assets/home/reasons/phone-icon.svg";
import planeTrail from "../../assets/home/reasons/plane-trail.svg";

import { useTranslation, Trans } from "react-i18next";

import "./reasons.css";

const Reasons = () => {
  const { t } = useTranslation("common");
  return (
    <section className="reasons">
      <div className="reasons_container">
        <img src={planeTrail} alt={planeTrail} />
        <img src={planeTrail} alt={planeTrail} />
        <div className="reasons_container_inside">
          <h1>{t("home.reasons.title")}</h1>
          <div className="wrapper">
            <div className="reason">
              <div className="reason_box">
                <img src={plane} alt={plane} />
                <img src={frame} alt={frame} />
              </div>
              <div className="reason-text">{t("home.reasons.content.p1")}</div>
            </div>
            <div className="reason">
              <div className="reason_box">
                <img src={trainingIcon} alt={trainingIcon} />
                <img src={frame} alt={frame} />
              </div>
              <div className="reason-text">{t("home.reasons.content.p2")}</div>
            </div>
            <div className="reason">
              <div className="reason_box">
                <img src={coins} alt={coins} />
                <img src={frame} alt={frame} />
              </div>
              <div className="reason-text">{t("home.reasons.content.p3")}</div>
            </div>
            <div className="reason">
              <div className="reason_box">
                <img src={securityIcon} alt={securityIcon} />
                <img src={frame} alt={frame} />
              </div>
              <div className="reason-text">{t("home.reasons.content.p4")}</div>
            </div>
            <div className="reason">
              <div className="reason_box">
                <img src={flashIcon} alt={flashIcon} />
                <img src={frame} alt={frame} />
              </div>
              <div className="reason-text">{t("home.reasons.content.p5")}</div>
            </div>
            <div className="reason">
              <div className="reason_box">
                <img src={phoneIcon} alt={phoneIcon} />
                <img src={frame} alt={frame} />
              </div>
              <div className="reason-text">
                <Trans
                  i18nKey="home.reasons.content.p6-new"
                  t={t}
                  components={[<span></span>]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reasons;
