/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import AOS from 'aos';

import Api2 from '../../Libs/api';
import Select from '../content/select/Select';
import schoolIcon from '../../assets/registerFlow/schoolIcon.svg';
import PhoneInput from '../forms/PhoneInputWithSelect';
import Button from '../general/Button';
import './schoolRegister.css';

const recaptchaKey = '6LcjDPYZAAAAAP974S8Yo7gIbJAROqi2e4i8rdGg';

const SchoolFound = ({ firstStepData, setLastStep, goBack }) => {
  const { i18n, t } = useTranslation('common');

  const { school, city, country, newSchoolName, azsEmail, azsID } = firstStepData;

  const [schoolName, setSchoolName] = useState(
    school.label && school.value !== '0' ? school.label : newSchoolName
  );
  const [newCountry, setNewCountry] = useState(country);
  const [newCity, setNewCity] = useState(city);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const [filteredTowns, setFilteredTowns] = useState([]);
  const [town, setTown] = useState();
  const [townName, setTownName] = useState();
  const [address, setAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(azsEmail);
  const [phoneNr, setPhoneNr] = useState();
  const [schoolManager, setSchoolManager] = useState(false);
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    AOS.init({ mirror: 'false', once: 'true', duration: 1000 });

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const fetchCities = (option) =>
      Api2.get('./adrese/judete', {
        taraID: option.value,
        _limit: 1000,
      }).then((response) => {
        const formattedCities = response.data.map((city) => ({
          label: city.judNume,
          value: city.judID,
        }));
        formattedCities.push({
          label: t('register.schoolDetails.another-city'),
          value: '0',
        });
        setCities(formattedCities);
        setFilteredCities(formattedCities);
      });

    if (newCountry && t) {
      fetchCities(newCountry);
    }
  }, [t, newCountry]);

  useEffect(() => {
    if (newCity) {
      Api2.get('./adrese/cities', {
        judID: newCity.value,
        _limit: 1000,
      }).then((response) => {
        const formattedTowns = response.data.map((town) => ({
          label: town.cityFullName,
          value: town.cityID,
        }));

        setTowns(formattedTowns);
        setFilteredTowns(formattedTowns);
      });
    }
  }, [newCity]);

  const sortCountriesByNames = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (i18n.language && t) {
      Api2.get('./adrese/tari', {
        _lang: i18n.language,
        _limit: 1000,
      }).then((response) => {
        const formattedCountries = response.data.map((ct) => ({
          label: ct.taraNumeFull,
          value: ct.taraID,
        }));

        if (i18n.language !== 'ro') {
          formattedCountries.sort(sortCountriesByNames);
        }

        setCountries(formattedCountries);
        setFilteredCountries(formattedCountries);
      });
    }
  }, [t, i18n.language]);

  const found = school.value && school.value !== '0';

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // const validatePhoneNr = (phoneNr) => {
  //   const re = /^(\+\d{1,3}\s)?\(?\d{1,3}\)?[\s.-]\d{1,3}[\s.-]\d{1,4}$/im;
  //   return re.test(phoneNr);
  // };

  const cleanString = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    if (!string) return '';

    return string
      .toString()
      .toLowerCase()
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };

  const handleSearchConutries = (value) => {
    let newCountries = countries.filter(({ label }) =>
      cleanString(label).includes(cleanString(value))
    );

    if (!newCountries.length) {
      newCountries = [
        {
          label: t('register.schoolDetails.another-country'),
          value: '0',
        },
      ];
    }

    setFilteredCountries(newCountries);
  };

  const hasError = (formErrors, fieldName, errorCode) => {
    if (!formErrors.length) return false;
    const error = formErrors.filter(
      (fieldErr) => fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode
    );
    return error.length ? error[0] : false;
  };

  const handleFocus = (fieldName) => {
    setErrors(errors.filter((fieldErr) => fieldErr.errorField !== fieldName));
  };

  const prepareRecaptcha = async () =>
    new Promise((resolve, reject) => {
      try {
        window.grecaptcha
          .execute(recaptchaKey, {
            action: 'submit',
          })
          .then((token) => {
            resolve(token);
          })
          .catch(() => {
            setShowErrorMessage(true);
            reject();
          });
      } catch (error) {
        setShowErrorMessage(true);
        reject();
      }
    });

  const validateConfirmPassword = (value) => {
    if (password === value) {
      return true;
    } else {
      setErrors([{ errorCode: '2', errorField: 'confirmPassword' }]);
      return false;
    }
  };

  const submitForm = async () => {
    const newErrors = [];

    if (!schoolName && !found) {
      newErrors.push({ errorCode: '1', errorField: 'schoolName' });
    }
    if (!newCountry && !found) {
      newErrors.push({ errorCode: '1', errorField: 'newCountry' });
    }
    if (!newCity && !found) {
      newErrors.push({ errorCode: '1', errorField: 'newCity' });
    }
    if (!town && !found) {
      newErrors.push({ errorCode: '1', errorField: 'town' });
    }
    if (!address && !found) {
      newErrors.push({ errorCode: '1', errorField: 'address' });
    }
    if (!firstName) {
      newErrors.push({ errorCode: '1', errorField: 'firstName' });
    }
    if (!lastName) {
      newErrors.push({ errorCode: '1', errorField: 'lastName' });
    }
    if (!email) {
      newErrors.push({ errorCode: '1', errorField: 'email' });
    } else {
      if (!validateEmail(email)) {
        newErrors.push({ errorCode: '2', errorField: 'email' });
      }
    }
    if (!phoneNr) {
      newErrors.push({ errorCode: '1', errorField: 'phoneNr' });
    }
    //todo: uncomment when phone number validation is fixed 29.03.2023
    // else {
    //   if (!validatePhoneNr(phoneNr)) {
    //     newErrors.push({ errorCode: "2", errorField: "phoneNr" });
    //   }
    // }
    if (!password) {
      newErrors.push({ errorCode: '1', errorField: 'password' });
    }
    if (!confirmPassword) {
      newErrors.push({ errorCode: '1', errorField: 'confirmPassword' });
    }
    if (!validateConfirmPassword(confirmPassword)) {
      newErrors.push({ errorCode: '2', errorField: 'confirmPassword' });
    }

    if (!schoolManager) {
      newErrors.push({ errorCode: '1', errorField: 'schoolManager' });
    }

    if (!terms) {
      newErrors.push({ errorCode: '1', errorField: 'terms' });
    }

    if (newErrors.length) {
      setErrors(newErrors);
    } else {
      try {
        await Api2.del('./auth', {});
      } catch (error) {}

      try {
        setSubmitDisabled(true);

        const recaptchaToken = await prepareRecaptcha();

        const body = {
          schoolName: schoolName || school.label,
          schoolCountryID: newCountry.value,
          schoolCityID: town.value,
          schoolCityName: townName,
          schoolCountyID: newCity.value,
          schoolAddress: address,
          firstName,
          lastName,
          password,
          confirmPassword,
          phone: phoneNr.replace(/\s/g, ''),
          email,
          consentOwner: 1,
          consentTos: 1,
          recaptcha: recaptchaToken,
          _lang: i18n.language,
        };

        if (azsID) {
          body.azsID = azsID;
        }

        await Api2.post('./scoli/enrollment', body);

        setLastStep({
          schoolName,
          newCountry,
          newCity,
          address,
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          phoneNr,
          schoolManager,
          terms,
          recaptchaKey,
        });

        setSubmitDisabled(false);
      } catch (error) {
        const {
          response: { data },
        } = error;
        if (data) {
          data.forEach((error) => {
            newErrors.push(error);
          });
          setErrors(newErrors);
        }
        setShowErrorMessage(true);
        setSubmitDisabled(false);
      }
    }
  };

  const handleSearchCities = (value) => {
    let newCities = cities.filter(({ label }) => cleanString(label).includes(cleanString(value)));

    if (!newCities.length) {
      newCities = [
        {
          label: t('register.schoolDetails.another-city'),
          value: '0',
        },
      ];
    }
    setFilteredCities(newCities);
  };

  const handleSearchTowns = (value) => {
    let newTowns = towns.filter(({ label }) => cleanString(label).includes(cleanString(value)));

    setFilteredTowns(newTowns);
  };

  return (
    <div id="schoolRegister">
      <div className="topSection">
        <img src={schoolIcon} alt={schoolIcon} data-aos="fade-in" />
        <div>
          {found ? (
            <>
              <h1>{`${school.scoalaNume}, ${school.judNume}`}</h1>
              {school.scoalaLocSup !== school.scoalaLoc ? (
                <p>{`${school.scoalaLocSup}, ${school.scoalaLoc}`}</p>
              ) : (
                <p>{school.scoalaLoc}</p>
              )}

              <p>{`${t('register.schoolRegister.sirues')}: ${school.value}`}</p>
            </>
          ) : (
            <h1>{t('register.schoolRegister.title')}</h1>
          )}
        </div>
      </div>

      <form>
        <input hidden type="text" name="azsID" value={azsID} />

        {!found && (
          <div className="schoolInfo">
            <p className="title">{t('register.schoolRegister.form.p1')}</p>
            <label htmlFor="schoolName">{t('register.schoolRegister.form.schoolName')}</label>
            <input
              type="text"
              name="schoolName"
              id="schoolName"
              value={schoolName}
              onChange={(event) => setSchoolName(event.target.value)}
              onFocus={() => handleFocus('schoolName')}
            />
            {hasError(errors, 'schoolName', '1') && (
              <p className="form__input-error">{t('contact.form.school_required_error')}</p>
            )}
            <div className="row">
              <label htmlFor="country">
                {t('register.schoolRegister.form.country')}
                <Select
                  name="country"
                  value={newCountry}
                  onChangeOptions={(option) => {
                    console.log(option);
                    setNewCountry(option);
                    setNewCity(null);
                    setCities([]);
                    setFilteredCities([]);
                    setTown(null);
                    setTowns([]);
                    setFilteredTowns([]);
                  }}
                  onFocus={() => handleFocus('newCountry')}
                  isSearchable
                  className="select-country"
                  options={filteredCountries}
                  noClientFilter
                  placeholder={t('register.schoolDetails.placeholder')}
                  onInputValueChange={handleSearchConutries}
                  noOptionsMessage={() => t('contact.form.school_no_options')}
                />
                {hasError(errors, 'newCountry', '1') && (
                  <p className="form__input-error">{t('contact.form.country_required_error')}</p>
                )}
              </label>
              <label htmlFor="city">
                {t('register.schoolRegister.form.city-new')}
                <Select
                  name="city"
                  value={newCity}
                  onChangeOptions={(option) => {
                    setNewCity(option);
                    setTown(null);
                    setTownName(null);
                    setTowns([]);
                    setFilteredTowns([]);
                  }}
                  onFocus={() => handleFocus('city')}
                  isSearchable
                  className="select-city"
                  options={filteredCities}
                  noClientFilter
                  placeholder={t('contact.form.placeholder')}
                  onInputValueChange={handleSearchCities}
                  noOptionsMessage={() => t('contact.form.school_no_options')}
                  isDisabled={!country?.value && !newCountry}
                />
                {hasError(errors, 'newCity', '1') && (
                  <p className="form__input-error">{t('contact.form.city_required_error')}</p>
                )}
              </label>
              <label htmlFor="town">
                {t('register.schoolRegister.form.town-new')}
                <Select
                  name="town"
                  value={town}
                  onChangeOptions={(option) => {
                    setTown(option);
                    setTownName(option.label);
                  }}
                  onFocus={() => handleFocus('town')}
                  isSearchable
                  className="select-city"
                  options={filteredTowns}
                  noClientFilter
                  placeholder={t('contact.form.placeholder')}
                  onInputValueChange={handleSearchTowns}
                  noOptionsMessage={() => t('contact.form.school_no_options')}
                  isDisabled={!country?.value && !newCountry}
                />
                {hasError(errors, 'town', '1') && (
                  <p className="form__input-error">
                    {t('register.schoolRegister.town_required_error')}
                  </p>
                )}
              </label>
            </div>
            <label htmlFor="address">{t('register.schoolRegister.form.address')}</label>
            <input
              type="text"
              name="address"
              id="address"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              onFocus={() => handleFocus('address')}
            />
            {hasError(errors, 'address', '1') && (
              <p className="form__input-error">{t('contact.form.address_required_error')}</p>
            )}
          </div>
        )}
        <div className="schoolManagerInfo">
          <p className="title"> {t('register.schoolRegister.title2')}</p>
          <div className="row">
            <label htmlFor="firstName">{t('register.schoolRegister.form.firstName')}</label>
            <label htmlFor="lastName">{t('register.schoolRegister.form.lastName')}</label>
            <div>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                onFocus={() => handleFocus('firstName')}
              />
              {hasError(errors, 'firstName', '1') && (
                <p className="form__input-error">{t('contact.form.name_required_error')}</p>
              )}
            </div>

            <div>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                onFocus={() => handleFocus('lastName')}
              />
              {hasError(errors, 'lastName', '1') && (
                <p className="form__input-error">{t('contact.form.last-name_required_error')}</p>
              )}
            </div>
          </div>

          <div className="row">
            <label htmlFor="email">{t('register.schoolRegister.form.email')}</label>
            <label htmlFor="phone">{t('register.schoolRegister.form.phone')}</label>

            <div>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onFocus={() => handleFocus('email')}
              />
              {hasError(errors, 'email', '1') && (
                <p className="form__input-error">{t('contact.form.email_required_error')}</p>
              )}
              {hasError(errors, 'email', '2') && (
                <p className="form__input-error">{t('contact.form.email_invalid_format_error')}</p>
              )}
              {hasError(errors, 'email', '99') && (
                <p className="form__input-error">{t('contact.form.email_exist_error')}</p>
              )}
            </div>

            <div>
              <PhoneInput
                type="tel"
                name="phone"
                id="phone"
                value={phoneNr}
                onChange={setPhoneNr}
                onFocus={() => handleFocus('phoneNr')}
              />
              {hasError(errors, 'phoneNr', '1') && (
                <p className="form__input-error">{t('contact.form.phone_required_error')}</p>
              )}
              {hasError(errors, 'phoneNr', '2') && (
                <p className="form__input-error">{t('contact.form.phone_invalid_format_error')}</p>
              )}
            </div>
          </div>

          <div className="row">
            <label htmlFor="password">{t('register.schoolRegister.form.password')}</label>
            <label htmlFor="confirmPassword">
              {t('register.schoolRegister.form.confirmPassword')}
            </label>

            <div>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onFocus={() => handleFocus('password')}
              />
              {hasError(errors, 'password', '1') && (
                <p className="form__input-error">{t('contact.form.password_required_error')}</p>
              )}
            </div>
            <div>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                onFocus={() => handleFocus('confirmPassword')}
                onBlur={() => validateConfirmPassword(confirmPassword)}
              />
              {hasError(errors, 'confirmPassword', '1') && (
                <p className="form__input-error">
                  {t('contact.form.confirm_password_required_error')}
                </p>
              )}
              {hasError(errors, 'confirmPassword', '2') && (
                <p className="form__input-error">{t('contact.form.password_matching_error')}</p>
              )}
            </div>
          </div>
        </div>

        <div className="checkBoxes">
          <label htmlFor="schoolManager">
            <input
              type="checkbox"
              name="schoolManager"
              id="schoolManager"
              checked={schoolManager}
              onChange={(event) => setSchoolManager(event.target.checked)}
              onFocus={() => handleFocus('schoolManager')}
            />
            {t('register.schoolRegister.form.checkBox1')}
          </label>
          {hasError(errors, 'schoolManager', '1') && (
            <p className="form__input-error">{t('contact.form.required_error')}</p>
          )}
          <label htmlFor="terms">
            <input
              type="checkbox"
              name="terms"
              id="terms"
              checked={terms}
              onChange={(event) => setTerms(event.target.checked)}
              onFocus={() => handleFocus('terms')}
            />
            <Trans
              i18nKey="register.schoolRegister.form.checkBox3"
              t={t}
              components={[
                <a
                  href="https://www.adservio.ro/ro/p/privacy-policy"
                  rel="noreferrer"
                  target="_blank"
                ></a>,
                <a
                  href="https://www.adservio.ro/ro/p/terms-and-condition"
                  rel="noreferrer"
                  target="_blank"
                ></a>,
              ]}
            />
          </label>
          {hasError(errors, 'terms', '1') && (
            <p className="form__input-error">{t('contact.form.required_error')}</p>
          )}
          {showErrorMessage && (
            <p className="form__input-error">{t('register.schoolRegister.api_error')}</p>
          )}
        </div>

        {!azsID && (
          <Button
            text={t('register.schoolRegister.form.btn1')}
            type={'primary-invert'}
            action={goBack}
          />
        )}

        <Button
          text={t('register.schoolRegister.form.btn2')}
          type={'primary'}
          disabled={submitDisabled}
          action={submitForm}
        />
      </form>
    </div>
  );
};

export default SchoolFound;
