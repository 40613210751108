import LiveChatIcon from "../../assets/schools/support/12-livechat-icon.svg";
import LiveChatIconHover from "../../assets/schools/support/12-livechat-icon-hover.svg";
import UserManualIcon from "../../assets/schools/support/13-usermanual-icon.svg";
import UserManualIconHover from "../../assets/schools/support/13-usermanual-icon-hover.svg";
import ContactFormIcon from "../../assets/schools/support/14-contactform-icon.svg";
import ContactFormIconHover from "../../assets/schools/support/14-contactform-icon-hover.svg";
import LeftArrows from "../../assets/schools/support/15-left-arrows.svg";
import RightArrow from "../../assets/schools/support/right-arrow.svg";
import RightArrowHover from "../../assets/schools/support/right-arrow-hover.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";

import "./support.css";
const Support = () => {
  const { i18n, t } = useTranslation("common");
  const [opneZendesk, setOpenZendesk] = useState(false);
  const navigate = useNavigate();
  const ZENDESK_KEY = "34253eb0-f339-4571-b90b-043c766cafc6";

  return (
    <>
      {opneZendesk && (
        <Zendesk
          defer
          zendeskKey={ZENDESK_KEY}
          onLoaded={() => {
            ZendeskAPI("webWidget", "setLocale", i18n.language);
            ZendeskAPI("webWidget", "open");
          }}
        />
      )}
      <section className="support_contact">
        <div className="support_wrapper">
          <div
            className="square_container "
            onClick={() => setOpenZendesk(true)}
          >
            <span>
              <div className="support_icon_w_text">
                <img src={LiveChatIcon} alt={LiveChatIcon} />
                <img src={LiveChatIconHover} alt={LiveChatIconHover} />
                <h2>{t("schools.support.card1.title")}</h2>
              </div>
              <div className="support_button">
                <img src={RightArrow} alt={RightArrow} />
                <img src={RightArrowHover} alt={RightArrowHover} />
              </div>
            </span>
          </div>
          <div
            className="square_container"
            onClick={() => {
              if (isMobile) {
                setTimeout(
                  () =>
                    window.open(
                    i18n.language === 'ro'? 'https://manual.adservio.ro/web/': 'https://manual.adservio.ro/web/en'
                      , "_blank"),
                  400
                );
              } else {
               window.open(
                    i18n.language === 'ro'? 'https://manual.adservio.ro/web/': 'https://manual.adservio.ro/web/en'
                      , "_blank")
              }
            }}
          >
            <span>
              <div className="support_icon_w_text">
                <img src={UserManualIcon} alt={UserManualIcon} />
                <img src={UserManualIconHover} alt={UserManualIconHover} />
                <h2>{t("schools.support.card2.title")}</h2>
                <p>{t("schools.support.card2.p")}</p>
              </div>
              <div className="support_button">
                <img src={RightArrow} alt={RightArrow} />
                <img src={RightArrowHover} alt={RightArrowHover} />
              </div>
            </span>
          </div>
          <div
            className="square_container"
            onClick={() => {
              if (isMobile) {
                setTimeout(() => navigate(`/${i18n.language}/contact`), 400);
              } else {
                navigate(`/${i18n.language}/p/contact`);
              }
            }}
          >
            <span>
              <div className="support_icon_w_text">
                <img src={ContactFormIcon} alt={ContactFormIcon} />
                <img src={ContactFormIconHover} alt={ContactFormIconHover} />
                <h2>{t("schools.support.card3.title")}</h2>
                <p>{t("schools.support.card3.p")}</p>
              </div>
              <div className="support_button">
                <img src={RightArrow} alt={RightArrow} />
                <img src={RightArrowHover} alt={RightArrowHover} />
              </div>
            </span>
          </div>
        </div>
        <div className="support_arrows">
          <h1>{t("schools.support.title")}</h1>
          <div className="support_colored_rectangle"></div>
          <img src={LeftArrows} alt={LeftArrows} />
        </div>
      </section>
    </>
  );
};

export default Support;
