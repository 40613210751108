import { useTranslation } from "react-i18next";

const Headteacher = ({ images }) => {
  const { t } = useTranslation("common");
  return (
    <div className="benefits_blue_icons_container" id="headteacher">
      <h1>{t("schools.benefits.public.blue-icons.headteacher.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["clock-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["usercheck-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["bell-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["trendingup-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r1c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["barchart2-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["video-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["groups-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["comment-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.headteacher.r2c4")}</p>
        </div>
      </div>
    </div>
  );
};

export default Headteacher;
