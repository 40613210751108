import Button from "../general/Button";
import { useTranslation } from "react-i18next";
import "./csrContact.css";
const CsrContact = () => {
  const { i18n, t } = useTranslation("common");

  return (
    <div id="csrContact">
      <h1>{t("csr.contact.title")}</h1>
      <h2>{t("csr.contact.subtitle")}</h2>
      <Button
        text={t("csr.contact.button")}
        type={"primary"}
        linkType="intern"
        link={`/${i18n.language}/p/contact`}
      />
    </div>
  );
};

export default CsrContact;
