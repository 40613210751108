import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import horizoltalScroll from "../../utility/horizontallScroll";
import dragToScroll from "../../utility/dragToScroll";
import { partenersForInternational, partenersFor } from "./partenersConstants";
import "./parteners.css";

const Parteners = ({ title, type }) => {
  const { i18n } = useTranslation("common");

  const parteners =
    i18n.language === "ro" ? partenersFor : partenersForInternational;

  useEffect(() => {
    horizoltalScroll(document.getElementById("scrollContainerParteners"));
    dragToScroll(document.getElementById("scrollContainerParteners"));
  }, []);

  return (
    <section id="parteners">
    {title  && <div>
       <h1>{title}</h1>
      </div>
    }
      <div className="parteners" id="scrollContainerParteners">
        {parteners[type].map((el, index) => (
          <img
            src={el.img}
            alt={el.img}
            style={{
              height: el.height + "px",
              width: el.width + "px",
            }}
            key={index}
          />
        ))}
      </div>
    </section>
  );
};

export default Parteners;
