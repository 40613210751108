import enterOnViewPort from "./positionOnViewPort";
const time = 2500;
const scaleFromLeftBottom = (el) => {
  if (el) {
    let transform = el.getAttribute("transform");
    let current = 0;
    let fisnishValue = 1;

    setInterval(() => {
      if (current < fisnishValue) {
        current += fisnishValue / 40;
        el.setAttribute("transform", transform + `scale(${current})`);
      } else {
        el.setAttribute("transform", transform + `scale(${fisnishValue})`);
      }
    }, time / 100);
  }
};

const slideIn = (el) => {
  if (el) {
    let width = el.getAttribute("width");
    let current = 0;
    let fisnishValue = width;

    setInterval(() => {
      if (current < fisnishValue) {
        current += fisnishValue / 40;
        el.setAttribute("width", `${current}`);
      } else {
        el.setAttribute("width", `${fisnishValue}`);
      }
    }, time / 100);
  }
};
const getXY = (str, getRotate) => {
  let x = 0;
  let y = 0;
  let rotate = 0;
  let i = 0;
  let decimals = 0;
  while (i < str.length && (str[i] >= "9" || str[i] <= "0")) i++;
  while (i < str.length && str[i] <= "9" && str[i] >= "0")
    x = x * 10 + (str[i++] - "0");
  ++i;

  while (i < str.length && str[i] <= "9" && str[i] >= "0") {
    x = x * 10 + (str[i++] - "0");
    decimals++;
  }
  x = x / 10 ** decimals;
  decimals = 0;
  i++;

  while (i < str.length && str[i] <= "9" && str[i] >= "0")
    y = y * 10 + (str[i++] - "0");
  ++i;

  while (i < str.length && str[i] <= "9" && str[i] >= "0") {
    y = y * 10 + (str[i++] - "0");
    decimals++;
  }
  y = y / 10 ** decimals;
  while (i < str.length && (str[i] >= "9" || str[i] <= "0") && str[i] !== "-")
    i++;
  let sign = 1;
  if (str[i] === "-") {
    sign = -1;
  }
  i++;
  while (i < str.length && str[i] <= "9" && str[i] >= "0")
    rotate = rotate * 10 + (str[i++] - "0");
  rotate *= sign;

  if (getRotate) return [x, y, rotate];
  return [x, y];
};
const circleSlideIn = (el, direction) => {
  if (el) {
    let transform = el.getAttribute("transform");
    let [fisnishValue, translateY] = getXY(transform, false);

    const distance = 120 * direction;

    let startPoint = fisnishValue - distance;

    el.setAttribute(
      "transform",
      `translate(${startPoint} ${translateY}) rotate(-90)`
    );

    const animation = setInterval(() => {
      if (
        distance > 0 ? startPoint <= fisnishValue : startPoint >= fisnishValue
      ) {
        startPoint += (distance / time) * 100;

        el.setAttribute(
          "transform",
          `translate(${startPoint} ${translateY}) rotate(-90)`
        );
      } else {
        clearInterval(animation);
      }
    }, time / 100);
  }
};
const rotate = (el) => {
  if (el) {
    let transform = el.getAttribute("transform");
    let [translateX, translateY, rotate] = getXY(transform, true);

    let current = rotate;

    const animation = setInterval(() => {
      if (current < rotate + 360) {
        current += 18;

        el.setAttribute(
          "transform",
          `translate(${translateX} ${translateY}) rotate(${current})`
        );
      } else {
        clearInterval(animation);
      }
    }, time / 100);
  }
};
const slideIn2 = (el, startCircles) => {
  if (el) {
    let width = el.getAttribute("width");
    let transform = el.getAttribute("transform");
    let [translateX, translateY] = getXY(transform, false);

    let current = 0;
    let fisnishValue = width;

    const animation = setInterval(() => {
      if (current < fisnishValue) {
        current += fisnishValue / 40;
        el.setAttribute("width", `${current}`);
        el.setAttribute(
          "transform",
          `translate(${translateX} ${
            translateY - parseFloat(width - current)
          }) rotate(-90)`
        );
      } else {
        el.setAttribute("width", `${fisnishValue}`);
        if (startCircles) {
          document.getElementById("Ellipse_197").style.opacity = "1";
          document.getElementById("Ellipse_199").style.opacity = "1";
          document.getElementById("Path_1294").style.opacity = "1";
          circleSlideIn(document.getElementById("Ellipse_197"), -1);
          circleSlideIn(document.getElementById("Ellipse_199"), 1);
          rotate(document.getElementById("Path_1294"));
        }
        clearInterval(animation);
      }
    }, time / 100);
  }
};
export function addAnim() {
  const firstImageSection = document.getElementById("firstImagesSection");
  const secondImageSection = document.getElementById("secondImagesSection");
  firstImageSection.style.opacity = "0";
  secondImageSection.style.opacity = "0";
  document.getElementById("Path_1294").style.opacity = "0";
  document.getElementById("Ellipse_197").style.opacity = "0";
  document.getElementById("Ellipse_199").style.opacity = "0";

  const firstAnimation = setInterval(() => {
    if (enterOnViewPort(firstImageSection, -250)) {
      firstImageSection.style.opacity = "1";
      slideIn2(document.getElementById("Rectangle_295"), true);
      slideIn(document.getElementById("Rectangle_293"));
      slideIn2(document.getElementById("Rectangle_296"), false);
      scaleFromLeftBottom(document.getElementById("Rectangle_292"));
      scaleFromLeftBottom(document.getElementById("Path_1293"));
      scaleFromLeftBottom(document.getElementById("Ellipse_202"));
      if (document.getElementById("img1")) {
        document.getElementById("img1").classList.add("fadeIn0_5");
      }
      if (document.getElementById("img2")) {
        document.getElementById("img2").classList.add("fadeIn0_5");
      }
      clearInterval(firstAnimation);
    }
  }, 0.05);
  const secondAnimation = setInterval(() => {
    if (enterOnViewPort(secondImageSection, -550)) {
      secondImageSection.style.opacity = "1";
      scaleFromLeftBottom(document.getElementById("Rectangle_297"), false);
      scaleFromLeftBottom(document.getElementById("Path_1297"));
      if (document.getElementById("img3")) {
        document.getElementById("img3").classList.add("fadeIn0_5");
      }
      if (document.getElementById("Path_1298")) {
        document.getElementById("Path_1298").classList.add("fadeIn0_5");
      }
      clearInterval(secondAnimation);
    }
  }, 0.05);
}
