const menuItems = [
  {
    id: "schools",
    link: "/schools/public",
    subMenuItems: [
      {
        id: "public",
        link: "/public",
      },
      {
        id: "private",
        link: "/private",
      },
      {
        id: "virtual",
        link: "/virtual",
      },
      {
        id: "chain",
        link: "/chain",
      },
    ],
  },
  {
    id: "parents",
    link: "/parents",
  },
  {
    id: "students",
    link: "/students",
  },
  {
    id: "institutions",
    subMenuItems: [
      {
        id: "cityHall",
        link: "/cityhall",
      },
      {
        id: "ministry",
        link: "/ministry",
      },
    ],
  },
 
  {
    id: "about",
    subMenuItems: [
      {
        id: "us",
        link: "",
      },
      {
        id: "media",
        link: "https://idei.adservio.ro/aparitii-media/",
        extern: true,
      },
      {
        id: "blog",
        link: "https://idei.adservio.ro/",
        extern: true,
      },
      {
        id: "csr",
        link: "/csr",
      }
    ],
  },
  {
    id: "contact",
    link: "/contact",
  },
  {
    id:'universities',
    link:'https://uni.adservio.ro',
    extern:true
  }
];

export default menuItems;
