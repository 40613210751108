import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./logo.css";

const Logo = ({ active }) => {
  const { i18n } = useTranslation("common");

  return (
    <Link
      to={`/${i18n.language}/`}
      className={active ? "logo logoActive" : "logo"}
    ></Link>
  );
};

export default Logo;
