import "./cityhall_benefits.css";
import chart_icon from "../../assets/schools/blueIcons/trendingup-bround.svg";
import lightning_icon from "../../assets/schools/blueIcons/zap-bround.svg";
import file_icon from "../../assets/schools/blueIcons/survey-bround.svg";
import camera_icon from "../../assets/schools/blueIcons/video-bround.svg";
import statistics_icon from "../../assets/schools/blueIcons/barchart2-bround.svg";
import { useTranslation } from "react-i18next";
const CityhallBenefits = () => 
{ 
  const { t } = useTranslation("common");
  return (
    <section className="cityhall_benefits">
      <h1>{t("institutions.cityhall.benefits.title")}</h1>
      <div className="cityhall_benefits_box">
        <h2>{t("institutions.cityhall.benefits.title2")}</h2>
        <div className="cityhall_benefits_wrapper">
          <div className="blue_icon_w_info">
            <img src={chart_icon} alt={chart_icon} />
            <p>{t("institutions.cityhall.benefits.blue_icons.r1c1")}</p>
          </div>
          <div className="blue_icon_w_info">
            <img src={lightning_icon} alt={lightning_icon} />
            <p>{t("institutions.cityhall.benefits.blue_icons.r1c2")}</p>
          </div>
          <div className="blue_icon_w_info">
            <img src={file_icon} alt={file_icon} />
            <p>{t("institutions.cityhall.benefits.blue_icons.r1c3")}</p>
          </div>
          <div className="blue_icon_w_info">
            <img src={camera_icon} alt={camera_icon} />
            <p>{t("institutions.cityhall.benefits.blue_icons.r2c1")}</p>
          </div>
          <div className="blue_icon_w_info">
            <img src={statistics_icon} alt={statistics_icon} />
            <p>{t("institutions.cityhall.benefits.blue_icons.r2c2")}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CityhallBenefits
