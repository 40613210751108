import React, { useEffect } from "react";

import "./RoCookiePolicy.css";

const RoCookiePolicy = () => {
  const currentHost = window.location.host;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://consent.cookiebot.com/c683ff1e-ea9a-458e-ae91-e2d33348dfdb/cd.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="comp-ro-cookie-policy">
      <h1>Politică de cookie-uri</h1>
      <p>
        Această politică reglementeaza utilizarea cookie-urilor si stabilește
        temeiul legal al companiei Adservio Social Inovation pentru utilizarea
        “cookie-urilor” și tehnologiilor similare pe website sau pe sisteme care
        au legatură cu website-ul.{" "}
        <u>
          <strong>
            <a href={`https://${currentHost}/`}>{currentHost}</a>
          </strong>
        </u>
        . Această politică de cookie-uri este valabilă începând cu data de
        01.05.2022.
      </p>
      <p>
        Modulele de cookie „esențiale” sunt plasate automat pe calculator,
        laptop sau orice dispozitiv ce poate accesa sau actiona pe site-ul
        companiei. Cookie-urile "non-esențiale" și alte tehnologii similare sunt
        plasate numai pe computerul sau pe dispozitivul utilizatorului dacă si-a
        dat acordul explicit. Informații adiționale despre diferența dintre
        cookie-urile esențiale și non esențiale, se găsesc în secțiunea de mai
        jos intitulată “ <u>Despre cookie-uri</u>”.
      </p>
      <p>
        Informații despre modul în care este oferit consimțământul pentru
        cookie-uri non-esențiale și alte tehnologii și despre modul cum se poate
        retrage acest consimțământ se găsesc in secțiunea de mai jos, intitulată
        “ <u>Cum se acceptă sau se resping cookie-urile</u>”.
      </p>
      <br />
      <br />
      <h3>Cuprins</h3>
      <ul className="cuprins">
        <li>
          <a href="#aboout_cookies">Despre Cookies</a>
        </li>
        <li>
          <a href="#types_cookies">Tipurile de cookie-uri utilizate</a>
        </li>
        <li>
          <a href="#essential_cookies">Cookie-uri esențiale</a>
        </li>
        <li>
          <a href="#non_essential_cookies">Cookie-uri non-esențiale</a>
        </li>
        <li>
          <a href="#web_beacon">Web beacon-uri</a>
        </li>
        <li>
          <a href="#facebook_pixel">Facebook Pixel</a>
        </li>
        <li>
          <a href="#how_reject">Cum se accepta sau resping cookie-urile</a>
        </li>
      </ul>
      <h2 id="aboout_cookies">Despre cookie-uri</h2>
      <h3>Ce sunt cookie-urile?</h3>
      <p>
        Fișierele cookie sunt fișiere text mici trimise de serverul unui site
        web către un navigator web (web browser: IE, Firefox, Opera), memorie
        RAM sau unitate de hard disk și apoi stocate acolo. Acestea pot fi
        utilizate cu scopuri diferite, cum ar fi: personalizarea site-ului web
        in funcție de nevoile unui anumit utilizator, ajutarea unui utilizator
        să navigheze pe site-ul web, îmbunătățirea calității site-ului web
        pentru utilizator sau stocarea preferințelor utilizatorului și
        informațiilor de conectare.
      </p>
      <h3>Cookie-uri esențiale și neesențiale</h3>
      <p>
        Cookie-urile pot fi clasificate drept "esențiale" sau "neesențiale".
      </p>
      <p>
        <u>Cookie-urile esențiale:</u>
        prezintă una dintre următoarele două caracteristici:
      </p>
      <ul>
        <li>
          sunt utilizate exclusiv pentru efectuarea sau facilitarea comunicării
          într-o rețea; sau
        </li>
        <li>
          sunt strict necesare pentru furnizarea unui serviciu online (de ex.
          site-ul nostru sau un serviciu de pe site-ul nostru) solicitat de
          către dvs.
        </li>
      </ul>
      <p>
        <u>Cookie-uri neesențiale: </u>
        Acestea nu se încadrează în definiția cookie-urilor esențiale deoarece
        nu sunt strict necesare pentru funcționarea unui site sau a serviciilor
        oferite pe acesta, precum cookie-urile utilizate pentru analizarea
        comportamentul pe un site web (cookie-uri „analitice”) sau cookie-urile
        utilizate pentru afișarea de reclame.
      </p>
      <h3>Cookie-uri de sesiune si persistente </h3>
      <p>
        Cookie-urile pot fi de două tipuri: „de sesiune” și „persistente”, în
        funcție de perioada de stocare după plasarea lor în browserul dvs.
      </p>
      <p>
        <u>Cookie-urile de sesiune:</u>
        rămân pe dipozitiv atâta timp cât navigatorul web este deschis. Acestea
        expiră când navigatorul web este inchis.
      </p>
      <p>
        <u>Cookie-urile persistente</u>: expiră la un punct fix în timp sau dacă
        sunt șterse manual din navigatorul web.
      </p>
      <h3>Cookie-urile noastre sau ale părților terțe</h3>
      <p>
        Cookie-urile pot fi clasificate în două grupuri: cele care aparțin
        site-ului vizitat sau cele care aparțin părților terțe.
      </p>
      <p>
        <u>Cookie-urile care aparțin site-ului nostru (primare)</u>: sunt
        cookie-urile plasate pe dispozitivul dvs. de către domeniul site-ului
        nostru.
      </p>
      <p>
        <u>Cookie-urile care aparțin terților:</u>
        sunt cookie-urile plasate pe dispozitivul dvs. de către domenii ale unor
        site-uri care aparțin unor părți terțe.
      </p>
      <p>
        Informații adiționale se găsesc prin accesarea următorului site
        web&nbsp;
        <u>
          <strong>
            <a href="http://www.allaboutcookies.org/">
              www.allaboutcookies.org
            </a>
          </strong>
        </u>
      </p>
      <h2 id="types_cookies">Tipuri de cookie-uri utilizate</h2>
      <p>Noi utilizăm următoarele cookie-uri pe site-ul nostru web: </p>
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/c683ff1e-ea9a-458e-ae91-e2d33348dfdb/cd.js"
        type="text/javascript"
        async
      ></script>

      <h2 id="essential_cookies">Cookie-uri esențiale</h2>
      <p>
        Acestea sunt cookie-urile strict necesare pentru funcționarea corecta a
        site-ului nostru sau pentru oferirea de servicii pe care le solicitați
        folosind website-ul nostru. Utilizăm următoarele cookie-uri esentiale pe
        website-ul nostru:
      </p>
      <p>
        - cookie-urile de sesiune primare utilizate pentru identificarea și
        autentificarea unui utilizator când se conecteaza la site, astfel încât
        să nu fie necesară introducerea în mod repetat a informațiilor de
        conectare. Aceste cookie-uri sunt prezentate in tabelul de mai sus.
      </p>
      <p>
        - Cookie-urile ale părților terțe persistente utilizate pentru
        detectarea acordului utilizatorului privind utilizarea cookie-urilor de
        pe site-ul nostru. Aceste cookie-uri sunt prezentate în tabelul de mai
        sus.
      </p>
      <p>
        Fundamentul juridic pentru prelucrare: prelucrarea informațiilor despre
        utilizatori conținute în sau obținute prin intermediul cookie-urilor
        esențiale se realizează în interese legitime [articolul 6 alineatul (1)
        litera (f) din Regulamentul General Privind Protecția Datelor (GDPR) cu
        caracter personal).
      </p>
      <p>
        Interesele legitime: asigurarea funcționării corecte a site-ului și
        serviciilor online solicitate.
      </p>
      <p>
        <u>Cum se renunța la cookie-urile esențiale</u>
      </p>
      <p>
        Cele mai multe tipuri de navigatoare web permit blocarea tuturor
        modulelor de tip cookie, inclusiv a cookie-urilor esențiale. De
        menționat faptul ca dacă sunt blocate toate cookie-urile, anumite
        componente si functii ale site-ului pot fi afectate.
      </p>
      <p>
        Cookie-urile existente în cadrul navigatorul web pot fi șterse, prin
        selectarea opțiunii de ștergere a cookie-urilor din opțiunea de ștergere
        a datele de navigare.
      </p>
      <p>
        Informații mai detaliate despre modul de acceptare și respingere a
        cookie-urilor, inclusiv instrucțiuni pentru anumite browsere, se afla în
        secțiunea de mai jos, intitulată{" "}
        <u>„Cum se acceptă sau se resping cookie-urile”</u>
      </p>
      <h2 id="non_essential_cookies">Cookie-uri neesentiale</h2>
      <p>
        Utilizăm următoarele tipuri de cookie-uri neesentiale pe website-ul
        nostru:
      </p>
      <p>
        <strong>• Cookie-uri funcționale</strong>
      </p>
      <p>
        <strong>• Cookie-uri analitice (sau de performanță)</strong>
      </p>
      <p>
        <strong>• Cookie-urile de targetare (sau publicitare)</strong>
      </p>
      <br />
      <h3>Cookie-uri funcționale</h3>
      <p>
        Acestea sunt cookie-uri concepute pentru îmbunătățirea funcționalitatii
        site-ului. Acestea nu sunt strict esențiale pentru funcționarea corectă
        a site-ul web sau pentru serviciile solicitate pe acesta, sau sunt​​
        cookie-uri care servesc scopuri neesentiale, în plus față de scopul
        esențial. Utilizăm următoarele cookie-uri funcționale pe site:
      </p>
      <p>
        - Cookie-urile părților terțe de sesiune sau persistente utilizate
        pentru a recunoaște un utilizator când acesta utilizează serviciul de
        chat live. Aceste cookie-uri sunt prezentate în tabelul de mai sus
      </p>
      <p>
        <u>Acceptarea sau respingerea cookie-urilor funcționale</u>
      </p>
      <p>
        Navigați spre secțiunea principală intitulată “Cum se acceptă sau se
        resping cookie-urile”.
      </p>
      <p>
        <u>
          Prelucrarea informațiilor personale despre utilizator, conținute sau
          obținute prin intermediul cookie-urilor funcționale
        </u>
      </p>
      <p>
        <strong>Fundamentul juridic pentru prelucrare</strong>: prelucrarea
        informațiilor despre utilizatori conținute în sau obținute prin
        intermediul cookie-urilor esențiale se realizează în interese legitime
        (Articolul 6 alineatul (1) litera (f) din Regulamentul General Privind
        Protecția Datelor cu caracter personal(GDPR)). Vom procesa datele
        personale doar dacă v-ați dat acordul să plasăm cookie-uri pe computerul
        sau dispozitivul dvs.
      </p>
      <p>
        <strong>Interesele legitime</strong>: îmbunătățirea calității și
        functionalitatii site-ului web și furnizarea sau îmbunătățirea
        serviciilor solicitate de pe site.
      </p>
      <h3>Cookie-urile analitice (sau de performanță)</h3>
      <p>
        Cookie-urile analitice (sau de performanță) urmăresc și colectează date
        despre ceea ce face un utilizator pe site-ul web accesat. Aceste
        cookie-uri nu sunt esențiale pentru buna funcționare a site-ului.
        Utilizăm următoarele cookie-uri analitice pe website-ul nostru:
      </p>
      <p>
        Utilizăm cookie-uri de tip Google Analytics pe site. Cookie-urile Google
        Analytics ajută la înțelegerea modului în care interacționați cu
        website-ul, inclusiv cum a intrat un utilizatorul pe site-ul web,
        paginile accesate, cât timp și ce obiecte au fost accesate, locație (pe
        baza adresei IP).
      </p>
      <p>
        Cookie-urile Google Analytics utilizate pe site sunt prezentate în
        tabelul de mai sus. Aceste cookie-uri sunt cookie-uri persistante.
        Aceste cookie-uri expiră după 2 ani.
      </p>
      <p>
        Informațiile colectate cu ajutorul modulelor cookie analitice sunt
        colectate în mod anonim.
      </p>
      <p>
        <u>Mai multe informatii</u>
      </p>
      <p>
        Cookie-urile Google Analytics sunt clasificate drept cookie-uri primare,
        deoarece sunt stabilite de domeniul site-ului, deși Google colectează și
        procesează informații prin utilizarea Google Analytics. Informatii mai
        multe despre modul în care Google gestionează informațiile colectate
        prin intermediul Google Analytics, se află în politica de
        confidențialitate Google Analytics, disponibilă aici:&nbsp;
        <a href="https://support.google.com/analytics/answer/6004245">
          https://support.google.com/analytics/answer/6004245
        </a>
      </p>
      <p>
        Informații despre modul în care Google utilizează date din cookie-urile
        pe care le utilizează, se gasesc accesând:&nbsp;
        <a href="https://www.google.com/policies/privacy/partners/">
          www.google.com/policies/privacy/partners/
        </a>
      </p>
      <p>
        <u>Acceptarea sau respingerea cookie-urilor analitice</u>
      </p>
      <p>
        Navigați spre secțiunea principală intitulată “Cum se acceptă sau se
        resping cookie-urile”.
      </p>
      <p>
        Renunțarea la monitorizarea oferita de Google Analytics pe toate
        site-urile web în general se face urmand instructiunile de la aceasta
        adresă:&nbsp;
        <a href="http://tools.google.com/dlpage/gaoptout">
          http://tools.google.com/dlpage/gaoptout
        </a>{" "}
      </p>
      <p>
        <u>
          Prelucrarea informațiilor despre utilizator conținute în sau obținute
          prin intermediul cookie-urilor analitice
        </u>
      </p>
      <p>
        <strong>Fundamentul juridic pentru prelucrare</strong>: prelucrarea
        informațiilor despre utilizatori conținute în sau obținute prin
        intermediul cookie-urilor analitice în interese legitime [articolul 6
        alineatul (1) litera (f) din Regulamentul General Privind Protecția
        Datelor cu caracter personal (GDPR).
      </p>
      <p>
        <strong>Interesele legitime</strong>: analiza modului în care persoanele
        utilizează site-ul web pentru îmbunătățirea site-ului și business-ului.
        Informații suplimentare despre modul în care sunt folosite informațiile
        obținute din utilizarea cookie-urilor analitice, inclusiv profilarea, se
        afla in secțiunea intitulată "Utilizarea sistemelor automate de luare a
        deciziilor și profilare" în Politica de Confidențialitate, si este
        disponibila aici:&nbsp;
        <a
          href={`https://${currentHost}/ro/p/privacy-policy`}
        >{`https://${currentHost}/ro/p/privacy-policy`}</a>
        .
      </p>
      <h3>Cookie-uri ale părților terțe</h3>
      <p>
        Terții utilizează cookie-uri pentru analizarea utilizarii de către
        utilizatori a site-ului nostru cu scopul afisarii de reclame (inclusiv
        reclame ale părților terțe) către utilizatori [și] [introduceți alte
        utilizări ale cookie-urilor de la terti, de ex. urmărirea conversiilor
        anunțurilor, cum ar fi Google AdWords]. Cookie-urile de la terti
        utilizate în cadrul site-ului nostru includ:
      </p>
      <p>
        - Cookie-urile Google AdWords urmăresc dacă ați accesat site-ul printr-o
        reclamă ce a fost plasată pe un motor de căutare sau în altă parte pe
        internet, precum o altă pagină web și urmăresc, de asemena, să afle și
        alte informații referitoare la modul în care utilizatorul a accesat
        website-ul precum locația de unde ați accesat website-ul, când a fost
        accesat site-ul, ora din zi ​​și dispozitivul utilizat. Google poate
        utiliza cookie-uri diferite pentru a urmări modul în care un utilizator
        a ajuns pe site, în funcție de reclama pe care utilizatorul a accesat-o
        și unde a fost publicată aceasta reclama.
      </p>
      <p>
        <u>Informații adiționale</u>
      </p>
      <p>
        Informații despre cookie-urile utilizate de Google în legătură cu cele
        de mai sus, se afla in secțiunea "Publicitate" si este disponibila la
        aceasta adresa:
        <u>
          <a href="https://www.google.com/policies/technologies/types/">
            https://www.google.com/policies/technologies/types/
          </a>
        </u>
      </p>
      <p>
        Informații despre modul în care Google utilizează date din cookie-uri în
        scopuri proprii, se afla la urmatoarea adresa{" "}
        <u>
          <a href="https://www.google.com/policies/privacy/partners/">
            www.google.com/policies/privacy/partners/
          </a>
        </u>
      </p>
      <p>
        <u>Aceptarea sau respingerea cookie-urilor terților</u>
      </p>
      <p>
        Navigați spre secțiunea principală intitulată “Cum se acceptă sau se
        resping cookie-urile”.
      </p>
      <p>
        <u>
          Prelucrarea datelor dvs. conținute in sau obținute prin intermediul
          cookie-urilor părților terțe
        </u>
      </p>
      <p>
        <strong>Fundamentul juridic pentru prelucrare</strong>: consimțământul
        (articolul 6 alineatul (1) litera (a) din Regulamentul general privind
        protecția datelor).
      </p>
      <p>
        <strong>Consimțământul</strong>: Vă oferiți consimțământul în ceea ce
        privește scopurile pentru care se prelucrează informațiile prin a da
        click / a accepta scopurile pentru care aceste cookie-uri publicitare
        sunt utilizate pe website-ul nostru.
      </p>

      <h2 id="web_beacon">Web Beacons</h2>
      <p>
        Noi și orice societăți de marketing pe care le folosim încorporăm, de
        asemenea, web beacon-uri în e-mailurile de marketing. Web Beacon-urile
        sunt fișiere imagine GIF mici, care permit urmărirea primirii
        e-mailurilor de marketing, cât de des sunt vizualizate anunțurile sau
        paginile site-ului Web, locația, adresa IP și informațiile navigatorului
        web. Web Beacon-urile sunt activate ori de câte ori este deschis un
        e-mail de marketing sau este accesata o pagină pe site-ul ce contine un
        Web Beacon. Web Beacon-urile transmit date când sunt vizualizate, dar nu
        sunt capabile să acceseze alte informatii de pe computer. Web
        Beacon-urile nu sunt stocate pe hard disk decât dacă sunt descarcate
        împreună cu imaginea GIF.
      </p>
      <p>
        Unele navigatoare (dar nu toate) permit restricționarea utilizării Web
        beacon-urilor fie prin împiedicarea trimiterii de informații către sursa
        acestora (de exemplu, atunci când este setat navigatorul sa blocheze
        cookie-urile și trackerele), fie prin neaccesarea imaginilor care le
        conțin (de exemplu, dacă este selectata setarea "Nu afișați imaginile
        (în e-mailuri)" pe serverul de e-mail).
      </p>
      <p>
        <u>Aceptarea sau respingerea web beacon-urilor</u>
      </p>
      <p>
        Navigați spre secțiunea principală intitulată “Cum se acceptă sau se
        resping cookie-urile”.
      </p>
      <p>
        <strong>Fundamentul juridic pentru prelucrare</strong>: prelucrarea
        datelor despre utilizatori conținute în sau obținute prin intermediul
        web beacon-urilor se realizează pe baza intereselor legitime [articolul
        6 alineatul (1) litera (f) din Regulamentul General Privind Protecția
        Datelor (GDPR) cu caracter personal].
      </p>
      <p>
        <strong>Interes legitim comercial</strong>: analiza eficacității
        campaniilor de marketing prin e-mail
      </p>
      <h2 id="facebook_pixel">Facebook Pixel</h2>
      <p>
        Website-ul nostru utilizeaza Facebook Pixel. Facebook Pixel este un cod
        care permite urmărirea și monitorizarea succesului anunțurilor afișate
        pe Facebook și îmbunătățirea eficienței acelor reclame prin
        înregistrarea de informații precum dispozitivul utilizat pentru
        accesarea site-ului și acțiunile efectuate folosind cookie-uri. Se poate
        de asemenea, să se utilizeze Facebook Pixel pentru crearea de reclame
        segmentate pe Facebook și pe site-ul nostru.
      </p>
      <p>
        Facebook combină datele colectate din utilizarea Facebook Pixel-ului de
        pe site cu date pe care le colectează din alte surse, în scopul de a
        îmbunătăți și segmenta reclamele afișate pe site sau prin intermediul
        serviciilor sale, pentru a-și îmbunătăți sistemele și pentru a oferi
        servici de măsurare a eficienței reclamelor. Informații mai multe despre
        modul în care Facebook gestionează informațiile pe care le colectează
        despre utilizatori și alte persoane se găsesc în politica lor de
        confidențialitate la adresa:
        <u>
          <a href="https://www.facebook.com/about/privacy">
            https://www.facebook.com/about/privacy
          </a>
        </u>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        <u>Aceptarea sau respingerea Facebook Pixel</u>
      </p>
      <p>
        Navigați spre secțiunea principală intitulată “Cum se acceptă sau se
        resping cookie-urile”.
      </p>
      <p>
        <strong>Fundamentul juridic pentru prelucrare</strong>: prelucrarea
        informațiilor despre utilizatori conținute în sau obținute prin
        intermediul Facebook Pixel se realizează pe baza intereselor legitime
        [articolul 6 alineatul (1) litera (f) din Regulamentul General Privind
        Protecția Datelor cu caracter personal (GDPR)).
      </p>
      <p>
        <strong>Interes legitim</strong>: analizarea eficacității anunțurilor de
        pe Facebook. Pentru informații adițonale despre modul în care sunt
        utilizate informațiile colectate prin inermediul Facebook Pixel[,
        inclusiv luarea deciziilor automate și profilarea, se afla in secțiunea
        intitulată{" "}
        <u>Utilizarea sistemelor automate de luare a deciziilor și profilare</u>
        din politica de confidențialitate, sunt disponibile aici:https:
        <u>
          <a
            href={`https://${currentHost}/ro/p/privacy-policy`}
          >{`https://${currentHost}/ro/p/privacy-policy`}</a>
        </u>
      </p>
      <h2 id="how_reject">Cum se acceptă sau se resping cookie-urile</h2>
      <p>
        Există o serie de moduri diferite prin care se pot accepta sau respinge
        unele sau toate tipurile de cookie-uri și tehnologii similare. Unele
        dintre principalele metode de a face acest lucru sunt descrise mai jos:
      </p>
      <p>
        Sunteți bineveniți să blocați utilizarea unora sau a tuturor
        cookie-urilor care sunt folosite pe website. Cu toate acestea, vă rugăm
        să conștientizați faptul că acest lucru poate afecta website-ul și
        funcționalitatea sa în mod parțial sau total.
      </p>
      <p>
        De asemenea, trebuie să fiți conștient că ștergerea tuturor
        cookie-urilor din navigatorul web va șterge, de asemenea, toate modulele
        cookie care stochează preferințele dvs., de exemplu dacă ați acceptat
        sau nu utilizarea cookie-urilor pe un website sau orice cookie-uri care
        blocheaza alte cookie-uri.
      </p>
      <p>
        Informații detaliate despre cookie-uri și setările browserului se pot
        gasi prin accesarea următorului link:&nbsp;
        <u>
          <a href="http://www.allaboutcookies.org">
            http://www.allaboutcookies.org/
          </a>
        </u>
      </p>
      <h3>Acceptarea sau respingerea cookie-urilor</h3>
      <p>
        <u>Setările browserului</u>
      </p>
      <p>
        Puteți accepta sau respinge unele sau toate modulele cookie (de exemplu,
        blocând toate modulele cookie ale părților terțe) ajustând setările
        browserului. Modul de efectuarea a acestui lucru se gasește accesând
        urmatoarele link-uri pentru cele mai populare navigatoare web:
      </p>
      <p>
        - Google Chrome:&nbsp;
        <u>
          <a href="https://support.google.com/chrome/answer/95647">
            https://support.google.com/chrome/answer/95647
          </a>
        </u>
      </p>
      <p>
        -MozillaFirefox:&nbsp;
        <u>
          <a href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox?redirectlocale=ro-US&amp;redirectslug=Clear+Recent+History">
            https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox?redirectlocale=ro-US&amp;redirectslug=Clear+Recent+History
          </a>
        </u>
      </p>
      <p>
        - Microsoft Internet Explorer:&nbsp;
        <u>
          <a href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer">
            https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer
          </a>
        </u>
      </p>
      <p>
        - Apple Safari:&nbsp;
        <u>
          <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">
            https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac
          </a>
        </u>
      </p>
      <p>
        Unele browsere, cum ar fi Chrome și Firefox, permit modificarea
        setărilor pentru navigarea în modul "incognito", limitând cantitatea de
        date introduse pe dispozitiv și ștergând automat toate modulele cookie
        persistente introduse pe dispozitiv când sesiunea de navigare se
        termina. Există, de asemenea, numeroase aplicații ale unor parți terțe
        care se pot adăuga în browser pentru a bloca sau administra
        cookie-urile.
      </p>
      <h3>Cookie-urile existente</h3>
      <p>
        Pentru a șterge cookie-urile care au fost plasate anterior în browser,
        trebuie selectata opțiunea de ștergere a istoricului navigării
        asigurandu-vă că opțiunea de ștergere sau de ștergere a cookie-urilor
        este activată atunci când se face acest lucru.
      </p>
      <p>
        <u>Google Adsettings</u>
      </p>
      <p>
        Gestionarea anunțurilor personalizate se face accesând&nbsp;
        <u>
          <a href="https://adssettings.google.com">
            https://adssettings.google.com/
          </a>
        </u>
        și prin:
      </p>
      <p>
        - demarcarea opțiunii "Personalizare anunțuri" (adică prin asigurarea că
        acel comutator din partea de sus a paginii este setat la stânga/gri și
        nu la dreapta/albastru).
      </p>
      <p>
        Alternativ, se poate instala un plugin gratuit pentru browser
        aici:&nbsp;
        <u>
          <a href="https://support.google.com/ads/answer/7395996">
            https://support.google.com/ads/answer/7395996
          </a>
        </u>
      </p>
      <p>
        <u>Extensie a browserului pentru excluderea Google Analytics</u>
      </p>
      <p>
        Puteți renunța la Google Analytics instalând extensia pentru browser
        care este disponibila aici:&nbsp;
        <u>
          <a href="http://tools.google.com/dlpage/gaoptout">
            http://tools.google.com/dlpage/gaoptout
          </a>
        </u>
      </p>
      <h3>Web Beacon-uri</h3>
      <p>
        Puteți permite utilizarea de Web Beacon-uri în e-mailurile noastre de
        marketing prin bifarea casutei relevante în momentul abonarii la
        newsletter.
      </p>
      <p>
        Puteți renunța la utilizarea Web Beacon-urilor în e-mailurile noastre de
        marketing prin accesarea url-ului de dezabonare prezent în fiecare
        email.
      </p>
      <h3>Facebook Pixel</h3>
      <p>
        Puteți bloca Facebook Pixel utilizând instrumentul nostru de control al
        cookie-urilor.
      </p>
      <p>
        <u>Deconectarea de la Facebook</u>
      </p>
      <p>
        Exista posibilitatea instalării unei extensii de browser numită
        „Disconnect Facebook pixel and FB tracking”. Acest lucru va împiedica
        Facebook să vă urmărească comportamentul pe site-urile web ale unor
        părți terțe. Acestea se pot instala accesând:
      </p>
      <ul>
        <li>
          Pentru Chrome:&nbsp;
          <u>
            <a href="https://www.facebook.com/business/help/282415328961648">
              https://www.facebook.com/business/help/282415328961648
            </a>
          </u>
        </li>
        <li>
          Pentru Firefox:&nbsp;
          <u>
            <a href="https://addons.mozilla.org/en-GB/firefox/addon/facebook-disconnect/">
              https://addons.mozilla.org/en-GB/firefox/addon/facebook-disconnect/
            </a>
          </u>
        </li>
      </ul>
      <p>Instrumentul European de publicitate digitală interactivă</p>
      <p>
        Se poate renunța la Facebook și la alte companii care fac parte din
        Digital Advertising Alliance să afișeze anunțuri bazate pe interes,
        vizitând&nbsp;
        <u>
          <a href="http://www.youronlinechoices.com/">
            http://www.youronlinechoices.com
          </a>
        </u>
        , selectând țara dvs., selectând "Alegerile dvs. de anunțuri", apoi
        găsind Facebook (și orice alte companii pe care doriți să le blocați) și
        selectând opțiunea "Oprit".
      </p>
    </div>
  );
};

export default RoCookiePolicy;
