import React from "react";

import "./RoPrivacyPolicy.css";

const RoPrivacyPolicy = () => {
  const currentHost = window.location.host;

  return (
    <div className="comp-ro-privacy-policy">
      <h1 align="center">
        <strong>Politica de protecţie a datelor cu caracter personal</strong>
      </h1>
      <p>
        Protecția datelor dumneavoastră cu caracter personal este importantă
        pentru noi, prin urmare, acordăm o atenție deosebită protejării vieții
        private a vizitatorilor care accesează site-ul &nbsp;
        <strong>
          <a href={`https://${currentHost}/`}>{`https://${currentHost}`}</a>
        </strong>
        , numit in continuare <strong>Site-ul</strong>, precum și persoanelor
        care utilizează platforma educaţională ADSERVIO denumită în
        continuare&nbsp;
        <strong>PLATFORMA ADSERVIO</strong>.
      </p>
      <p>
        Începând cu 25 mai 2018 este aplicabil Regulamentul (UE) 2016/679 al
        Parlamentului European și al Consiliului din data de 27 aprilie 2016
        privind protecția persoanelor fizice în ceea ce privește prelucrarea
        datelor cu caracter personal și libera circulație a acestor date,
        denumit în continuare “<strong>GDPR”</strong>.
      </p>
      <p>
        Datele cu caracter personal sunt acele date care identifică direct o
        persoană fizică precum nume, prenume, adresă de domiciliu, adresă e-mail
        sau număr de telefon dar și orice alte date care permit identificarea
        indirectă a acesteia, cum ar fi, în anumite cazuri, numărul matricol al
        elevilor.
      </p>
      <p>
        GDPR este aplicabil oricărei entități juridice care procesează datele
        personale în activitatea lor.
      </p>
      <p>
        Vă rugăm să acordați o atenție deosebită lecturării următoarei Politici
        pentru a înțelege modul în care vor fi tratate datele dumneavoastră cu
        caracter personal.
      </p>
      <p>
        Prin prezenta Politică, vă informăm cu privire la practicile noastre
        referitoare la aplicarea prevederilor GDPR dar şi cu privire la natura
        datelor cu caracter personal pe care le prelucrăm pe Site şi pe
        PLATFORMA ADSERVIO, la scopurile şi temeiurile prelucrării, la
        drepturile de care beneficiază persoanele vizate.
      </p>
      <p>
        <strong>1. DEFINIȚII:</strong>
      </p>
      <ul>
        <li>
          <strong>ANSPDCP</strong> reprezintă Autoritatea Națională de
          Supraveghere a Prelucrării Datelor cu Caracter Personal;
        </li>
        <li>
          <strong>"date cu caracter personal"</strong> înseamnă orice informație
          privind o persoană fizică identificată sau identificabilă ("persoană
          vizată"); o persoană fizică identificabilă este o persoană care poate
          fi identificată, direct sau indirect, în special prin referire la un
          element de identificare, cum ar fi un nume, un număr de identificare,
          date de localizare, un identificator online, sau la unul sau mai multe
          elemente specifice, proprii identității sale fizice, fiziologice,
          genetice, psihice, economice, culturale sau sociale;
        </li>
        <li>
          <strong>"prelucrare"</strong> înseamnă orice operațiune sau set de
          operațiuni efectuate asupra datelor cu caracter personal sau asupra
          seturilor de date cu caracter personal, cu sau fară utilizarea de
          mijloace automatizate, cum ar fi colectarea, înregistrarea,
          organizarea, structurarea, stocarea, adaptarea sau modificarea,
          extragerea, consultarea, utilizarea, divulgarea prin transmitere,
          diseminarea sau punerea la dispoziție în orice alt mod, alinierea sau
          combinarea, restrictionarea, ștergerea sau distrugerea;
        </li>
        <li>
          <strong>"restricționarea prelucrării"</strong> înseamnă marcarea
          datelor cu caracter personal stocate cu scopul de a limita prelucrarea
          viitoare a acestora;
        </li>
        <li>
          <strong>"operator"</strong> înseamnă persoana fizică sau juridică,
          autoritatea publică, agenția sau alt organism care, singur sau
          împreună cu altele, stabilește scopurile și mijloacele de prelucrare a
          datelor cu caracter personal; atunci când scopurile și mijloacele
          prelucrării sunt stabilite prin dreptul Uniunii sau dreptul intern,
          operatorul sau criteriile specifice pentru desemnarea acestuia pot fi
          prevăzute în dreptul Uniunii sau în dreptul intern;
        </li>
        <li>
          <strong>"persoana împuternicită de operator"</strong> înseamnă
          persoana fizică sau juridică, autoritatea publică, agenția sau alt
          organism care prelucrează datele cu caracter personal în numele
          operatorului;
        </li>
        <li>
          <strong>"destinatar"</strong> înseamnă persoana fizică sau juridică,
          autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt
          divulgate datele cu caracter personal, indiferent dacă este sau nu o
          parte terță. Cu toate acestea, autoritățile publice cărora li se pot
          comunica date cu caracter personal în cadrul unei anumite anchete în
          conformitate cu dreptul Uniunii sau cu dreptul intern nu sunt
          considerate destinatari; prelucrarea acestor date de către
          autoritățile publice respective respectă normele aplicabile în materie
          de protecție a datelor, în conformitate cu scopurile prelucrării;
        </li>
        <li>
          <strong>"consimțământ"</strong> al persoanei vizate înseamnă orice
          manifestare de voință liberă, specifică, informată și lipsita de
          ambiguitate a persoanei vizate prin care aceasta acceptă, printr-o
          declarație sau printr-o acțiune fără echivoc, că datele cu caracter
          personal care o privesc să fie prelucrate.
        </li>
      </ul>
      <p>
        <strong>2</strong> <strong>. CINE SUNTEM</strong>
      </p>
      <p>
        Suntem <strong>Adservio Social Inovation S.R.L.</strong> cu sediul
        social în Municipiul Iași, Strada Bulevardul Socola, Numărul 3, Bloc D2,
        Scara B, etaj 4, Apartament 19, Județul Iași, înregistrată la Oficiul
        Registrului Comertului sub nr. J22/1482/2009, având Cod Unic de
        Înregistrare RO 26033834, denumit în continuare ADSERVIO.
      </p>
      <p>
        ADSERVIO deţine calitatea de autor, proprietar și administrator al
        Site-ului{" "}
        <a href={`https://${currentHost}/`}>{`https://${currentHost}`}</a> şi al
        PLATFORMEI ADSERVIO astfel încât toate informațiile cuprinse în această
        Politică au legătură și sunt valabile în raport cu activitatea pe Site
        şi pe PLATFORMA ADSERVIO.
      </p>
      <p>
        <strong>
          3. IDENTITATEA ȘI DATELE DE CONTACT ALE PERSOANEI RESPONSABILE CU
          PROTECȚIA DATELOR (RPD) SAU (ÎN ENGLEZĂ DPO)
        </strong>
      </p>
      <p>
        ADSERVIO a desemnat un responsabil cu protecția datelor („RPD”), care
        are obligația de a verifica respectarea prevederilor GDPR în
        operațiunile de prelucrare de date efectuate de către ADSERVIO și de a
        reprezenta ADSERVIO în relația cu persoanele vizate și cu ANSPDCP.
      </p>
      <p>
        Persoanele vizate au posibilitatea de a se adresa în orice moment, în
        mod direct, RPD la urmatoarea adresă de mail:&nbsp;
        <a href={`mailto:dpo@${currentHost}`}>dpo@adservio.ro</a>.
      </p>
      <p>
        <strong>
          4. PRINCIPII PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL
        </strong>
      </p>
      <p>
        ADSERVIO se obpgă să respecte principiile de protecție a datelor cu
        caracter personal prevăzute de GDPR, pentru a se asigura că toate datele
        sunt:
      </p>
      <ul className="check-list">
        <li>Prelucrate în mod corect, legal și transparent; </li>
        <li>&nbsp;Colectate în scopuri specificate, explicite și legitime; </li>
        <li>
          Adecvate, relevante și limitate în raport cu scopurile pentru care
          sunt prelucrate;
        </li>
        <li>Corecte și actualizate; </li>
        <li>
          Păstrate într-o formă care nu permite identificarea persoanelor vizate
          mai mult timp decât este necesar în raport de scopul prelucrării;
        </li>
        <li>
          Procesate în conformitate cu drepturile persoanei vizate, într-un mod
          care să asigure o securitate adecvată a prelucrării, astfel încât
          datele să fie integre, confidențiale și disponibile.
        </li>
      </ul>
      <p>
        <strong>5. CE TIPURI DE DATE CU CARACTER PERSONAL COLECTĂM</strong>
      </p>
      <p>
        Vă infomăm că prelucrăm următoarele date cu caracter personal prin
        intermediul formularelor existente pe Site:
      </p>
      <ol className="check-box">
        <li>
          În Formularul “<em>Solicită prezentare</em>”: nume prenume, adresă
          e-mail, număr de telefon, organizaţia pe care o reprezentaţi, rolul în
          organizaţie, localitatea şi judeţul. Datele ne sunt necesare pentru a
          vă putea înscrie la acest eveniment şi le prelucrăm dacă vă oferiți
          consimțământul în acest sens.
        </li>
        <li>
          În "<em>Secţiunea CARIERE</em>”: datele incluse în CV-ul pe care
          alegeţi să îl transmiteţi la adresa
          <strong>jobdeviitor@adservio.ro</strong> indicată în secţiunea CARIERE
          a Site-ului. Datele ne sunt necesare pentru recrutarea dumneavoastră
          în echipa ADSERVIO şi le prelucrăm dacă ne oferiți consimțământul în
          acest sens.
        </li>
        <li>
          În secţiunea “<em>Adaugă un comentariu</em>”: nume, adresă e-mail
          introduse de dvs. alături de comentariul dorit. Datele ne sunt
          necesare pentru a primi comentariul dumneavoastră şi le prelucrăm dacă
          ne oferiți consimțământul în acest sens.
        </li>
        <li>
          În secţiunea “<em>Află toate noutățile Adservio</em>”: adresa de
          e-mail pe care ne-o puneţi la dispoziţie şi pe care o prelucrăm dacă
          ne oferiți consimțământul în acest sens.
        </li>
        <li>
          În secţiunea “<em>CONTACTEAZĂ-NE</em>”: nume, prenume, scoală,
          telefon, adresă e- mail. Datele ne sunt necesare pentru a vă contacta
          cât mai curând posibil (maxim 24 de ore) urmare a solicitării
          dumneavoastră.
        </li>
        <li>
          Postăm pe Site mărturiile dvs. cu privire la PLATFORMA ADSERVIO care
          pot conține informații personale, cum ar fi numele, fotografia,
          funcţia și/sau un videoclip al persoanei din mărturie. Obținem
          consimțământul persoanei în prealabil pentru a ne asigura că avem
          permisiunea de a posta acest conținut în mod public. Pentru a solicita
          eliminarea informațiilor dumneavoastră personale din mărturii vă rugăm
          să ne contactați la&nbsp;
          <a href={`https://${currentHost}/`}>dpo@adservio.ro</a>.
        </li>
      </ol>
      <p>
        ADSERVIO, poate primi, de asemenea, și înregistra, pe serverele sale
        informațiile referitoare la adresa IP și informațiile cookie ale
        site-ului solicitat. Aveți opțiunea de a seta browserul de internet
        astfel încât acesta să respingă anumite cookies. În acest caz, va exista
        însă un impact negativ asupra navigării dvs. pe Sit. Politica Adservio
        referitoare la cookie-uri poate fi consultată&nbsp;
        <a href={`https://${currentHost}/ro/p/cookies`}>aici</a>.
      </p>
      <p>
        Colectăm date cu caracter personal utilizatorilor PLATFORMEI ADSERVIO
        pentru crearea conturilor de utilizator și pentru ca persoanele vizate
        să aiba acces la informațiile oferite de PLATFORMA ADSERVIO.
      </p>
      <ol className="check-box">
        <li>
          Nume, prenume, specializare, adresa e-mail, număr telefon, imagine,
          înregistrare video aparţinând cadrelor didactice;
        </li>
        <li>
          Nume, prenume, clasa la care este înscris, adresa e-mail, număr
          telefon, imagine, înregistrare video aparţinând elevilor;
        </li>
        <li>
          Nume, prenume, adresa e-mail, număr telefon aparţinând părinţilor
          elevilor;
        </li>
        <li>
          Nume, prenume, adresa e-mail, număr telefon aparţinând Directorilor
          unităţilor de învăţământ cu care ADSERVIO derulează o relaţie
          contractuală.
        </li>
      </ol>
      <p>
        Niciun profil complet al copilului nu este disponibil sau vizibil pentru
        alți părinți prin PLATFORMA ADSERVIO.
      </p>
      <p>
        ADSERVIO poate accesa datele, atunci cand este necesar pentru a oferi
        suport si ajutor in utilizarea PLATFORMEI ADSERVIO. Toate datele despre
        părinti, profesori, copii şi/sau note, absente, medii sunt introduse in
        PLATFORMA ADSERVIO de către persoane numite de unitatea şcolară cu care
        ADSERVIO are o relaţie contractuală.
      </p>
      <p>
        <strong>
          6. SCOPUL PENTRU CARE PRELUCRĂM DATELE CU CARACTER PERSONAL
        </strong>
      </p>
      <p> Solicităm și prelucrăm date cu caracter personal pentru: </p>
      <ol className="check-box">
        <li>
          A face posibilă înscrierea la o prezentare demo, odată ce v-aţi
          exprimat consimţământul prin formularul “Solicită prezentare”;
        </li>
        <li>
          A vă recruta în echipa ADSERVIO, odată ce v-aţi exprimat
          consimţământul prin transmiterea CV-ului la adresa
          <strong>jobdeviitor@adservio.ro</strong>;
        </li>
        <li>
          A primi şi analiza comentariul dvs. odată ce v-aţi exprimat
          consimţământul prin formularul transmis in secţiunea “Adaugă un
          comentariu”:
        </li>
        <li>
          A vă transmite newslettere, invitații, materiale legate de serviciile
          noastre și informații legate de evenimentele sau programele pe care le
          organizăm sau pe care le promovăm odată ce v-aţi exprimat
          consimţământu prin formularul “Află toate noutățile Adservio”;
        </li>
        <li>
          A vă contacta odată ce v-aţi exprimat consimţământul prin formularul
          transmis in secţiunea “CONTACTEAZĂ-NE;
        </li>
        <li>
          A face posibilă crearea contului dumneavoastră pe PLATFORMA ADSERVIO;
        </li>
        <li> A asigura buna funcţionare a PLATFORMEI ADSERVIO; </li>
        <li>
          Pentru a preveni spam-ul, frauda și abuzul și pentru a ajuta la
          recuperarea contului, a numelui de utilizator al contului prin SMS;
        </li>
      </ol>
      <p>
        <strong>7.TEMEIURILE PRELUCRĂRII DE DATE CU CARACTER PERSONAL</strong>
      </p>
      <p>
        ADSERVIO prelucrează datele cu caracter personal de pe Site şi din
        PLATFORMA ADSERVIO:
      </p>
      <ol className="check-box">
        <li>
          în temeiul consimţământului acordat de persoanele ce ne încredinţează
          datele ((art. 6 alin. 1 teza 1 lit. a GDPR
        </li>
        <li>
          pentru executarea unui contract la care sunteți parte (art. 6 alin. 1
          teza 1 lit. b GDPR)
        </li>
        <li>
          în vederea îndeplinirii unei obligații legale care ne revine (art. 6
          alin. 1 teza 1 lit. c GDPR)
        </li>
        <li>
          conform interesului legitim al ADSERVIO (art. 6 alin. 1 teza 1 lit. f
          GDPR).
        </li>
      </ol>
      <p>
        <strong>8. TRANSFERURILE DE DATE CU CARACTER PERSONAL </strong>
      </p>
      <p>
        Vă vom dezvălui datele dvs. personale acolo unde este necesar, de
        exemplu prin lege sau dacă considerăm că o astfel de acțiune este
        necesară pentru a ne conforma legii sau pentru a proteja securitatea sau
        integritatea Site-ului şi a PLATFORMEI ADSERVIO.
      </p>
      <p>
        De asemenea, există situații în care datele sunt trimise către
        autoritățile și instituțiile competente, avocați.
      </p>
      <p>
        Ori de câte ori folosim subcontractanți, acționând fie ca operator de
        date, fie ca persoană împuternicită, ne asigurăm că sunt îndeplinite
        cerințele stabilite de GDPR și că datele dvs. personale sunt procesate
        într-un mod adecvat.
      </p>
      <p>
        În principiu, datele dumneavoastră cu caracter personal nu vor fi
        transmise către o țară terță sau către o organizație internațională.
      </p>
      <p>
        <strong>9. CÂT TIMP PĂSTRĂM DATELE CU CARACTER PERSONAL</strong>
      </p>
      <p>
        Păstrăm datele cu caracter personal furnizate de dumneavoastră pentru o
        perioadă rezonabilă de timp, atât cât este necesar pentru a îndeplini
        scopurile descrise mai sus cât și pentru a ne îndeplini obligațiile
        legale cărora ne supunem. Dacă datele cu caracter personal nu ne mai
        sunt necesare și folositoare acestea se șterg.
      </p>
      <p>
        Motivele pentru care păstrăm datele cu caracter personal au la bază
        respectarea obligațiilor legale în conformitate cu GDPR și pentru a ne
        apăra drepturile şi interesele în cadrul eventualelor acțiuni în fața
        instanțelor competente.
      </p>
      <p>
        <strong>10. DEZVALUIREA DATELOR</strong>
      </p>
      <p>
        În situația în care suntem obligați să dezvăluim datele dumneavoastră cu
        caracter personal printr-un ordin judecătoresc sau pentru a respecta
        alte cerinţe legale, printr-o notificare vă vom anunța înainte de a
        furniza aceste date exceptând cazul în care este interzisă această
        dezvaluire. Nu vom vinde, dezvălui, distribui datele dumneavoastră cu
        caracter personal fără a obține acordul și permisiunea dumneavoastră.
      </p>
      <p>
        <strong>
          11. CUM ASIGURĂM SECURITATEA DATELOR CU CARACTER PERSONAL
        </strong>
      </p>
      <p>
        Ne angajăm să adoptăm măsuri tehnice și organizatorice adecvate, conform
        standardelor industriei. PLATFORMA ADSERVIO este aliniată la cerințele
        GDPR și folosește tehnologie de criptare și securizare a datelor cu
        caracter personal.
      </p>
      <p>
        ADSERVIO nu va dezvălui datele cu caracter personal colectate prin
        intermediul PLATFORMEI ADSERVIO cu excepția angajaților autorizați ai
        ADSERVIO și contractorilor/partenerilor ADSERVIO ce au calitatea de
        persoane împuternicite.
      </p>
      <p>
        ADSERVIO, împreună cu colaboratorii săi, și-a asumat responsabilitatea
        implementării unor măsuri tehnice și organizatorice corespunzătoare
        privind protecția datelor cu caracter personal, respectiv pentru
        protecția împotriva accesului neautorizat, a utilizării, alterării sau
        distrugerii datelor cu caracter personal conform prevederilor GDPR, după
        cum urmează:
      </p>
      <ol start="1" type="1">
        <li>
          Utilizatorii care au acces la baza de date a informațiilor cu caracter
          personal sunt doar cei care și-au creat cont pe PLATFORMA ADSERVIO,
          fiecare dintre aceștia beneficiind de funcţiile PLATFORMEI ADSERVIO cu
          propriul nume de cont și parola.
        </li>
        <li>
          Toți angajații, colaboratorii și furnizorii de servicii ai ADSERVIO,
          care intră în contact cu datele cu caracter personal, trebuie să
          acționeze în conformitate cu principiile politicilor și procedurilor
          de protecţie și de securitate a datelor cu caracter personal semnând
          declarații și acorduri de confidențialitate cu privire la aceste date.
        </li>
        <li>
          Computerele de la care se accesează baza de date sunt parolate, au
          implementate soluții de protecție antivirus, antispam și firewall
          updatate la zi.
        </li>
      </ol>
      <p>
        <strong>12. LOCAȚIA DATELOR CU CARACTER PERSONAL</strong>
      </p>
      <p>
        Datele cu caracter personal sunt stocate în cloud, și în format fizic,
        pe teritoriul României, în deplină concordanță cu cerințele legale și în
        condiții de maximă siguranță și protecție.
      </p>
      <strong>
        <h5>
          13. CE DREPTURI AVEŢI ÎN LEGĂTURĂ CU DATELE CU CARACTER PERSONAL
        </h5>
      </strong>
      <p>
        Vă asiguram ca vă respectăm drepturile stabilite prin legislația în
        vigoare.
      </p>
      <ol className="check-box">
        <li>
          <strong>Dreptul de acces</strong> înseamnă dreptul persoanei vizate de
          a obține o confirmare din partea operatorului că prelucrează sau nu
          datele cu caracter personal care il privesc și, în caz afirmativ,
          acces la datele respective și la informații privind modalitatea în
          care sunt prelucrate datele.
        </li>
        <li>
          <strong>Dreptul la portabilitatea datelor</strong> se referă la
          dreptul de a primi datele personale într-un format structurat,
          utilizat în mod curent și care poate fi citit automat și la dreptul că
          aceste date să fie transmise direct altui operator, dacă acest lucru
          este fezabil din punct de vedere tehnic.
        </li>
        <li>
          <strong>Dreptul la opoziție"</strong> vizează dreptul persoanei vizate
          de a se opune prelucrării datelor personale atunci când prelucrarea
          este necesară pentru indeplinirea unei sarcini care servește unui
          interes public sau când are în vedere un interes legitim al
          operatorului. Atunci când prelucrarea datelor cu caracter personal are
          drept scop marketingul direct, persoana vizată are cu precadere
          dreptul de a se opune prelucrării în orice moment.
        </li>
        <li>
          <strong>Dreptul la rectificare</strong> se referă la corectarea, fără
          întârzieri nejustificate, a datelor cu caracter personal inexacte
          stocate. Rectificarea trebuie comunicată fiecarui destinatar la care
          au fost transmise datele, cu excepția cazului în care acest lucru se
          dovedește imposibil sau presupune eforturi disproporționate
          (demonstrabile).
        </li>
        <li>
          ☑
          <strong>
            Dreptul la ștergerea datelor ("dreptul de a fi uitat")
          </strong>
          înseamnă dreptul persoanei vizate de a solicita să îi fie șterse
          datele cu caracter personal, fără întârzieri nejustificate, în cazul
          în care se aplică unul dintre următoarele motive: acestea nu mai sunt
          necesare pentru îndeplinirea scopurilor pentru care au fost colectate
          sau prelucrate; își retrage consimțământul și nu există niciun alt
          temei juridic pentru prelucrare; se opune prelucrării și nu există
          motive legitime care să prevaleze; datele cu caracter personal au fost
          prelucrate ilegal; datele cu caracter personal trebuie șterse pentru
          respectarea unei obligații legale; datele cu caracter personal au fost
          colectate în legătură cu oferirea de servicii ale societății
          informaționale.
        </li>
        <li>
          <strong>Dreptul la restricționarea prelucrării </strong> poate fi
          exercitat în cazul în care persoana contestă exactitatea datelor, pe o
          perioadă care permite verificarea corectitudinii datelor; prelucrarea
          este ilegală, iar persoana se opune ștergerii datelor cu caracter
          personal, solicitând în schimb restricționarea; în cazul în care
          operatorul nu mai are nevoie de datele cu caracter personal în scopul
          prelucrării, dar persoana i le solicită pentru constatarea,
          exercitarea sau apărarea unui drept în instanță; în cazul în care
          persoana s-a opus prelucrării pentru intervalul de timp în care se
          verifică dacă drepturile legitime ale operatorului prevalează asupra
          celor ale persoanei respective.
        </li>
      </ol>
      <p>
        <strong>
          14. ACTUALIZAREA POLITICII DE PROTECȚIE ȘI PRELUCRARE A DATELOR CU
          CARACTER PERSONAL
        </strong>
      </p>
      <p>
        Avem dreptul de a modifica, completa Politica de protecţie a datelor cu
        caracter personal oricând, afișând Politica de protectie a datelor
        modificată.
      </p>
      <p>
        <strong>
          15. DATELE DE CONTACT PENTRU AUTORITATEA NAȚIONALĂ PENTRU
          SUPRAVEGHEREA PRELUCRĂRII DATELOR CU CARACTER PERSONAL (ANSPDCP)
        </strong>
      </p>
      <p>
        Adresa: B-dul G-ral Gheorghe Magheru 28-30, Sector 1, cod postal 010336,
        București, România
      </p>
      <p>
        Mail:&nbsp;
        <a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a>
      </p>
      <p>
        Tel: <a href="tel:0318059211">+0318.059.211</a>;
        <a href="tel:0318059212">+0318.059.212</a>
      </p>
    </div>
  );
};

export default RoPrivacyPolicy;
