import React from 'react'

import adsLogo from "../../../assets/csr/adservioLogo.svg"
import microsoftLogo from "../../../assets/logos/Parteneri & Certificări/logo-Microsoft.svg"
import symbolMS from '../../../assets/logos/Parteneri & Certificări/symbol_MS.svg'
import thumbnail from '../../../assets/about/thumbnail_video_MS.png'

import { VideoAnimation } from '../../schools/DigitalizationVideoContainer'

import "./AdservioAndMicrosoft.css"
import VideoModal from '../../general/VideoModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const AdservioMicrosoft = () => {
   const [videoUrl, setVideoUrl]  = useState(null);
const {t} = useTranslation("common");
  return (
    <div className='adservioMicrosoft__wrapper'>
    {videoUrl ? <VideoModal videoUrl={'https://youtu.be/BIFGpVIRBqk'} setVideoUrl={setVideoUrl}/> : null}
        <div className='adservioMicrosoft__logos'>
            <div className='adservioMicrosoft__logos__wrapper'>
                <img src={adsLogo} alt="Adservio logo" className='adservioMicrosoft__logos__adservio'/>
                <span>+</span>
                <img src={microsoftLogo} alt="Microsoft logo" className='adservioMicrosoft__logos__microsoft'/>
            </div>
            <img className='adservioMicrosoft__logos__microsoft__icon' src={symbolMS} alt={"symbol MS"}/>
        </div>
        <div className='adservioMicrosoft__content'>
            <p className="adservioMicrosoft__text">{t("about.microsoft.text")}</p>
            <div className="adservioMicrosoft__video__wrapper">
                <VideoAnimation handleClick={()=>setVideoUrl('https://youtu.be/BIFGpVIRBqk')} img={thumbnail} url={'https://youtu.be/BIFGpVIRBqk'}/>
            </div>
        </div>       
    </div>
  )
}


