import Button from "../general/Button";
import card1Img from "../../assets/csr/cardImg1.svg";
import card2Img from "../../assets/csr/cardImg2.svg";
import card3Img from "../../assets/csr/cardImg3.svg";
import { useTranslation } from "react-i18next";
import "./steps.css";
const Steps = () => {
  const { i18n, t } = useTranslation("common");
  return (
    <div id="steps">
      <h1>{t("csr.steps.title")}</h1>
      <div className="containerCards">
        <div className="card">
          <div className="card_image_container">
            <img src={card1Img} alt={card1Img} />
          </div>
          <h3>{t("csr.steps.card1.h3")}</h3>
          <p>{t("csr.steps.card1.p")}</p>
        </div>
        <div className="card">
          <div className="card_image_container">
            <img src={card2Img} alt={card2Img} />
          </div>
          <h3>{t("csr.steps.card2.h3")}</h3>
          <p>{t("csr.steps.card2.p")}</p>
        </div>
        <div className="card">
          <div className="card_image_container">
            <img src={card3Img} alt={card3Img} />
          </div>
          <h3>{t("csr.steps.card3.h3")}</h3>
          <p>{t("csr.steps.card3.p")}</p>
        </div>
        <div className="emptyCard">aaaa</div>
      </div>
      <Button
        text={t("csr.steps.button")}
        type={"primary"}
        linkType="intern"
        link={`/${i18n.language}/p/contact`}
      />
    </div>
  );
};

export default Steps;
