import {ReactComponent as LanguageIcon} from "../../../assets/general/footer/langIcon.svg";
import {ReactComponent as ArrowDown} from "../../../assets/general/footer/arrow-down.svg";

import {useTranslation} from "react-i18next";
import {useRef, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

import Select from "../../content/select/Select";

import "./languageSelector.css";

const LanguageSelector = ({flags, color = "light", sizeSmall}) => {
  const {i18n} = useTranslation("comon");
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [languageSelector, setLangaugeSelector] = useState(false);
  const [currentLang, setCurrentLang] = useState();

  const getNewPath = (oldPath, newLng) => {
    let newPath = oldPath;
    newPath = "/" + newLng + newPath.slice(3);
    return newPath;
  };
  const changeLanguage = (e) => {
    const lang = e.item.dataValue;
    const lowerCaseLang = lang;

    i18n.changeLanguage(lowerCaseLang).then(() => {
      i18n.language = lowerCaseLang;

      navigate(getNewPath(location.pathname, lowerCaseLang) + location.search);
    });
  };
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (languageSelector && ref.current && !ref.current.contains(e.target)) {
        setLangaugeSelector(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [languageSelector]);

  useEffect(() => {
    const myOptionFlag = [];
    if (flags?.length) {
      flags.forEach((lang) => {
        myOptionFlag.push({lang: lang.langID, langValue: lang.langName});

        if (lang.langID === i18n?.language) {
          if (sizeSmall) {
            setCurrentLang(lang.langID.toUpperCase());
          } else {
            setCurrentLang(lang.langName);
          }
        }
      });
    }
  }, [flags, i18n?.language, sizeSmall]);

  return (
    <section className="language-selector">
      <Select
        name="switchLanguage"
        onChangeOptions={changeLanguage}
        isSearchable={false}
        value={{
          id: 0,
          label: (
            <div className={color}>
              <LanguageIcon strokeWidth="1%" />
              <span className="current-lang">{currentLang}</span>
              {sizeSmall && <ArrowDown />}
            </div>
          ),
          value: i18n?.language,
          filterName: "switchLanguage",
        }}
      >
        {flags?.length &&
          flags.map((el) => {
            return (
              <option key={el.langOrder} dataValue={el.langID}>
                {el.langName}
              </option>
            );
          })}
      </Select>
    </section>
  );
};
export default LanguageSelector;
