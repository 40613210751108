import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

export const VideoContainer = ({ type, activeStaff, youtubeUrl }) => {
  const { i18n } = useTranslation("common");
  const videoUrl = {
    public: {
      headmaster:
        i18n.language === "ro"
          ? "https://youtu.be/lWeoM_ZdNzM"
          : "https://youtu.be/g4OhhioPeow",

      secretary:
        i18n.language === "ro"
          ? "https://youtu.be/jFmRpQnTXfE"
          : "https://youtu.be/jFmRpQnTXfE",

      educator:
        i18n.language === "ro"
          ? "https://youtu.be/qRr3SL80JCo"
          : "https://youtu.be/P6IIVDYZvdc",

      schoolmaster:
        i18n.language === "ro"
          ? "https://youtu.be/s9drmTxZnBo"
          : "https://youtu.be/GZLnDHhJr9c",

      teacher:
        i18n.language === "ro"
          ? "https://youtu.be/RJQRcXe7AuI"
          : "https://youtu.be/AgCidyNsajc",

      headteacher:
        i18n.language === "ro"
          ? "https://www.youtube.com/watch?v=g0SxnDV3Wz4"
          : "",

      auxiliar: undefined,
    },
    parent:
      i18n.language === "ro"
        ? "https://youtu.be/uQzo0vTEGa0"
        : "https://youtu.be/FBMWcVkPDaA",
    student:
      i18n.language === "ro"
        ? "https://youtu.be/iO81u58O_Sw"
        : "https://youtu.be/ehFQUwC-ve8",
    "recommendations-home": {
      1: "https://youtu.be/Ul13cd198aU",
      2: "https://youtu.be/42ajZ_3iG3Q",
      3: "https://youtu.be/2owzbjzxmFg",
      4: "https://youtu.be/eFRKOoDn5w4",
      5: "https://youtu.be/y9V0ZbTCFeY",
    },
  };
  let url = undefined;

  switch (type) {
    case "public":
      url = videoUrl[type][activeStaff];
      break;
    case "private":
    case "virtual":
    case "chain":
      url = videoUrl["public"]["headmaster"];
      break;
    case "parent":
    case "student":
      url = videoUrl[type];
      break;
    case "recommendations-home":
    case "recommendations-schools":
    case "about":
      url = youtubeUrl;
      break;
    default:
      break;
  }

  console.log(youtubeUrl)
  return (
    <>
      {url && (
        <ReactPlayer
          url={url}
          width={"100%"}
          height={"100%"}
          controls={true}
          config={{
            youtube: {
              playerVars: {
                playsinline: 0,
                autoPlay: 1,
                cc_load_policy: i18n.language === "ro" ? 0 : 1,
                cc_lang_pref: i18n.language !== "ro" ? "en" : "ro",
                hl: i18n.language !== "ro" ? "en" : "ro",
              },
            },
          }}
        />
      )}
    </>
  );
};

export default VideoContainer;
