import React, {useState, useMemo, useEffect} from "react";
import BottomSection from "../general/footer/BottomSection";
import Header from "../general/header/Header";
import {useTranslation} from "react-i18next";

import wave from "../../assets/subscriptions/bluewave_background_login.svg";
import hero from "../../assets/subscriptions/phone_illustration.svg";
import checkmark from "../../assets/subscriptions/checkmark_icon.svg";

import "./packageChosen.css";
import ReactHtmlParser from "react-html-parser";
import {useParams, useLocation, useNavigate} from "react-router-dom";

import Api2 from "../../../src/Libs/api";

function useQuery() {
  const {search} = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PackageChosen = () => {
  const [flags, setFlags] = useState();
  const {t, i18n} = useTranslation("common");

  const navigate = useNavigate();
  const params = useQuery();
  const userType = params.get("user");
  const packageId = params.get("package");
  let {lng} = useParams();
  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });
  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);
  return (
    <section>
      <Header flags={flags} />
      <div className="packageChosen">
        <img src={wave} alt="wave" className="packageChosen__wave" />
        <div className="packageChosen__flex">
          <img src={hero} alt="hero" className="packageChosen__hero" />
          <div className="packageChosen__benefits">
            <h2>{ReactHtmlParser(t(`subscriptions.subTitle.${userType}`))}</h2>
            {Array.from(
              Array(
                parseInt(t(`packages.${userType}.${packageId}.noOfBenefits`))
              )
            ).map((_, index) => {
              return (
                <div className="packageChosen__benefit" key={index}>
                  <img src={checkmark} alt="checkmark" />
                  <p>
                    {t(`packages.${userType}.${packageId}.benefits.${index}`)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="packageChosen__box">
          <h1>{t(`subscriptions.start`)}</h1>
          <p>{t(`subscriptions.desc1`)}</p>
          <button
            onClick={() => (window.location.href = `/${i18n.language}/auth`)}
          >
            {t(`subscriptions.btn1`)}
          </button>
          {userType !== "parent" && (
            <>
              {" "}
              <span>{t(`subscriptions.desc2`)}</span>
              <button
                onClick={() => {
                  navigate(`/${i18n.language}/p/register?user=${userType}`);
                }}
              >
                {t(`subscriptions.btn2`)}
              </button>
            </>
          )}
        </div>
      </div>
      <BottomSection />
    </section>
  );
};

export default PackageChosen;
