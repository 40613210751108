const customStylesControl = (base, state) => ({
  ...base,
  border: state.isFocused ? 0 : 0,
  borderRadius: 0,
  boxShadow: state.isFocused ? 0 : 0,
  cursor: "pointer",
  fontSize: 14,
  marginLeft: "15px",
});

const customStylesOption = (styles) => ({
  ...styles,
  cursor: "pointer",
  textAlign: "left",
});

const customStyleMenu = (styles) => ({
  ...styles,
  marginTop: 0,
  borderRadius: 0,
});

const customStyleMenuList = (styles) => ({
  ...styles,
  padding: 0,
});

const customStyles = {
  control: customStylesControl,
  indicatorSeparator: () => ({ display: "none" }),
  option: customStylesOption,
  menu: customStyleMenu,
  menuList: customStyleMenuList,
};

export default customStyles;
