import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

import Home from "./components/home/Home";
import Schools from "./components/schools/Schools";
import Parents from "./components/parents/Parents";
import Students from "./components/students/Students";
import Institution from "./components/institutions/Institution";
import Csr from "./components/csr/Csr";
import Contact from "./components/contact/Contact";
import {About} from "./components/about";
import ScrollToTop from "./ScrollToTop";
import Register from "./components/register/Register";
import Registered from "./components/register/Registered";
import TermsAndCondition from "./components/general/TermsAndCondition";
import PrivacyNotice from "./components/general/PrivacyNotice";
import CookiesPage from "./components/general/Cookies";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Subscriptions from "./components/subscriptions/subscriptions";
import PackageChosen from "./components/subscriptions/PackageChosen";

function App() {
  window.addEventListener("orientationchange", () => {
    window.location.reload();
  });

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const isIos = window.navigator.userAgent.match(new RegExp("iPhone OS", "i"));

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Router>
      {!Cookies.get("ads_native") && !isIos && (
        <Helmet>
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-culture={language}
            data-cbid="c683ff1e-ea9a-458e-ae91-e2d33348dfdb"
            data-blockingmode="auto"
            type="text/javascript"
          ></script>
        </Helmet>
      )}

      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:lng" element={<Home />} />
        <Route path="/:lng/p/schools/:type" element={<Schools />} />
        <Route path="/:lng/p/parents" element={<Parents />} />
        <Route path="/:lng/p/students" element={<Students />} />
        <Route path="/:lng/p/institutions/:type" element={<Institution />} />
        <Route path="/:lng/p/about/csr" element={<Csr />} />
        <Route path="/:lng/p/about" element={<About />} />
        <Route path="/:lng/p/contact" element={<Contact />} />
        <Route path="/:lng/p/subscriptions" element={<Subscriptions />} />
        <Route
          path="/:lng/p/subscriptions/chosen"
          element={<PackageChosen />}
        />
        <Route path="/:lng/p/register" element={<Register />} />
        <Route path="/:lng/p/cookies" element={<CookiesPage />} />
        <Route
          path="/:lng/p/register/validate/:token"
          element={<Registered />}
        />
        <Route
          path="/:lng/p/terms-and-condition"
          element={<TermsAndCondition />}
        />
      <Route path="/:lng/p/privacy-policy" element={<PrivacyNotice />} />
      </Routes>
    </Router>
  );
}

export default App;
