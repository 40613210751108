import img from "../../assets/about/Mask Group 9.png";
import AOS from "aos";
import { useEffect } from "react";
const SecondImageContainer = () => {
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });
  }, []);
  return (
    <>
      <svg
        id="secondImagesSection"
        xmlns="http://www.w3.org/2000/svg"
        width="809.058"
        height="870.806"
        viewBox="0 0 809.058 648.806"
      >
        <g
          id="Group_1656"
          data-name="Group 1656"
          transform="translate(-0.001 -1783.517)"
        >
          <rect
            id="Rectangle_297"
            data-name="Rectangle 297"
            width="151.483"
            height="151.483"
            transform="translate(563.001 1935) rotate(-90)"
            fill="#e54f6d"
          />
          <path
            id="Path_1298"
            data-name="Path 1298"
            d="M1717.454,446.232V351.658h94.559A94.877,94.877,0,0,1,1717.454,446.232Z"
            transform="translate(362.827 3747.014) rotate(-90)"
            fill="#f7c52f"
          />
          <path
            id="Path_1297"
            data-name="Path 1297"
            d="M0,197.323C0,88.345,88.345,0,197.323,0S394.647,88.345,394.647,197.323Z"
            transform="translate(197.324 2037.676) rotate(90)"
            fill="#f7c52f"
          />
        </g>

        <image
          id="img3"
          href={img}
          height="600"
          width="600"
          transform="translate(197.324 151.483)"
        />
      </svg>
    </>
  );
};

export default SecondImageContainer;
