import entreprAcademy from '../../assets/logos/Parteneri & Certificări/Logo-EntrepreneurshipAcademy.svg';
import innvox from '../../assets/logos/Parteneri & Certificări/logo-InnovX.svg';
import iso from '../../assets/logos/Parteneri & Certificări/logo-ISO.svg';
import merito from '../../assets/logos/Parteneri & Certificări/logo-Merito.png';
import mindArh from '../../assets/logos/Parteneri & Certificări/logo-MindArchitect.png';
import narada from '../../assets/logos/Parteneri & Certificări/logo-Narada.png';
import sclBani from '../../assets/logos/Parteneri & Certificări/logo-ScoaladeBani.svg';
import startupGrind from '../../assets/logos/Parteneri & Certificări/logo-StartupGrind.svg';
import superTech from '../../assets/logos/Parteneri & Certificări/logo-Superteach.svg';
import vodafone from '../../assets/logos/Parteneri & Certificări/logo-Vodafone.svg';
import vrSaFiuAntre from '../../assets/logos/Parteneri & Certificări/logo-VreauSaFiuAntreprenor.png';
import yes from '../../assets/logos/Parteneri & Certificări/logo-YES.svg';
import ave from '../../assets/logos/Parteneri & Certificări/logo-AVE.png';
import bcr from '../../assets/logos/Parteneri & Certificări/logo-BCR.svg';
import bz from '../../assets/logos/Parteneri & Certificări/logo-Buzău.svg';
import educ from '../../assets/logos/Parteneri & Certificări/logo-Educativa.png';
import eduHack from '../../assets/logos/Parteneri & Certificări/logo-EduHack.png';
import microsoft from '../../assets/logos/Parteneri & Certificări/logo-Microsoft.svg';
import crayon from '../../assets/logos/Parteneri & Certificări/logo-Crayon.svg';
import orange from '../../assets/logos/Parteneri & Certificări/logo-Orange.svg';
import scoalaDeBani from '../../assets/logos/Parteneri & Certificări/logo-ScoaladeBani.svg';
import anis from '../../assets/logos/Parteneri & Certificări/anis_logo.png';
import eaf from '../../assets/logos/Parteneri & Certificări/EAF_logo.png';

export const parteners = {
  entreprAcademyLogo: {
    img: entreprAcademy,
    height: '70',
    width: '271',
  },
  innvoxLogo: {
    img: innvox,
    height: '143',
    width: '100',
  },
  isoLogo: {
    img: iso,
    height: '111',
    width: '370',
  },
  meritoLogo: {
    img: merito,
    height: '91',
    width: '208',
  },
  mindArhLogo: {
    img: mindArh,
    height: '89',
    width: '202',
  },
  naradaLogo: {
    img: narada,
    height: '55',
    width: '183',
  },
  sclBaniLogo: {
    img: sclBani,
    height: '79',
    width: '176',
  },
  startupGrindLogo: {
    img: startupGrind,
    height: '131',
    width: '208',
  },
  superTechLogo: {
    img: superTech,
    height: '65',
    width: '200',
  },
  vodafoneLogo: {
    img: vodafone,
    height: '62',
    width: '251',
  },
  vrSaFiuAntreLogo: {
    img: vrSaFiuAntre,
    height: '152',
    width: '149',
  },
  yesLogo: {
    img: yes,
    height: '68',
    width: '207',
  },
  aveLogo: {
    img: ave,
    height: '97',
    width: '185',
  },
  bcrLogo: {
    img: bcr,
    height: '56',
    width: '154',
  },
  bzLogo: {
    img: bz,
    height: '84',
    width: '192',
  },
  educLogo: {
    img: educ,
    height: '117',
    width: '293',
  },
  eduHackLogo: {
    img: eduHack,
    height: '73',
    width: '169',
  },
  microsoft: {
    img: microsoft,
    height: '50',
    width: 'auto',
  },
  crayonLogo: {
    img: crayon,
    height: '50',
    width: 'auto',
  },
  orangeLogo: {
    img: orange,
    height: '50',
    width: 'auto',
  },
  scoalaDeBaniLogo: {
    img: scoalaDeBani,
    height: '50',
    width: 'auto',
  },
  anisLogo: {
    img: anis,
    height: '60',
    width: 'auto',
  },
  eafLogo: {
    img: eaf,
    height: '120',
    width: 'auto',
  },
};

export const partenersFor = {
  homePage: [
    parteners['microsoft'],
    parteners['vodafoneLogo'],
    parteners['bcrLogo'],
    parteners['bzLogo'],
    parteners['mindArhLogo'],
    parteners['superTechLogo'],
    parteners['naradaLogo'],
    parteners['anisLogo'],
  ],
  parents: [
    parteners['microsoft'],
    parteners['vodafoneLogo'],
    parteners['bcrLogo'],
    parteners['superTechLogo'],
    parteners['educLogo'],
    parteners['vrSaFiuAntreLogo'],
    parteners['eduHackLogo'],
    parteners['entreprAcademyLogo'],
  ],
  cityhall: [
    parteners['microsoft'],
    parteners['vodafoneLogo'],
    parteners['bcrLogo'],
    parteners['bzLogo'],
    parteners['superTechLogo'],
    parteners['mindArhLogo'],
    parteners['eafLogo'],
    parteners['isoLogo'],
  ],
  about: [
    parteners['microsoft'],
    parteners['vodafoneLogo'],
    parteners['crayonLogo'],
    parteners['orangeLogo'],
    parteners['bcrLogo'],
    parteners['scoalaDeBaniLogo'],
    parteners['bzLogo'],
    parteners['educLogo'],
    parteners['naradaLogo'],
    parteners['meritoLogo'],
    parteners['innvoxLogo'],
    parteners['aveLogo'],
    parteners['yesLogo'],
    parteners['startupGrindLogo'],
    parteners['eafLogo'],
    parteners['isoLogo'],
  ],
};

export const partenersForInternational = {
  homePage: [
    parteners['microsoft'],
    parteners['crayonLogo'],
    parteners['vodafoneLogo'],
    parteners['orangeLogo'],
    parteners['bcrLogo'],
    parteners['anisLogo'],
  ],
  parents: [
    parteners['microsoft'],
    parteners['vodafoneLogo'],
    parteners['bcrLogo'],
    parteners['superTechLogo'],
    parteners['eduHackLogo'],
    parteners['entreprAcademyLogo'],
  ],
  cityhall: [
    parteners['microsoft'],
    parteners['vodafoneLogo'],
    parteners['bcrLogo'],
    parteners['superTechLogo'],
    parteners['mindArhLogo'],
    parteners['eafLogo'],
    parteners['isoLogo'],
  ],
  about: [
    parteners['microsoft'],
    parteners['crayonLogo'],
    parteners['vodafoneLogo'],
    parteners['orangeLogo'],
    parteners['bcrLogo'],
    parteners['eafLogo'],
    parteners['isoLogo'],
  ],
};
