import "./ministry_digitalization.css";
import activities_icon from "../../assets/schools/blueIcons/feather-bround.svg"
import statistics_icon from "../../assets/schools/blueIcons/barchart2-bround.svg"
import lightning_icon from "../../assets/schools/blueIcons/piechart-bround.svg"
import { useTranslation } from "react-i18next";
const MinistryDigitalization = () => {
  const { t } = useTranslation("common");
  return (
    <section className="ministry_digitalization">
      <h1>{t("institutions.ministry.digitalization.title")}</h1>
      <div className="ministry_digitalization_container">
        <h2>{t("institutions.ministry.digitalization.title2")}</h2>
        <div>
          <img src={activities_icon} alt={activities_icon} />
          <p>{t("institutions.ministry.digitalization.p1")}</p>
        </div>
        <div>
          <img src={statistics_icon} alt={statistics_icon} />
          <p>{t("institutions.ministry.digitalization.p2")}</p>
        </div>
        <div>
          <img src={lightning_icon} alt={activities_icon} />
          <p>{t("institutions.ministry.digitalization.p3")}</p>
        </div>
      </div>
    </section>
  )
}





export default MinistryDigitalization
