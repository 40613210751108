import exit from "../../assets/shareModal/exit.svg";
import mail from "../../assets/shareModal/mail.svg";
import mess from "../../assets/shareModal/mess.svg";
import whatsApp from "../../assets/shareModal/whatsApp.svg";
import fb from "../../assets/shareModal/icon-Facebook.svg";
import linkedIn from "../../assets/shareModal/icon-LinkedIn.svg";
import twitter from "../../assets/shareModal/icon-Twitter.svg";
import Parser from "html-react-parser";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./shareModal.css";

const ShareModal = () => {
  const { t, i18n } = useTranslation("common");
  const currentHost = window.location.host;
  const wpText = t("general.shareModal.recommendsAdsText", { 0: currentHost });
  const fbShareText = Parser(
    t("general.shareModal.facebookShareText", {
      0: currentHost,
    })
  );
  const linkedInText = t("general.shareModal.linkedinSummaryText", {
    0: currentHost,
  });
  const shareURL = `https://${currentHost}/${
    i18n.language === "ro" ? i18n.language : "en"
  }`;

  useEffect(() => {
    document.getElementById("shareModal").addEventListener("wheel", (evt) => {
      evt.preventDefault();
    });
  }, []);

  return (
    <>
      <div id="shareModal">
        <div className="content">
          <h1>{t("general.shareModal.title")}</h1>
          <p>{t("general.shareModal.p1")}</p>
          <p>{t("general.shareModal.p2")}</p>
          <div className="send">
            <div className="optionCard">
              <img src={mail} alt={mail} />
              <span>
                <a href={`mailto:?subject=adservio&body=${t("general.shareModal.recommendsAdsText",{0:shareURL,})}`}>
                  {t("general.shareModal.email")}
                </a>
              </span>
            </div>
            <div className="optionCard">
              <img src={whatsApp} alt={whatsApp} />
              <span>
                <a
                  href={`https://api.whatsapp.com/send?text=${wpText}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("general.shareModal.whatsapp")}
                </a>
              </span>
            </div>
            <div className="optionCard">
              <FacebookMessengerShareButton
                appId="396867917090216"
                url={shareURL}
                redirectUri={shareURL}
                link={shareURL}
              >
                <img src={mess} alt={mess} />
                <span>{t("general.shareModal.messenger")}</span>
              </FacebookMessengerShareButton>
            </div>
          </div>
          <div className="horizontalLine">
            <span>{t("general.shareModal.share")}</span>
          </div>
          <div className="share">
            <FacebookShareButton
              url={shareURL}
              quote={fbShareText}
              hashtag={`#adservio`}
            >
              <img src={fb} alt={fb} />
            </FacebookShareButton>

            <LinkedinShareButton
              url={shareURL}
              title={t("general.shareModal.linkedinTitle")}
              summary={linkedInText}
              source={shareURL}
            >
              <img src={linkedIn} alt={linkedIn} />
            </LinkedinShareButton>

            <TwitterShareButton
              url={shareURL}
              title={t("general.shareModal.twitterTitle")}
              hashtag={`#adservio`}
            >
              <img src={twitter} alt={twitter} />
            </TwitterShareButton>
          </div>
          <button
            className="close-modal"
            onClick={() =>
              (document.getElementById("shareModal").style.display = "none")
            }
          >
            <img src={exit} alt={exit} />
          </button>
        </div>
        <div className="backgroundContainer"></div>
      </div>
    </>
  );
};

export default ShareModal;
