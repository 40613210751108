import RRA from "../../assets/logos/RRA.svg";
import ZF from "../../assets/logos/ZF.png";
import Hotnews from "../../assets/logos/Hotnews.png";
import Adevarul from "../../assets/logos/Adevarul.svg";
import Mediafax from "../../assets/logos/Mediafax.svg";
import Guerilla from "../../assets/logos/Guerilla.svg";
import digi from "../../assets/logos/Digi24.svg";

import protv from "../../assets/logos/ProTV.svg";
import forbes from "../../assets/logos/Forbes.svg";
import aLive from "../../assets/logos/aLive.png";
import br from "../../assets/logos/BR.png";
import Biz from "../../assets/logos/Biz.png";
import startUp from "../../assets/logos/StartUp.png";

export const mentionsRo = [
  { img: RRA, name: "RRA", id: 0 },
  { img: ZF, name: "ZF", id: 1 },
  { img: Hotnews, name: "Hotnews", id: 2 },
  { img: Adevarul, name: "Adevarul", id: 3 },
  { img: Mediafax, name: "Mediafax", id: 4 },
  { img: Guerilla, name: "Guerilla", id: 5 },
  { img: digi, name: "digi", id: 6 },
  { img: protv, name: "protv", id: 7 },
  { img: forbes, name: "forbes", id: 8 },
  { img: aLive, name: "aLive", id: 9 },
  { img: br, name: "br", id: 10 },
  { img: Biz, name: "Biz", id: 11 },
  { img: startUp, name: "startUp", id: 12 },
];
export const mentionsInternational = [
  { img: Hotnews, name: "Hotnews", id: 0 },
  { img: Mediafax, name: "Mediafax", id: 1 },
  { img: Guerilla, name: "Guerilla", id: 2 },
  { img: digi, name: "digi", id: 3 },
  { img: protv, name: "protv", id: 4 },
  { img: forbes, name: "forbes", id: 5 },
  { img: aLive, name: "aLive", id: 6 },
  { img: br, name: "br", id: 7 },
  { img: Biz, name: "Biz", id: 8 },
  { img: startUp, name: "startUp", id: 9 },
];
