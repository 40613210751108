import imgBottom from "../../assets/contact/imgBottom.svg";
import imgTopRight from "../../assets/contact/imgTopRight.svg";
import keys from "../../assets/contact/keys.svg";
import location from "../../assets/contact/location.svg";
import phone from "../../assets/contact/phone.svg";
import recoverIcon from "../../assets/contact/recover-icon.svg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {Header} from "../general/header";
import BecomePartener from "../about/BecomePartener";
import Footer from "../general/footer/Footer";
import Form from "./components/Form";
import AOS from "aos";
import Api2 from "../../../src/Libs/api";

import "./contact.css";

const Contact = () => {
  let { lng } = useParams();
  const { t, i18n } = useTranslation("common");
  const [flags, setFlags] = useState();
  useEffect(() => {
    AOS.init({ mirror: "false", once: "true", duration: 1000 });

    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);
  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });
  const clickHandler = () => {
    document.location =
      "mailto:office@adservio.education?subject=Partnership inquiry";
  };
  return (
    <>
      <Helmet>
        <title>{t("general.keysForMeta.contact.title")}</title>
        <meta
          name="description"
          content={t("general.keysForMeta.contact.description")}
        />
        <meta
          name="keywords"
          content={t("general.keysForMeta.contact.keywords")}
        />
        <meta
          property="og:title"
          content={t("general.keysForMeta.contact.title")}
        />
        <meta
          property="og:description"
          content={t("general.keysForMeta.contact.description")}
        />
      </Helmet>
      <Header flags={flags} activeEl={"contact"} />
      <main style={{ position: "relative", overflow: "hidden" }}>
        <img
          src={imgBottom}
          alt={imgBottom}
          id="bottomImg"
          data-aos="zoom-in-up"
        />
        <section id="contact">
          <Form />

          <section className="col">
            <section className="dataRecover">
              <h1>{t("contact.daraRecover.title")}</h1>
              <div className="content">
                <img src={keys} alt={keys} />
                <div className="textContainer">
                  <h2>{t("contact.daraRecover.subTitle")}</h2>
                  <p>{t("contact.daraRecover.p")}</p>
                  <div>
                    <img src={recoverIcon} alt={recoverIcon} />

                    <a href={`/${i18n.language}/site/recuperare_parola`}>
                      {t("contact.daraRecover.start")}
                    </a>
                  </div>
                </div>
                <img
                  src={imgTopRight}
                  alt={imgTopRight}
                  data-aos="slide-left"
                />
              </div>
            </section>
            <section className="contact2">
              <h1>{t("contact.contact2.title")}</h1>
              <div className="row">
                <div className="container">
                  <div className="top">
                    <img src={location} alt={location} />
                    <h3>{t("contact.contact2.card1.title")}</h3>
                  </div>

                  <p>
                    {t("contact.contact2.card1.adress.1")}
                    <br />
                    {t("contact.contact2.card1.adress.2")}
                  </p>

                  <p>
                    {t("contact.contact2.card1.zipCode")} 700011 <br />
                    RO 26033834, <br />J 22/1482/2009
                  </p>
                </div>
                <div className="container">
                  <div className="top">
                    <img src={phone} alt={phone} />
                    <h3> {t("contact.contact2.card2.title")}</h3>
                  </div>

                  <p>
                    {t("contact.contact2.card2.timeSchedule.1")}
                    <br />
                    {t("contact.contact2.card2.timeSchedule.2")}
                  </p>

                  <p>
                    {t("contact.contact2.card2.country")}
                    <br />
                    <span>+40 332 10 28 28</span>
                  </p>
                </div>
              </div>
            </section>
          </section>
        </section>

        {i18n.language !== "ro" && <BecomePartener action={clickHandler} />}
      </main>
      <Footer flags={flags} />
    </>
  );
};

export default Contact;
