import { useTranslation } from "react-i18next";

const Secretary = ({ images }) => {
  const { t } = useTranslation("common");
  return (
    <div className="benefits_blue_icons_container" id="secretary">
      <h1>{t("schools.benefits.public.blue-icons.secretary.title")}</h1>
      <div className="wrapper">
        <div className="blue_icon_w_info">
          <img src={images["zap-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r1c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["archive-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r1c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["cloud-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r1c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["piechart-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r1c4")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["feather-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r2c1")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["reportcard-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r2c2")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["gradebook-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.secretary.r2c3")}</p>
        </div>
        <div className="blue_icon_w_info">
          <img src={images["coins-bround.svg"].default} alt="" />
          <p>{t("schools.benefits.public.blue-icons.auxiliar.r2c2")}</p>
        </div>
      </div>
    </div>
  );
};

export default Secretary;
