import React from 'react';
import adservioLogo from '../../../assets/general/footer/logo-Adservio-footer.svg';
import LaptopImg from '../../../assets/parents/Admitere_Univ.png';
import Button from '../Button';
import './AdmissionSection.css';
import { useTranslation } from 'react-i18next';
import useCheckHostname from '../../../utility/helper';

const AdmissionSection = ({ type }) => {
  const { t, i18n } = useTranslation('common');
  const isEducation = useCheckHostname('education');

  if (isEducation) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <section className="admission_section">
      <img className="admission_section_illustration" src={LaptopImg} alt="laptop admitere" />
      <div className="admission_card">
        <div className="admission_card_top">
          <img className="adservio_logo" src={adservioLogo} alt="adservio logo" />
          <div className="admission_card_badge">
            <p>{t('general.admission_section.admission')}</p>
          </div>
        </div>
        <h2 className="admission_card_title">{t('general.admission_section.title')}</h2>
        <p className="admission_card_description">
          {t(`general.admission_section.${type}.description`)}
        </p>
        <Button
          text={t('general.admission_section.view_faculties')}
          type={'primary'}
          linkType="location"
          link={`/${i18n.language}/admission/offers`}
        />
      </div>
    </section>
  );
};

export default AdmissionSection;
