import { Header } from '../general/header';
import Footer from '../general/footer/Footer';
import SelectUser from './SelectUser';
import SchoolDetails from './SchoolDetails';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import RegisterError from './RegisterError';
import SchoolRegister from './SchoolRegister';
import ShareModal from '../general/ShareModal';
import { isMobile } from 'react-device-detect';
import RegisterSucceeded from './RegisterSucceeded';
import SchoolIsAdservio from './SchoolIsAdservio';
import { useTranslation } from 'react-i18next';
import './registerError.css';
import { useParams } from 'react-router-dom';
import Api2 from '../../../src/Libs/api';
import Loader from '../overlays/Modal/Loader';
import { AZURE_MARKETPLACE_SOURCE } from '../../constants';

const Register = () => {
  const { i18n, t } = useTranslation('common');
  let { lng } = useParams();

  const [user, setUser] = useState();
  const [lastStep, setLastStep] = useState();
  const [firstStepData, setFirstStepData] = useState();
  const [flags, setFlags] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscriptionFlow, setIsSubscriptionFlow] = useState(false);
  const [subscriptionError, setSubscriptionsError] = useState(false);

  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };

  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let token = queryParams.get('token');
    let source = queryParams.get('source');

    if (source === AZURE_MARKETPLACE_SOURCE && token) {
      setIsLoading(true);

      Api2.post('./microsoft/subscription', { token: decodeURI(token) })
        .then((response) => {
          setIsLoading(false);
          setFirstStepData({
            school: {},
            azsID: response.azsID,
            azsEmail: response.email,
          });
          setIsSubscriptionFlow(true);
        })
        .catch(() => {
          setIsLoading(false);
          setSubscriptionsError(true);
        });
    }

    let allFlags = [];

    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };

    allFlags?.length === 0 && fetchEvent();
  }, []);

  const openModal = async () => {
    const shareData = {
      title: 'adservio',
      text: 'adservio text',
      url: 'https://adservio.ro',
    };

    if (isMobile) {
      navigator
        .share(shareData)
        .then(() => console.log('Share was successful.'))
        .catch((error) => {
          console.log('Sharing failed', error);
        });
    } else {
      console.log(`Your system doesn't support sharing files.`);
      document.getElementById('shareModal').style.display = 'block';
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    let urlData = queryParams.get('user');
    if (urlData) setUser(urlData);
  }, []);

  const userCanCreateSchool = user === 'school' || user === 'headmaster' || user === 'secretary';

  return (
    <>
      <Helmet>
        <title>{t(`general.keysForMeta.register-${user || ''}.title`)}</title>
        <meta
          name="description"
          content={t(`general.keysForMeta.register-${user || ''}.description`)}
        />
        <meta name="keywords" content={t(`general.keysForMeta.register-${user || ''}.keywords`)} />
        <meta property="og:title" content={t(`general.keysForMeta.register-${user || ''}.title`)} />
        <meta
          property="og:description"
          content={t(`general.keysForMeta.register-${user || ''}.description`)}
        />
      </Helmet>

      <ShareModal />

      <Header flags={flags} />

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {subscriptionError ? (
              <p className="subscription-error">{t('general.generic_error')}</p>
            ) : (
              <>
                {!lastStep ? (
                  user ? (
                    firstStepData ? (
                      firstStepData?.school?.scoalaIsAdservio ? (
                        <SchoolIsAdservio />
                      ) : userCanCreateSchool || isSubscriptionFlow ? (
                        <SchoolRegister
                          firstStepData={firstStepData}
                          setLastStep={setLastStep}
                          goBack={setFirstStepData}
                        />
                      ) : (
                        <RegisterError user={user} openModal={openModal} />
                      )
                    ) : (
                      <SchoolDetails
                        onSubmit={setFirstStepData}
                        userCanCreateSchool={userCanCreateSchool}
                      />
                    )
                  ) : (
                    <SelectUser setUser={setUser} />
                  )
                ) : (
                  <RegisterSucceeded />
                )}
              </>
            )}
          </>
        )}
      </main>

      <Footer flags={flags} />
    </>
  );
};

export default Register;
