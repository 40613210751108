import React from "react";

import "./EngPrivacyPolicy.css";

const EngPrivacyPolicy = () => {
  const currentHost = window.location.host;

  return (
    <div className="comp-eng-privacy-policy">
      {" "}
      <h1 align="center">
        <strong>Personal data protection policy</strong>
      </h1>
      <p>
        The protection of your personal data is important to us, therefore, we
        pay special attention to the protection of privacy of visitors who
        access the site&nbsp;
        <a href={`https://${currentHost}/`}>www.adservio.ro</a>, hereinafter
        referred to as the <strong>Site</strong>, as well as persons who use the
        educational platform ADSERVIO called hereinafter
        <strong> ADSERVIO PLATFORM</strong>.
      </p>
      <p>
        From 25 May 2018, Regulation (EU) 2016/679 of the European Parliament
        and of the Council of 27 April 2016 on the protection of individuals
        with regard to the processing of personal data and on the free movement
        of such data, hereinafter referred to as <strong>"GDPR"</strong>.
      </p>
      <p>
        Personal data is data that directly identifies an individual such as
        name, surname, home address, e-mail address or telephone number, but
        also any other data that allows its indirect identification, such as, in
        certain cases, enrollment number of students.
      </p>
      <p>
        GDPR is applicable to any legal entity that processes personal data in
        their activity.
      </p>
      <p>
        Please pay special attention to reading the following Policy in order to
        understand how your personal data will be treated.
      </p>
      <p>
        Through this Policy, we inform you about our practices regarding the
        application of the provisions of the GDPR but also about the nature of
        the personal data we process on the Site and on the ADSERVIO PLATFORM,
        the purposes and grounds of processing, the rights enjoyed by data
        subjects.
      </p>
      <p>
        <strong>1. DEFINITIONS:</strong>
      </p>
      <ul>
        <li>
          <strong>ANSPDCP</strong> represents the National Authority for the
          Supervision of Personal Data Processing;
        </li>
        <li>
          <strong>"personal data" </strong> means any information relating to an
          identified or identifiable natural person ("data subject"); an
          identifiable natural person is a person who can be identified,
          directly or indirectly, in particular by reference to an identifying
          element, such as a name, an identification number, location data, an
          online identifier, or one or more many specific elements of his
          physical, physiological, genetic, mental, economic, cultural or social
          identity;
        </li>
        <li>
          <strong></strong> "<strong>processing</strong>" means any operation or
          set of operations performed on personal data or personal data sets,
          with or without the use of automated means such as collecting,
          recording, organizing, structuring, storing, adapting or modifying ,
          extracting, consulting, using, disclosing by transmission,
          dissemination or otherwise making available, aligning or combining,
          restricting, deleting or destroying;
        </li>
        <li>
          <strong>"restriction of processing" </strong> means the marking of
          stored personal data in order to limit their further processing;
        </li>
        <li>
          <strong>"controller" </strong> means the natural or legal person,
          public authority, agency or other body which, alone or jointly with
          others, determines the purposes and means of the processing of
          personal data; where the purposes and means of processing are
          determined by Union or national law, the operator or the specific
          criteria for its designation may be laid down in Union or national
          law;
        </li>
        <li>
          <strong>"authorized person" </strong> means the natural or legal
          person, public authority, agency or other body which processes
          personal data on behalf of the controller;
        </li>
        <li>
          <strong>"recipient" </strong> means the natural or legal person,
          public authority, agency or other body to whom personal data are
          disclosed, whether or not it is a third party. However, public
          authorities to which personal data may be disclosed in the course of a
          particular investigation in accordance with Union or national law
          shall not be considered as recipients; the processing of such data by
          the public authorities concerned shall comply with the applicable data
          protection rules in accordance with the purposes of the processing;
        </li>
        <li>
          <strong>"consent"</strong> of the data subject means any manifestation
          of the free, specific, informed and unambiguous will of the data
          subject by which he or she consents, by an unequivocal statement or
          action, that personal data concerning him or her be processed.
        </li>
      </ul>
      <p>
        <strong>2</strong> <strong>. WHO WE ARE</strong>
      </p>
      <p>
        We are Adservio Social Innovation S.R.L. with its registered office in
        the Municipality of Iași, Bulevardul Socola Street, Number 3, Block D2,
        Staircase B, 4th floor, Apartment 19, Iași County, registered at the
        Trade Register Office under no. J22 / 1482/2009, having Unique
        Registration Code RO 26033834, hereinafter referred to as ADSERVIO.
      </p>
      <p>
        ADSERVIO is the author, owner and administrator of the&nbsp;
        <a href={`https://${currentHost}/`}>www.adservio.ro</a> Site and of the
        ADSERVIO PLATFORM so that all the information contained in this Policy
        is related and valid in relation to the activity on the Site and on the
        ADSERVIO PLATFORM.
      </p>
      <p>
        <strong>
          3. IDENTITY AND CONTACT DETAILS OF THE PERSON RESPONSIBLE FOR DATA
          PROTECTION (DPO)
        </strong>
      </p>
      <p>
        ADSERVIO has appointed a Data Protection Officer ("DPO"), who has the
        obligation to verify compliance with the provisions of the GDPR in the
        data processing operations performed by ADSERVIO and to represent
        ADSERVIO in relation to data subjects and ANSPDCP.
      </p>
      <p>
        The persons concerned have the possibility to address at any time,
        directly, RPD to the following email address:&nbsp;
        <a href={`mailto:dpo@${currentHost}`}>dpo@adservio.ro</a>.
      </p>
      <p>
        <strong>4. PRINCIPLES REGARDING THE PROCESSING OF PERSONAL DATA</strong>
      </p>
      <p>
        ADSERVIO undertakes to comply with the principles of personal data
        protection set out in the GDPR, in order to ensure that all data are:
      </p>
      <ul className="check-list">
        <li>Processed correctly, legally and transparently; </li>
        <li>Collected for specified, explicit and legitimate purposes; </li>
        <li>
          Adequate, relevant and limited in relation to the purposes for which
          they are processed;
        </li>
        <li>Correct and updated; </li>
        <li>
          Kept in a form which does not allow the identification of the persons
          concerned for longer than is necessary in relation to the purpose of
          the processing;
        </li>
        <li>
          Processed in accordance with the rights of the data subject, in a way
          that ensures adequate security of processing, so that the data is
          complete, confidential and available.
        </li>
      </ul>
      <p>
        <strong>5. WHAT TYPES OF PERSONAL DATA WE COLLECT</strong>
      </p>
      <p>
        We inform you that we process the following personal data through the
        existing forms on the Site:
      </p>
      <ol className="check-box">
        <li>
          In the “Request presentation” form: first name, e-mail address,
          telephone number, the organization you represent, the role in the
          organization, the locality and the county. We need the data to be able
          to register for this event and we process it if you give your consent.
        </li>
        <li>
          In the "CAREERS Section": the data included in the CV that you choose
          to send to the address jobdeviitor@adservio.ro indicated in the
          CAREERS section of the Site. give your consent.
        </li>
        <li>
          In the "Add a comment" section: name, email address you entered with
          the desired comment. We need the data to receive your comment and we
          process it if you give us your consent.
        </li>
        <li>
          In the section “Find out all the news Adservio”: the e-mail address
          that you provide us and that we process if you give us your consent in
          this regard.
        </li>
        <li>
          In the “CONTACT US” section: name, surname, school, telephone, e-mail
          address. We need your data to contact you as soon as possible (maximum
          24 hours) following your request.
        </li>
        <li>
          We post on the Site your testimonials about the ADSERVIO PLATFORM that
          may contain personal information, such as the name, photo, position
          and / or video of the person in the testimony. We obtain the person's
          prior consent to ensure that we have permission to post this content
          publicly. To request the removal of your personal information from the
          testimonials, please contact us at&nbsp;
          <a href={`mailto:dpo@${currentHost}`}>dpo@adservio.ro</a>
        </li>
      </ol>
      <p>
        ADSERVIO may also receive and record on its servers the information
        regarding the IP address and cookie information of the requested site.
        You have the option to set your internet browser to reject certain
        cookies. However, this will have a negative impact on your browsing of
        the Site. Adservio's cookie policy can be found&nbsp;
        <a href={`https://${currentHost}/en/p/cookies`}>here</a>.
      </p>
      <p>
        We collect personal data from users of the ADSERVIO PLATFORM for the
        creation of user accounts and for the data subjects to have access to
        the information provided by the ADSERVIO PLATFORM.
      </p>
      <ol className="check-box">
        <li>
          Name, surname, specialization, e-mail address, telephone number,
          image, video recording belonging to teachers;
        </li>
        <li>
          Name, surname, class in which he is enrolled, e-mail address,
          telephone number, image, video recording belonging to students;
        </li>
        <li>
          Name, surname, e-mail address, telephone number belonging to the
          parents of the students;
        </li>
        <li>
          Name, surname, e-mail address, telephone number belonging to the
          Directors of the educational units with which ADSERVIO has a
          contractual relationship.
        </li>
      </ol>
      <p>
        No full profile of the child is available or visible to other parents
        through the ADSERVIO PLATFORM.
      </p>
      <p>
        ADSERVIO may access the data when necessary to provide support and
        assistance in using the ADSERVIO PLATFORM. All data about parents,
        teachers, children and / or grades, absent, average are entered in the
        ADSERVIO PLATFORM by persons appointed by the school with which ADSERVIO
        has a contractual relationship.
      </p>
      <p>
        <strong>6. PURPOSE FOR WHICH WE PROCESS PERSONAL DATA</strong>
      </p>
      <p> We request and process personal data in order to: </p>
      <ol className="check-box">
        <li>
          Make it possible to register for a demo presentation, once you have
          given your consent through the “Request a presentation” form;
        </li>
        <li>
          To recruit in the ADSERVIO team, once you have expressed your consent
          by sending your CV to jobdeviitor@adservio.ro;
        </li>
        <li>
          Receive and analyze your comment once you have given your consent
          using the form provided in the "Add a comment" section:
        </li>
        <li>
          To send you newsletters, invitations, materials related to our
          services and information related to the events or programs that we
          organize or promote once you have given your consent through the form
          "Find out all the news Adservio";
        </li>
        <li>
          To contact you once you have given your consent through the form sent
          in the section “CONTACT US;
        </li>
        <li>
          Make it possible to create your account on the ADSERVIO PLATFORM;
        </li>
        <li> Ensure the proper functioning of the ADSERVIO PLATFORM; </li>
        <li>
          To prevent spam, fraud and abuse and to help recover the account, the
          username of the account via SMS;
        </li>
      </ol>
      <p>
        <strong>7.TEMEIURILE PRELUCRĂRII DE DATE CU CARACTER PERSONAL</strong>
      </p>
      <p>
        ADSERVIO processes personal data on Site and on the ADSERVIO PLATFORM:
      </p>
      <ol className="check-box">
        <li>
          on the basis of the consent given by the persons entrusting the data
          to us (art. 6 paragraph 1 sentence 1 letter of the GDPR
        </li>
        <li>
          for the execution of a contract to which you are a party (art. 6
          paragraph 1 sentence 1 letter b GDPR)
        </li>
        <li>
          in order to fulfill a legal obligation incumbent on us (art. 6 para. 1
          thesis 1 letter c GDPR)
        </li>
        <li>
          according to the legitimate interest of ADSERVIO (art. 6 paragraph 1
          thesis 1 letter f GDPR).
        </li>
      </ol>
      <p>
        <strong>8. PERSONAL DATA TRANSFERS</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        We will disclose your personal data where necessary, for example by law
        or if we believe that such action is necessary to comply with the law or
        to protect the security or integrity of the Site and the ADSERVIO
        PLATFORM.
      </p>
      <p>
        There are also situations in which data is sent to the competent
        authorities and institutions, lawyers.
      </p>
      <p>
        Whenever we use subcontractors, acting either as a data controller or as
        a proxy, we ensure that the requirements set out in the GDPR are met and
        that your personal data is processed appropriately.
      </p>
      <p>
        In principle, your personal data will not be transmitted to a third
        country or to an international organization.
      </p>
      <p>
        <strong>9. DURATION OF KEEPING PERSONAL DATA</strong>
      </p>
      <p>
        We retain the personal data you provide for a reasonable period of time,
        as long as is necessary to fulfill the purposes described above and to
        fulfill our legal obligations to which we are subject. If our personal
        data is no longer needed and useful, we will delete it.
      </p>
      <p>
        The reasons for which we keep personal data are based on compliance with
        legal obligations under the GDPR and to protect our rights and interests
        in any actions before the competent courts.
      </p>
      <p>
        <strong>10. DATA DISCLOSURE</strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        In the event that we are required to disclose your personal data by
        court order or to comply with other legal requirements, we will notify
        you by prior notice before providing such data unless such disclosure is
        prohibited. We will not sell, disclose, share your personal data without
        your consent and permission.
      </p>
      <p>
        <strong>11. HOW WE ENSURE THE SECURITY OF PERSONAL DATA</strong>
      </p>
      <p>
        We are committed to adopting appropriate technical and organizational
        measures in line with industry standards. The ADSERVIO PLATFORM is
        aligned with GDPR requirements and uses encryption and personal data
        security technology.
      </p>
      <p>
        ADSERVIO will not disclose personal data collected through the ADSERVIO
        PLATFORM except for authorized ADSERVIO employees and ADSERVIO
        contractors / partners who have the capacity of authorized persons.
      </p>
      <p>
        ADSERVIO, together with its collaborators, has assumed the
        responsibility of implementing appropriate technical and organizational
        measures regarding the protection of personal data, respectively for the
        protection against unauthorized access, use, alteration or destruction
        of personal data according to the provisions of GDPR, as follows:
      </p>
      <ol>
        <li>
          Users who have access to the database of personal information are only
          those who have created an account on the ADSERVIO PLATFORM, each of
          them benefiting from the functions of the ADSERVIO PLATFORM with their
          own account name and password.
        </li>
        <li>
          All employees, collaborators and service providers of ADSERVIO who
          come into contact with personal data must act in accordance with the
          principles of policies and procedures for the protection and security
          of personal data by signing declarations and confidentiality
          agreements with regarding these data.
        </li>
        <li>
          The computers from which the database is accessed are password
          protected, they have implemented up-to-date antivirus, antispam and
          firewall protection solutions.
        </li>
      </ol>
      <p>
        <strong>12. LOCATION OF PERSONAL DATA</strong>
      </p>
      <p>
        Personal data is stored in the cloud, and in physical format, in
        Romania, in full compliance with legal requirements and in conditions of
        maximum security and protection.
      </p>
      <strong>
        <h5>13. WHAT RIGHTS DO YOU HAVE IN RELATION TO PERSONAL DATA? </h5>
      </strong>
      <p>
        We assure you that we respect your rights as established by applicable
        law.
      </p>
      <ol>
        <li>
          <strong>Right of access</strong> means the data subject's right to
          obtain confirmation from the controller that he or she is processing
          or not processing personal data concerning him or her and, if so,
          access to that data and information on how the data is processed.
        </li>
        <li>
          <strong>The right to data portability</strong> refers to the right to
          receive personal data in a structured, commonly used and automatically
          readable format and to the right to have this data transmitted
          directly to another controller, if this is feasible from the point of
          view of from a technical point of view.
        </li>
        <li>
          <strong>Right to object</strong> "refers to the data subject's right
          to object to the processing of personal data where the processing is
          necessary for the performance of a task in the public interest or
          where he considers a legitimate interest of the controller. Where the
          processing of personal data is entitled For the purpose of direct
          marketing, the data subject has the right to object to the processing
          at any time.
        </li>
        <li>
          <strong>The right to rectification</strong> refers to the correction,
          without undue delay, of inaccurate personal data stored. The
          rectification must be communicated to each of the recipients to whom
          the data were transmitted, unless this proves impossible or involves
          disproportionate (demonstrable) efforts.
        </li>
        <li>
          <strong>Right to erasure of data</strong> ("right to be forgotten")
          means the right of the data subject to request that his / her personal
          data be deleted, without undue delay, if one of the following reasons
          applies: they are no longer necessary for the fulfillment of the
          purposes for which they were collected or processed; withdraws its
          consent and there is no other legal basis for processing; opposes the
          processing and there are no legitimate reasons to prevail; personal
          data has been processed illegally; personal data must be deleted in
          order to comply with a legal obligation; personal data have been
          collected in connection with the provision of information society
          services.
        </li>
        <li>
          <strong>The right to restrict processing</strong> may be exercised if
          the person disputes the accuracy of the data, for a period which
          allows the accuracy of the data to be verified; the processing is
          illegal and the person objects to the deletion of personal data,
          requesting a restriction instead; if the controller no longer needs
          the personal data for the purpose of processing, but the person
          requests it in order to establish, exercise or defend a right in
          court; if the person objected to the processing for the period of time
          in which it is verified whether the legitimate rights of the
          controller prevail over those of that person.
        </li>
      </ol>
      <p>
        <strong>
          14. UPDATING THE PROTECTION POLICY AND PROCESSING OF PERSONAL DATA
        </strong>
      </p>
      <p>
        We reserve the right to change, complete the Personal Data Protection
        Policy at any time by posting the amended Data Protection Policy.
      </p>
      <p>
        <strong>
          15. CONTACT DETAILS OF THE NATIONAL AUTHORITY FOR THE SUPERVISION OF
          THE PROCESSING OF PERSONAL DATA
        </strong>
      </p>
      <p>
        <strong></strong>
      </p>
      <p>
        Address: &nbsp; B-dul G-ral Gheorghe Magheru 28-30, Sector 1, postal
        code 010336, Bucharest, România
      </p>
      <p>
        Mail:
        <a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a>
      </p>
      <p>
        Tel: <a href="tel:0318059211">+40318.059.211</a>;
        <a href="tel:0318059212">+40318.059.212</a>
      </p>
    </div>
  );
};

export default EngPrivacyPolicy;
