import phonesImg from "../../assets/adservioMobile/phones.png";
import appGallery from "../../assets/adservioMobile/appGallery.svg";
import appstore from "../../assets/adservioMobile/appStore.svg";
import arrow from "../../assets/adservioMobile/arrow.svg";
import googlePlay from "../../assets/adservioMobile/googlePlay.svg";
import "./adservio_mobile.css";
import { useTranslation } from "react-i18next";

const AdservioMobile = () => {
  const { t } = useTranslation("common");
  const handleClick = (url) => {
    window.open(url);
  };
  return (
    <section className="adservio_mobile">
      <div className="adservio_mobile_container">
        <img src={phonesImg} alt={phonesImg} />
        <div className="adservio_mobile_content">
          <h1>{t("students.adservio_mobile.title")}</h1>
          <h2>{t("students.adservio_mobile.text")}</h2>
          <div className="adservio_mobile_wrapper">
            <div
              className="adservio_mobile_button"
              onClick={() =>
                handleClick(
                  "https://play.google.com/store/apps/details?id=ro.adservio.app&hl=en&gl=US"
                )
              }
            >
              <img src={googlePlay} alt={googlePlay} />
              <img src={arrow} alt={arrow} />
            </div>
            <div
              className="adservio_mobile_button"
              onClick={() =>
                handleClick(
                  "https://apps.apple.com/ro/app/adservio/id1401366069"
                )
              }
            >
              <img src={appstore} alt={appstore} />
              <img src={arrow} alt={arrow} />
            </div>
            <div
              className="adservio_mobile_button"
              onClick={() =>
                handleClick("https://appgallery.huawei.com/#/app/C103260303")
              }
            >
              <img src={appGallery} alt={appGallery} />
              <img src={arrow} alt={arrow} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdservioMobile;
