import Api2 from '../../Libs/api';
import Button from '../general/Button';
import Select from '../content/select/Select';
import goldArches from '../../assets/home/institutions/gold-arches.svg';
import schoolIcon from '../../assets/registerFlow/schoolIcon.svg';

import { useTranslation } from 'react-i18next';
import './schoolDetails.css';
import AOS from 'aos';
import { useEffect, useState, useRef } from 'react';

const SchoolDetails = ({ onSubmit, userCanCreateSchool }) => {
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [schools, setSchools] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [errors, setErrors] = useState([]);
  const [country, setCountry] = useState('');
  const [school, setSchool] = useState('');
  const [city, setCity] = useState('');
  const [schoolInput, setSchoolInput] = useState('');
  const { i18n, t } = useTranslation('common');

  const searchSchoolInputValueRef = useRef('');
  const searchSchoolInputTimeoutRef = useRef(undefined);

  useEffect(() => {
    AOS.init({ mirror: 'false', once: 'true', duration: 1000 });
  });

  useEffect(() => {
    if (i18n.language && t) {
      Api2.get('./adrese/tari', {
        _lang: i18n.language,
        _limit: 1000,
      }).then((response) => {
        const formattedCountries = response.data.map((ct) => {
          return {
            label: ct.taraNumeFull,
            value: ct.taraID,
          };
        });

        if (i18n.language !== 'ro') formattedCountries.sort(sortCountriesByNames);

        setCountries(formattedCountries);
        setFilteredCountries(formattedCountries);
      });
    }
  }, [t, i18n.language]);

  const hasError = (formErrors, fieldName, errorCode) => {
    if (!formErrors.length) return false;
    const error = formErrors.filter(
      (fieldErr) => fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode
    );
    return error.length ? error[0] : false;
  };

  const cleanString = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    if (!string) return '';

    return string
      .toString()
      .toLowerCase()
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };

  const clickHandler = () => {
    const newErrors = [];

    if (!country) {
      newErrors.push({ errorCode: '1', errorField: 'country' });
    }
    if (!school && !schoolInput) {
      newErrors.push({ errorCode: '1', errorField: 'school' });
    }
    if (!city) {
      newErrors.push({ errorCode: '1', errorField: 'city' });
    }

    if (newErrors.length) {
      setErrors(newErrors);
    } else {
      onSubmit({ country, school, city, newSchoolName: schoolInput });
    }
  };

  const fetchCities = (option) =>
    Api2.get('./adrese/judete', {
      taraID: option.value,
      _limit: 1000,
    }).then((response) => {
      const formattedCities = response.data.map((city) => ({
        label: city.judNume,
        value: city.judID,
      }));
      formattedCities.push({
        label: t('register.schoolDetails.another-city'),
        value: '0',
      });
      setCities(formattedCities);
      setFilteredCities(formattedCities);
    });

  const sortCountriesByNames = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  const fetchSchools = (searchValue) =>
    new Promise((resolve) => {
      Api2.get('./scoli/public', {
        search: searchValue,
        ...(city?.value && { judID: city?.value }),
        ...(country?.value && { taraID: country?.value }),
      })
        .then((response) => {
          const apiSchools = [];
          response.data.forEach((school) => {
            if (school && school.scoalaNume && school.scoalaID) {
              apiSchools.push({
                label: school.scoalaNume,
                value: school.scoalaID,
                ...school,
              });
            }
          });

          if (!userCanCreateSchool) {
            apiSchools.push({
              label: t('contact.form.another-school'),
              value: '0',
            });
          }
          if (searchValue === searchSchoolInputValueRef.current) {
            setSchools(apiSchools);
          }
        })
        .finally(() => {
          resolve();
        });
    });

  const handleSearchSchoolsCallback = (value, action) => {
    searchSchoolInputValueRef.current = value;

    if (
      !userCanCreateSchool ||
      (action.action !== 'input-blur' && action.action !== 'menu-close')
    ) {
      setSchoolInput(value);
    }

    if (value.length < 3) {
      clearTimeout(searchSchoolInputTimeoutRef.current);
      searchSchoolInputTimeoutRef.current = undefined;

      return;
    }
    if (searchSchoolInputTimeoutRef.current) {
      return;
    }
    searchSchoolInputTimeoutRef.current = setTimeout(async () => {
      searchSchoolInputTimeoutRef.current = undefined;

      await fetchSchools(searchSchoolInputValueRef.current);
    }, 1000);
  };

  const handleSearchCities = (value) => {
    let newCities = cities.filter(({ label }) => cleanString(label).includes(cleanString(value)));

    if (!newCities.length) {
      newCities = [
        {
          label: t('register.schoolDetails.another-city'),
          value: '0',
        },
      ];
    }
    setFilteredCities(newCities);
  };

  const handleSearchConutries = (value) => {
    let newCountries = countries.filter(({ label }) =>
      cleanString(label).includes(cleanString(value))
    );

    if (!newCountries.length) {
      newCountries = [
        {
          label: t('register.schoolDetails.another-country'),
          value: '0',
        },
      ];
    }

    setFilteredCountries(newCountries);
  };

  const handleFocus = (fieldName) => {
    setErrors(errors.filter((fieldErr) => fieldErr.errorField !== fieldName));
  };

  return (
    <div id="schoolDetails">
      <img src={goldArches} alt={goldArches} data-aos="fade-in" />
      <img src={schoolIcon} alt={schoolIcon} data-aos="fade-in" />

      <h1>{t('register.schoolDetails.title1')}</h1>
      <form>
        <div className="form-row">
          <label htmlFor="country">{t('register.schoolDetails.form.country')}</label>
          <label htmlFor="city">{t('register.schoolDetails.form.city-new')}</label>
          <div>
            <Select
              name="country"
              value={country}
              onChangeOptions={(option) => {
                setCountry(option);
                fetchCities(option);
              }}
              onFocus={() => handleFocus('country')}
              isSearchable
              className="select-country"
              options={filteredCountries}
              noClientFilter
              placeholder={t('contact.form.placeholder')}
              onInputValueChange={handleSearchConutries}
              noOptionsMessage={() => t('contact.form.school_no_options')}
            />
            {hasError(errors, 'country', '1') && (
              <p className="form__input-error">{t('contact.form.country_required_error')}</p>
            )}
          </div>
          <div>
            <Select
              name="city"
              value={city}
              onChangeOptions={(option) => {
                setCity(option);
              }}
              onFocus={() => handleFocus('city')}
              isSearchable
              className="select-city"
              options={filteredCities}
              noClientFilter
              placeholder={t('contact.form.placeholder')}
              onInputValueChange={handleSearchCities}
              noOptionsMessage={() => t('contact.form.school_no_options')}
              isDisabled={!country?.value}
            />
            {hasError(errors, 'city', '1') && (
              <p className="form__input-error">{t('contact.form.city_required_error')}</p>
            )}
          </div>
        </div>

        <label htmlFor="schoolName">{t('register.schoolDetails.form.name1')}</label>

        <Select
          name="scoalaID"
          isSearchable
          value={school}
          onChangeOptions={(option) => {
            setSchool(option);
          }}
          onFocus={() => handleFocus('school')}
          onInputValueChange={handleSearchSchoolsCallback}
          inputValue={schoolInput}
          className="select-school"
          options={schools}
          noOptionsMessage={() =>
            schoolInput.length ? null : t('contact.form.school_start_typing')
          }
          noClientFilter
          placeholder={t('contact.form.placeholder')}
        />
        {hasError(errors, 'school', '1') && (
          <p className="form__input-error">{t('contact.form.school_required_error')}</p>
        )}

        <Button
          text={t('register.schoolDetails.form.btn')}
          type={'primary'}
          action={() => clickHandler()}
        />
      </form>
    </div>
  );
};

export default SchoolDetails;
