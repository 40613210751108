import { Header } from '../general/header';
import IntroStudents from './IntroStudents';
import Benefits from '../schools/Benefits';
import Digitalization from '../schools/Digitalization';
import AdservioMobile from './AdservioMobile';
import Feedback from '../parents/Feedback';
import Footer from '../general/footer/Footer';
import './feedback_students.css';
import '../parents/parents_extra_styling.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import VideoModal from '../general/VideoModal';
import ShareModal from '../general/ShareModal';
import Api2 from '../../../src/Libs/api';

import { useParams } from 'react-router-dom';
import AdmissionSection from '../general/AdmissionSection/AdmissionSection';
const Students = () => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [flags, setFlags] = useState();

  let { lng } = useParams();
  const { i18n, t } = useTranslation('common');
  const changeLanguage = (to) => {
    i18n.changeLanguage(to).then(() => {
      i18n.language = to;
    });
  };
  useEffect(() => {
    if (i18n.language !== lng) {
      changeLanguage(lng);
    }
  });

  useEffect(() => {
    let allFlags = [];
    const fetchEvent = async () => {
      Api2.get(`/enumerations/languages?langStatus=>0`).then((data) => {
        setFlags(data.data);
        allFlags = data.data;
      });
    };
    allFlags?.length === 0 && fetchEvent();
  }, []);

  return (
    <section id="studentsID">
      <Helmet>
        <title>{t('general.keysForMeta.students.title')}</title>
        <meta name="description" content={t('general.keysForMeta.students.description')} />
        <meta name="keywords" content={t('general.keysForMeta.students.keywords')} />
        <meta property="og:title" content={t('general.keysForMeta.students.title')} />
        <meta property="og:description" content={t('general.keysForMeta.students.description')} />
      </Helmet>
      {videoUrl && <VideoModal videoUrl={videoUrl} setVideoUrl={setVideoUrl} />}
      <ShareModal />
      <Header flags={flags} activeEl={'students'} />
      <main>
        <IntroStudents />
        <AdmissionSection type="student" />
        <Benefits type={'student'} />
        <Digitalization
          text={t('students.digitalization.title')}
          setVideoUrl={setVideoUrl}
          type={'student'}
        />
        <AdservioMobile />
        <Feedback />
      </main>
      <Footer flag={flags} />
    </section>
  );
};

export default Students;
